import React from 'react';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import jwt from 'jwt-decode';
import Sharing from 'utils/Sharing.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

class AccountDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isRestricted: false,
            isCopiedWebsite: false,
            isCopiedTelegram: false,
            isCopiedUniqueID: false
        };
    }

    render() {
        const telegramReferralLink = UserData.getItem(userDataKeys.telegram_referral_link);
        const websiteReferralLink = UserData.getItem(userDataKeys.website_referral_link);
        const uniqueUserID = UserData.getItem(userDataKeys.jwt_token) ? jwt(UserData.getItem(userDataKeys.jwt_token)).uniqueUserID : '';
        const terms = Number(UserData.getItem(userDataKeys.terms));

        const telegramReferralLinkText = Content.getValue("telegram_referral_link_text");
        const webReferralLinkText = Content.getValue("web_referral_link_text");
        return (
            <div className="account-details-section">
                <div className="account-details-container margin-bottom-10 mbt-65-desktop ">
                    <span className="account-details-label">{Content.getValue("username_label")}</span>
                    <span className="account-details-value">{UserData.getItem(userDataKeys.username)}</span>
                </div>
                {terms ?
                    <div className='account-details-links mb-40-desktop '>
                        <div className="account-details-container right-margin-25">
                            <span className="account-details-label">{telegramReferralLinkText}</span>
                            <button
                                href={telegramReferralLink}
                                onClick={async () => {
                                    await Sharing.copyToClipboard(telegramReferralLink, Content.getValue("copy_to_clipboard_fallback_title"), Content.getValue("copy_to_clipboard_fallback_text"));
                                    this.setState(prev => ({ ...prev, isCopiedTelegram: true }));
                                    setTimeout(() => {
                                        this.setState(prev => ({ ...prev, isCopiedTelegram: false }));
                                    }, 1000);
                                }}
                                target='_blank'
                                rel='noreferrer'
                                className="account-details-link"
                            >
                                {telegramReferralLink}
                            </button>
                            {this.state.isCopiedTelegram ?
                                <div className="account-details-message">{Content.getValue('copied_to_clipboard')}</div> : <></>}
                        </div>
                        <div className="account-details-container">
                            <span className="account-details-label">{webReferralLinkText}</span>
                            <button
                                href={websiteReferralLink}
                                onClick={async () => {
                                    await Sharing.copyToClipboard(websiteReferralLink, Content.getValue("copy_to_clipboard_fallback_title"), Content.getValue("copy_to_clipboard_fallback_text"));
                                    this.setState(prev => ({ ...prev, isCopiedWebsite: true }));
                                    setTimeout(() => {
                                        this.setState(prev => ({ ...prev, isCopiedWebsite: false }));
                                    }, 1000);
                                }}
                                target='_blank'
                                rel='noreferrer'
                                className="account-details-link button-hidden"
                            >
                                {websiteReferralLink}
                            </button>
                            {this.state.isCopiedWebsite ?
                                <div className="account-details-message">{Content.getValue('copied_to_clipboard')}</div> : <></>}
                        </div>
                    </div> : <></>}
                <div className="account-details-container margin-bottom-10">
                    <span className="account-details-label">{Content.getValue("unique_user_id_label")}</span>
                    <button
                        className="hidden-button account-details-link"
                        value={uniqueUserID}
                        onClick={async () => {
                            await Sharing.copyToClipboard(uniqueUserID, Content.getValue("copy_to_clipboard_fallback_title"), Content.getValue("copy_to_clipboard_fallback_text"));
                            this.setState(prev => ({ ...prev, isCopiedUniqueID: true }));
                            setTimeout(() => {
                                this.setState(prev => ({ ...prev, isCopiedUniqueID: false }));
                            }, 1000);
                        }}
                    >{uniqueUserID}
                    </button>
                    {this.state.isCopiedUniqueID ?
                        <div className="account-details-message">{Content.getValue('copied_to_clipboard')}</div> : <></>}
                </div>

            </div>
        );
    }
}

export default AccountDetails;