import React, { useState } from 'react';
import Content from 'utils/Content.js';

function SignupInput({ handler, text, error }) {
    const [email, setEmail] = useState('');

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handler(email);
        }
    };

    return (
        <div className={`join-content-email-input ${error? 'red-error-input':''}`}>
            <input
                type="email"
                placeholder={Content.getValue('your_email_placeholder')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ marginRight: '10px' }}
                onKeyPress={handleKeyPress}
            />
            <button
                className='join-content-email-input-button join-treatcoin-button' onClick={() => { handler(email); }}>{text}</button>
        </div>
    );
}

export default SignupInput;