import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IntroSurveyStartingSection from 'components/static/Intro_Survey_Starting_Section.js';
import IntroSurveyFinishingSection from 'components/static/Intro_Survey_Finishing_Section.js';
import IntroSurveyAnswersStorage from 'utils/Intro_Survey_Answers_Storage.js';
import Quiz from 'components/survey/Quiz.js';
import UserData from 'User_Data.js';
import ApiFacade from 'ApiFacade.js';

import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';


const configuration = require('configuration.json');

const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;
const userDataKeys = configuration.user_data_keys;

const IntroSurvey = () => {
    const navigate = useNavigate();
    const introSurveyQuestions = Content.getValue("intro_survey_questions");
    const startIndex = parseInt(Content.getValue("intro_survey_starting_index"));
    const finalIndex = parseInt(Content.getValue("intro_survey_finishing_index"));
    const multipleSelectionQuestionHint = introSurveyQuestions.multiple_selection_question_hint;

    const [questionIndex, setQuestionIndex] = useState(startIndex);
    const [validityStatus, setValidityStatus] = useState(false);

    const data = introSurveyQuestions[questionIndex];
    if (questionIndex === startIndex) IntroSurveyAnswersStorage.initializeAnswersStorage();

    if (!ApiFacade.isLoggedIn()) {
        redirectWithLangFunc(configuration.paths.playground, navigate);
    }
    
    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.intro_survey });
    }, []);

    const submitHandler = async () => {
        IntroSurveyAnswersStorage.sendAnswersToServer(
            configuration.paths.intro_survey_finished,
            (value) => setValidityStatus(value),
        );
        IntroSurveyAnswersStorage.clearAnswersStorage();
    };

    return (ApiFacade.isLoggedIn()) ? (
        <>
            {
                questionIndex === startIndex ?
                    <IntroSurveyStartingSection quizStartHandler={() => {
                        UserData.setItem(userDataKeys.intro_survey_starting_time, Date.now());
                        setQuestionIndex(questionIndex + 1);
                    }} /> :
                    questionIndex > finalIndex && validityStatus ?
                        <IntroSurveyFinishingSection /> :
                        questionIndex > finalIndex && !validityStatus ?
                            null :
                            <Quiz
                                data={data}
                                index={questionIndex}
                                hint={multipleSelectionQuestionHint}
                                submitHandler={submitHandler}
                                goToNext={(number) => setQuestionIndex(number || questionIndex + 1)}
                                goToBack={(number) => setQuestionIndex(number || questionIndex - 1)}
                            />
            }
        </>
    ) : (null);
};

export default IntroSurvey;