import React from 'react';
import Images from 'utils/Images';

function IFrameCPX() {
    return (
        <div className='iframe-container' id='cpx-iframe-container' >
            <div className="iframe-container-heading">
                <button className="button-hidden padding-x-20 "
                onClick={()=>{
                    const iframe = document.getElementById('cpx-iframe-container');
                    iframe.style.display = 'none';
                }}>
                    <img width={24} height={24} src={Images.imageURL('close-welcome.png')} alt="Close" />
                </button>
            </div>
            <iframe
                title="CPX Surveys"
                width="100%" 
                frameBorder="0"
                height="100%"
                id='cpx-iframe-src'
                >
            </iframe>
        </div>
    );
}

export default IFrameCPX;