import React from 'react';

function ProgressBar({ width }) {

    return (
        <div className='progress-bar'>
            <div className='progress-bar-outer' />
            <div className='progress-bar-inner' style={{ width }} />
        </div>
    )
}

export default ProgressBar;