import React from 'react';
import PropTypes from 'prop-types';

function ActionButton({ onClick, classNames, content, disabled = false, style, children }) {
    return (
        <button
            style={style ? style : {}}
            disabled={disabled}
            onClick={onClick}
            className={`hidden-btn confirm-button ${disabled ? 'disabled-btn' : ''} ${classNames}`}>
            {content}
            {children}
        </button>
    );
}

ActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    classNames: PropTypes.string,
    content: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    children: PropTypes.node
};

ActionButton.defaultProps = {
    disabled: false,
    style: {},
    children: null
};

export default ActionButton;