import Content from 'utils/Content.js';
import { useEffect, useRef, useState } from "react";
import { Mixpanel } from "utils/User_Events_Util.js";
import 'design/reviews.css';
import ApiFacade from "ApiFacade";
import SearchInput from "components/inputs/SearchInput";
import ReviewsList from './ReviewsList';
import { Waypoint } from 'react-waypoint';
import Images from 'utils/Images';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const REVIEWS_AMOUNT_PER_PAGE = 20;




const AllReviews = () => {

    const [allReviews, setAllReviews] = useState([]);
    const [page, setPage] = useState(1);
    const [isAllFetched, setIsAllFetched] = useState(false);
    const [filterToggle, setFilterToggle] = useState(false);
    const [filter, setFilter] = useState(null);

    const searchRef = useRef(null);
    const menuRef = useRef(null)



    const filterLookup = {
        1: Content.getValue('filter_reviews_highest_first'),
        2: Content.getValue('filter_reviews_lowest_first'),
        3: Content.getValue('filter_reviews_name_asc'),
        4: Content.getValue('filter_reviews_name_desc'),
    }

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.all_reviews });
        document.addEventListener("click", handleClickOutside, filterToggle);
        return () => {
            document.removeEventListener("click", handleClickOutside, filterToggle);
        }
    }, []);


    useEffect(() => {
        getUserReviews();
    }, [page, filter]);


    const handleClickOutside = (event) => {

        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setFilterToggle(false);
        }
    };

    const getUserReviews = async () => {

        const reviewTopicName = searchRef.current.value;

        const res = await ApiFacade.getUserReviews({ mine: 0, all: 1, offerPopup: 0, reviewTopicName, page, orderMode: filter });

        if (res?.ok) {
            const { reviews } = res.response;
            if (reviews.length == 0 || REVIEWS_AMOUNT_PER_PAGE > reviews.length) {
                setIsAllFetched(true);
            }
            setAllReviews(prev => [...prev, ...reviews]);
        }
    }

    const onSearchChange = () => {
        setAllReviews([]);
        setIsAllFetched(false);
        return (page > 1) ? setPage(1) : getUserReviews();
    }

    const onFilterChange = (val) => {
        if (filter != val) {
            setAllReviews([]);
            setIsAllFetched(false);
            setFilter(val);
        }
    }

    return (
        <div className="container-small">
            <div className="margin-bottom-20 reviews-todo">
                <SearchInput
                    width="100%"
                    height={35}
                    ref={searchRef}
                    onClick={onSearchChange}
                />
                <div className="flex-row space-between margin-bottom-20">
                    <div className="lable-header bold text-left capitalize">
                        {Content.getValue('all_reviews')}
                    </div>
                    <div className="flex items-center">
                        <div
                            className={`filter-wrap items-center flex-row relative gap-10 ${filter ? 'filter-set' : ''}`}
                            onClick={() => setFilterToggle(!filterToggle)}
                            ref={menuRef}
                        >
                            {filter && (<span>{filterLookup[filter]}</span>)}
                            <ul className={`dots-action-menu ${filterToggle ? "show" : ""}`}  >
                                {Object.keys(filterLookup).map((key, idx) => {
                                    return <li onClick={() => onFilterChange(key)} key={idx}>{filterLookup[key]}</li>
                                })}
                            </ul>
                            <img src={Images.imageURL(`icons/${filter ? 'filter-icon-purple.png' : 'filter-icon.png'}`)} alt="" />
                        </div>
                    </div>
                </div>
                <div className="done-reviews flex-column text-left">
                    <ReviewsList
                        mine={false}
                        reviews={allReviews}
                    />
                    {allReviews.length > 0 && (
                        <Waypoint
                            onEnter={() => {
                                if (!isAllFetched) {
                                    setPage(page + 1);
                                }
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default AllReviews;