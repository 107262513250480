import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';

const MySwal = withReactContent(Swal);


let thisComponent;



class AccountDeletion extends React.Component {

    constructor(props) {
        super(props);
        thisComponent = this;
        this.state = {
            lifetimeCoins: null,
            updating: false
        }
    }

    startUpdate() {
        thisComponent.setState({
            updating: true
        });
    }

    stopUpdate() {
        thisComponent.setState({
            updating: false
        });
    }

    startAccountDeletion() {


        let deleteRobotIconURL = Images.imageURL('robot-delete-account-icon.png');
        MySwal.fire({
            title: '<p class="delete-title">' + Content.getValue("delete_your_account_question") + '</p>',
            html: '<p class="swal2-content padding-top-10 swal-center-text">' + Content.getValue("delete_your_account_question_text") + '</p>',
            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon delete-account" src="' + deleteRobotIconURL + '"/>',
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            showCancelButton: true,
            reverseButtons: true,
            confirmButtonText: Content.getValue("delete_account_confirm_button_text"),
            cancelButtonText: Content.getValue("delete_account_cancel_button_text"),
            customClass: {
                popup: 'delete-account-container',
                cancelButton: 'delete-account-cancel-button',
                confirmButton: 'delete-account-confirm-button',
                actions: 'delete-account-actions'
            }
        }).then((result) => {
            if (result.isConfirmed) thisComponent.deleteAccount();
        });

    }

    deleteAccount() {

        const req = ApiFacade.deleteAccount();
        req.then(function (res) {
                let updateResultDiv = document.getElementById('updateResultDivAccountSettings');
                if (res.ok){
                    //let result = res.response;
                    let message = Content.getValue("account_successfully_deleted");
                    updateResultDiv.className = 'success-message account-settings';
                    updateResultDiv.innerHTML = message;
                    setTimeout(function () {
                        Backend.logOut();
                    },
                    1500);
                } else {
                    updateResultDiv.className = 'error-message account-settings';
                    updateResultDiv.innerHTML = Backend.loadBackendMessages().errorMessage;
                }

                setTimeout(function () {
                    updateResultDiv.innerHTML = '';
                },
                    2000);
            });
    }

    componentDidMount() {
        let req = ApiFacade.myCoins();
        req.then(async function (res) {
                if (res.ok) {
                    let result = res.response;
                    thisComponent.setState({
                        lifetimeCoins: result.lifetimeCoins
                    });
                }
            },
                () => {
                }
            );
    }

    render() {
            return <div className="account-deletion-section">
                <button className="account-settings-button delete-account-button w-auto-small-desktop" onClick={this.startAccountDeletion}>{Content.getValue("delete_account_button_text")}</button>
                <div id="updateResultDivAccountSettings" className="success-message account-settings"> </div>
            </div>
    }
}

export default AccountDeletion;