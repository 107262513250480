import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';



const CoinsConversionPanel = props => {

    const { coins, treatCoinValue, fullWidth, backgroundColor } = props;

    const styles = {
        coinsPannel: {
            background: backgroundColor || "#303149",
            borderRadius: 10,
            padding: 8,
            fontSize: 16,
            width: fullWidth ? "100%" : "fit-content",
            gap: 10
        }
    }

    return (
        <div style={styles.coinsPannel} className="justify-center flex coins-conversion-panel">
            <div className="flex-column">
                <div className="flex-row gap-5">
                    <img width={20} height={20} src={Images.imageURL('offers/stats-header-money-icon-new.png')} alt="TreatCoin"/>
                    <span className="f-weight-600">{coins}</span>
                </div>
                <span className="font-13">{Content.getValue('treat_coins')}</span>
            </div>
            <span>=</span>
            <div className='flex-column'>
                <div className="flex-row gap-5">
                    <span>{Formatting.currencySymbol()}</span>
                    <span>{(coins * treatCoinValue).toFixed(2)}</span>
                </div>
                <span className="font-13">{Formatting.currencyLabel()}</span>
            </div>
        </div>
    );
}

export default CoinsConversionPanel;