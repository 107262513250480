import React from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Platform from 'utils/Platform.js';
import Formatting from 'utils/Formatting.js';
import Images from 'utils/Images.js'
import IncompleteOffer from 'components/offers/Incomplete_Offer.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';


let thisComponent;

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const incompleteOfferModes = configuration.incomplete_offer_modes;
const recommendedOfferStatuses = configuration.recommended_offer_statuses;

class RecommendedOfferDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            isRestricted: false,
            recommenderDetails: null,
            recommendedOffer: null,
            alternativeOffer: null,
            offerAvailable: null,
            offerStatus: null
        };
        thisComponent = this;
    }

    componentDidMount() {
        let recommendedOffer = UserData.getItem(userDataKeys.recommended_offer);
        let offerRecommender = UserData.getItem(userDataKeys.offer_recommender);

        if (recommendedOffer !== null && offerRecommender !== null && !ApiFacade.isLoggedIn()) {
            let platform = Platform.getPlatformName();

            //let calledURL = `${recommendedOfferURL}?recommendedOffer=${encodeURIComponent(recommendedOffer)}&offerRecommender=${encodeURIComponent(offerRecommender)}&platform=${encodeURIComponent(platform)}`;
            const req = ApiFacade.recommendedOffer(recommendedOffer, offerRecommender, platform);
            req.then(function (res) {
                if (res.ok){
                    let result = res.response;

                    thisComponent.setState({
                        isLoaded: true,
                        recommendedOffer: result.recommendedOffer,
                        alternativeOffer: result.alternativeOffer,
                        recommenderDetails: result.recommenderDetails,
                        offerAvailable: result.offerAvailable,
                        offerStatus: result.offerStatus
                    });
                } else if (res.unauthorized){
                    thisComponent.setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                } else {
                    thisComponent.setState({
                        isLoaded: true,
                        error: res.error
                    });
                } 
            },
            (error) => {
                thisComponent.setState({
                    isLoaded: true,
                    error
                });
            }
            )
        }
    }

    render() {
        const { error, isLoaded, recommendedOffer, alternativeOffer, recommenderDetails, offerAvailable, offerStatus } = this.state;
            if (error) {
                return <div className="error-message red-error">{Backend.loadBackendMessages().errorMessage}</div>;
            } else if (!isLoaded) {
                return <></>
            } else {
                if (offerAvailable === false) {
                    if (alternativeOffer !== null) {
                        UserData.setItem(userDataKeys.recommended_offer, alternativeOffer.uniqueID);
                    }
                    else {
                        UserData.removeItem(userDataKeys.recommended_offer);
                        UserData.removeItem(userDataKeys.offer_recommender);
                    }
                }
                return (recommenderDetails !== null && 
                        recommendedOffer !== null && 
                        (offerAvailable || (offerStatus !== recommendedOfferStatuses.does_not_exist && alternativeOffer !== null))) ?
                    (
                        <section className="recommended-offer-container">
                            <div className="recommended-offer-title">{offerAvailable ? Content.getValue("recommended_offer_title") : Content.getValue("recommended_offer_title_not_available")}</div>
                            <IncompleteOffer offer={recommendedOffer} mode={(offerAvailable) ? incompleteOfferModes.recommended_offer : incompleteOfferModes.unavailable_offer} availabilityStatus={Content.getValue("recommended_offer_status_messages")[offerStatus]} key={`_offer-recommended-${recommendedOffer?.ID}_${Date.now()}`} />
                            <div className="recommender-details">
                                <div className="recommender-details-text-container">
                                    <img className="recommender-details-icon" alt="Recommender username" width="14" height="auto" src={Images.imageURL("recommender/username.png")} />
                                    <div className="recommender-details-text">{Content.getValue("recommended_by")} {recommenderDetails.username}</div>
                                </div>

                                <div className="recommender-details-text-container">
                                    <img className="recommender-details-icon" alt="Recommender earned" width="14" height="auto" src={Images.imageURL("recommender/earned.png")} />
                                    <div className="recommender-details-text">{Formatting.format(Content.getValue("recommender_treatcoins_earned"), ["(earned)"], [recommenderDetails.earned])}</div>
                                </div>

                                <div className="recommender-details-text-container">
                                    <img className="recommender-details-icon" alt="Recommender joined" width="14" height="auto" src={Images.imageURL("recommender/joined.png")} />
                                    <div className="recommender-details-text">{Formatting.format(Content.getValue("recommender_joined"), ["(joinedAgo)"], [recommenderDetails.joinedAgo])}</div>
                                </div>

                            </div>
                            {
                                (offerAvailable === false && alternativeOffer !== null) ? (
                                    <>
                                        <div className="recommended-offer-title alternative">{Content.getValue("recommended_offer_title_instead")}</div>
                                        <IncompleteOffer offer={alternativeOffer} mode={incompleteOfferModes.recommended_offer} key={`_offer-recommended-${recommendedOffer ? recommendedOffer.ID : 0}_${Date.now()}`} />
                                    </>
                                    ) : (null)
                            }
                        </section>
                    ) : (null);
            }
    }

}

export default RecommendedOfferDetails;