import React from 'react';
import Content from 'utils/Content.js';

class StartNowButton extends React.Component {

    render() {
        return (
            <span className="start-survey-button-container"  onClick={this.props.quizStartHandler}>
                <button className="start-survey-button">
                    { Content.getValue("start_now_button") }
                </button>
            </span>
        );
    }

}

export default StartNowButton;