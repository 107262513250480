import React, { useEffect} from 'react';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import AccountDetails from 'components/data/Account_Details.js';
import SubscriptionSettings from 'components/forms/Subscription_Settings.js';
import AccountDeletion from 'components/forms/Account_Deletion.js';
import Footer from 'components/sections/Footer.js';
import NewsTrack from 'components/live_news/News_Track.js';
import Content from 'utils/Content.js'

import 'design/account_settings.css';

import { Mixpanel } from 'utils/User_Events_Util.js';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const AccountSettings = () => {

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.account_settings });
    }, []);

    return (
        <>
            <div id="main">

                <div id="content">
                    {

                    }   
                    <ContentPageHeader />
                    {
                       <NewsTrack />                    }     
                    
                    <section className="content-page-section">
                        <div className='m-0-auto-desktop d-flex mobile-padding-25 mobile-width-auto'>
                            <div className="title-container">
                                <h1 className="page-title small-mobile account">{Content.getValue('menu_title_account_settings')}</h1>
                            </div>
                            <div className="page-container">
                            <AccountDetails/>
                            <SubscriptionSettings />  
                                <AccountDeletion />  
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AccountSettings;