import Content from 'utils/Content.js';
import { useContext, useEffect, useRef, useState } from "react";
import { Mixpanel } from "utils/User_Events_Util.js";
import 'design/reviews.css';
import ApiFacade from "ApiFacade.js";
import SearchInput from "components/inputs/SearchInput.js";
import ReviewsToDo from './ReviewsToDo.js';
import ReviewsList from './ReviewsList.js';
import { Waypoint } from 'react-waypoint';
import PopupModal from 'components/modals/PopupModal.js';
import WriteOfferReview from './WriteOfferReview.js';
import DeleteModal from './Delete_Modal.js';
import Alerts from '../../utils/Alerts.js';
import { SpinnerContext } from '../../context/Global_Spinner.js';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;


const REVIEWS_AMOUNT_PER_PAGE = 20;


const UserReviews = () => {
    const { updateIsVisibleSpinner } = useContext(SpinnerContext);
    const [toDoReviews, setToDoReviews] = useState([]);
    const [doneReviews, setDoneReviews] = useState([]);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(true);
    const [isAllFetched, setIsAllFetched] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState({});
    const [reviewToDelete, setReviewToDelete] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)

    const searchRef = useRef(null);


    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.my_reviews });
    }, []);

    useEffect(() => {
        getUserReviews();
    }, [page]);

    const getUserReviews = async () => {
        const reviewTopicName = searchRef.current.value;

        setIsFetching(true);
        const res = await ApiFacade.getUserReviews({ mine: 1, all: 0, offerPopup: 0, reviewTopicName, page });
        setIsFetching(false);
        if (res?.ok) {
            const { reviews, todo } = res.response;

            setToDoReviews(todo);
            if (reviews.length == 0 || REVIEWS_AMOUNT_PER_PAGE > reviews.length) {
                setIsAllFetched(true);
            }
            if (page !== 1) {
                setDoneReviews(prev => [...prev, ...reviews]);

            } else {
                setDoneReviews(reviews);
            }
        }
    }

    const onSearchChange = () => {
        setDoneReviews([]);
        setIsAllFetched(false);
        return (page > 1) ? setPage(1) : getUserReviews();
    }

    const onReviewEdit = review => {
        setSelectedReview(review);
        setIsEditOpen(true);
    }

    const handleReviewDelete = async () => {

        try {
            setDeleteModalOpen(false);
            updateIsVisibleSpinner({ isVisible: true });
            const res = await ApiFacade.setUserReview({
                uniqueOfferID: reviewToDelete.UniqueOfferID,
                title: reviewToDelete.Title,
                reviewText: reviewToDelete.ReviewText,
                rating: reviewToDelete.Rating,
                reviewID: reviewToDelete.ID,
                delete: 1,
            });
            if (res.ok) {
                Alerts.showSuccessAlert(Content.getValue('delete_review_success'));
                getUserReviews();
            } else {
                Alerts.showErrorAlert(Content.getValue('error_message_review'));
            }
        } catch (error) {
            Alerts.showErrorAlert(error.message);
        } finally {
            updateIsVisibleSpinner({ isVisible: false });
        }
    }

    const afterReviewSubmit = () => {
        onSearchChange();
    }

    return (
        <>
            <div className="container-small">
                <div className="margin-bottom-20 reviews-todo">
                    <SearchInput
                        width="100%"
                        height={35}
                        ref={searchRef}
                        onClick={onSearchChange}
                    />
                    {toDoReviews.length > 0 && (
                        <>
                            <div className="lable-header bold text-left margin-bottom-20 capitalize">{Content.getValue('to_do')}</div>
                            <div className="todo-reviews flex-column text-left">
                                <ReviewsToDo reviews={toDoReviews} afterReviewSubmit={afterReviewSubmit} />
                            </div>
                        </>
                    )}

                </div>
                <div className="reviews-done">
                    {doneReviews.length > 0 && (
                        <>
                            <div className="lable-header bold text-left margin-bottom-20 capitalize">{Content.getValue('done')}</div>
                            <div className="done-reviews flex-column text-left">
                                <ReviewsList
                                    mine={true}
                                    reviews={doneReviews}
                                    onReviewEdit={onReviewEdit}
                                    onReviewDelete={(review) => {
                                        setReviewToDelete(review);
                                        setDeleteModalOpen(true);
                                    }
                                    }
                                />
                                {doneReviews.length > 0 && (
                                    <Waypoint
                                        onEnter={() => {
                                            if (!isAllFetched) {
                                                setPage(page + 1);
                                            }
                                        }}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
                {!isFetching && doneReviews.length == 0 && toDoReviews.length == 0 && (
                    <div className='no-reviews-msg'>{Content.getValue('no_reviews_message')}</div>
                )}
            </div>
            <PopupModal
                isOpened={isEditOpen}
                closeModal={() => setIsEditOpen(false)}
                darkMode
                blurBackground
            >
                <WriteOfferReview
                    review={selectedReview}
                    afterReviewSubmit={(e) => {
                        setIsEditOpen(false)
                        afterReviewSubmit(e)
                    }}
                />
            </PopupModal>
            <DeleteModal
                deleteModalOpen={deleteModalOpen}
                handleReviewDelete={handleReviewDelete}
                onClose={() => { setDeleteModalOpen(null); }}
                reviewToDelete />
        </>
    );
};

export default UserReviews;