import React, { useState } from 'react';
import Images from 'utils/Images.js';
import * as Flags from 'country-flag-icons/react/3x2'



function OpenScreenSelect({ items, value, optionChangeHandler }) {
    const [open, setOpen] = useState(false);
    const [prefix, setPrefix] = useState(value);
    const clickHandler = () => setOpen(prev => !prev);
    const changeHandler = (value) => {
        optionChangeHandler(value);
        setOpen(false);
        setPrefix(value);
    }

    const Flag = ({ countryCode = 'UA' }) => {
        const FlagComponent = Flags[countryCode]
        return FlagComponent ? <FlagComponent /> : <></>
    };

    const getObjectKey = (obj, value) => {
        return Object.keys(obj).find(key => obj[key] === value);
    }

    return (
        <div className='select-container open-screen'>
            
            <div className='select-container-static open-screen' onClick={clickHandler} >
                <span>{prefix}  </span>

                <div className='select-option-icon open-screen'>
                    <Flag countryCode={getObjectKey(items, prefix)} />
                    <img
                        src={Images.imageURL('select-option-icon.png')}
                        alt='Expand'
                        width='11'
                        height='11'
                        className={`${open ? 'expanded' : ''}`}
                    />
                </div>
            </div>
            {open && Object.keys(items).length > 0 ?
                <div className='select-items open-screen'>
                    {
                        Object.keys(items)
                            .sort((a, b) => items[a].localeCompare(items[b]))
                            .map((country) => (
                                <div onClick={() => changeHandler(items[country])} key={items[country]} className="select-item-container">
                                    <p>
                                        {items[country]}
                                    </p>
                                    <Flag countryCode={country} className='country-flag' />
                                </div>
                            ))
                    }
                </div> :
                null
            }
        </div>
    );
}

export default OpenScreenSelect;