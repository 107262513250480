
const configuration = require('configuration.json');
const languageUrlCodes = configuration.languages.map(lang => lang.url_code);

const GetPathName = () => {
  if (window && window.location.pathname) {
    let normalizedPath = window.location.pathname.split('/').pop();
    if (languageUrlCodes.includes(normalizedPath)) {
      normalizedPath = ''
    }
    return '/' + normalizedPath;
  }
}

export default GetPathName;
