import React, { useRef, useEffect } from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;

function VideoGuide({ video, image, eventData }) {
    const player = useRef(null);

    const stopVideo = () => {
        if (player && !player.current.paused) {
            player.current.pause();
            player.current.currentTime = 0;
        }
    };

    useEffect(() => {
        window.addEventListener('resize', stopVideo);

        return () => {
            window.removeEventListener('resize', stopVideo);
        };
    }, []);


    return (
        <div className='offer-popup-play-relative'>

            <video
                poster={image ? image : Images.imageURL('offers/offer-popup-play-guides-bg.png')}
                className='offer-popup-play-container'
                ref={player}
            >
                <source src={video} type="video/mp4" />
            </video>
            <button className="hidden-button offer-popup-play-button" onClick={() => {
                Mixpanel.trackByArgs(mixpanelEvents['play_guide_video'], eventData);
                if (player.current.requestFullscreen) {
                    player.current.requestFullscreen();
                } else if (player.current.webkitRequestFullscreen) {
                    player.current.webkitRequestFullscreen(); // Safari
                }
                player.current.play();
            }}>
                <img className="z-1" src={Images.imageURL('play-purple.png')} height="20" width="auto" />    
                <div className="offer-guide-video-overlay"></div>
                <span className="offer-popup-white-heading-14 margin-bottom-0 z-1">{Content.getValue('report_popup_play_guide')}</span>
            </button>
        </div>
    );
}

export default VideoGuide;