import React, { useState, useEffect, memo } from 'react';
import ApiFacade from 'ApiFacade.js';
import UserData from 'User_Data.js';
import Platform from 'utils/Platform.js';
import HotOfferHomepage from 'components/offers/Hot_Offer_Homepage.js';
import EarnTeamPopup from 'components/offers/Earn_Team_Popup';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const incompleteOfferModes = configuration.incomplete_offer_modes;
const featuredOfferTypes = configuration.featured_offer_types;

function HotOfferLoaderHomepage({setOpenTermsPopup}) {
    const [state, setState] = useState({
        hotOffers: null,
        x2Offer: null,
        error: null,
        isLoaded: false,
        isRestricted: false,
    })
    const [isFirstTime, setIsFirstTime] = useState(false);

    useEffect(() => {
        if (ApiFacade.isLoggedIn()) {
            const platform = Platform.getPlatformName();
            const req = ApiFacade.hotOffers(platform);
            req.then(function (res) {
                if (res.ok){
                    let result = res.response;
                    UserData.setItem(userDataKeys.offer_completion_disclaimer_displayed, result.coinsDisclaimerDisplayed);
                    const offers = result.hotOffers;
                    setState({
                        isLoaded: true,
                        hotOffers: offers,
                        x2Offer: result.x2Offer
                    });
                } else if (res.unauthorized){
                    setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                } else {
                    setState({
                        isLoaded: true,
                        error: res.error
                    });
                } 
            },
                (error) => {
                    setState({
                        isLoaded: true,
                        error
                    });
                }
            )
            .catch(error => setState({ isLoaded: true, error }))
        }
    }, []);

    const { hotOffers, x2Offer } = state;
    const hotOffer = (hotOffers?.length > 0) ? hotOffers: null;
    const isX2Offer = x2Offer;
    const type = hotOffer ? featuredOfferTypes.hot_offer : featuredOfferTypes.x2_offer;
    const offers = hotOffer || (isX2Offer && x2Offer);

    if (state.isLoaded) {
        if (offers) {
            return (
                <HotOfferHomepage
                    key={`_offer-hot-offer-_${Date.now()}`}
                    offers={offers}
                    type={type}
                    mode={incompleteOfferModes.unavailable_offer}
                    isFirstTime={isFirstTime}
                    setIsFirstTime={setIsFirstTime}
                />
            );
        }
        else {
        return (
            <>
            <EarnTeamPopup setOpenTermsPopup={setOpenTermsPopup}/>
            </>
        );
        }
    }
}

export default memo(HotOfferLoaderHomepage);