import React, { Component } from 'react';
import LoginLogoutButton from 'components/buttons/Login_Logout_Button.js';
import MenuJoinButton from 'components/buttons/Menu_Join_Button.js';
import LoginGoogle from 'components/social/Login_Google.js';
import Images from 'utils/Images.js';
import UserData from 'User_Data.js';
import LanguageSelect from 'components/selects/Language_Select.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';
import styled from 'styled-components';
import NotificationsFetcher from 'utils/Notifications_Fetcher.js';
import ApiFacade from 'ApiFacade.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
import { MenuContext } from 'context/Menu_Context.js';
import 'design/gold.css';
// import Alerts from 'utils/Alerts';

const AnimatedSwitch = styled(Switch)(() => ({
    '&': {
        marginTop: 2,
        padding: "0px !important",
        height: "30px !important",
        width: '53px',
        borderRadius: 7,
        'span.Mui-checked': {
            color: 'white',
            transform: 'translateX(24px)',
        },
        '*': {
            borderRadius: 'inherit',
        },
        '.MuiSwitch-track': {
            backgroundColor: 'black !important',
            opacity: '1 !important',
            height: 30,
            width: 53,
            '&:before': {
                content: '"On"',
                position: 'absolute',
                width: 25,
                height: 45,
                fontSize: 10,
                left: 7,
                top: 0,
                color: 'white',
            },
        },
        '.MuiSwitch-switchBase': {
            padding: 0,
        },
        '.MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 25,
            height: 25,
            margin: '2px',
            borderRadius: 8,
            '&:after': {
                content: '"Off"',
                position: 'absolute',
                fontSize: 10,
                top: 0,
                left: 7,
                color: 'black',
            }
        },
    }
}));

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const mixpanelEvents = configuration.mixpanel_events;

let thisComponent;

const UISettings = configuration.ui_settings;

const menuSwipeSensitivity = UISettings.menu_swipe_sensitivity;
const menuToggleTimeout = UISettings.menu_toggle_timeout;

let touchStart;
let touchEnd;

let lastMenuUpdateTime;

class Menu extends Component {

    static thisStaticComponent = null;

    constructor(props) {
        super(props);
        thisComponent = this;
        Menu.thisStaticComponent = this;
        thisComponent.showMenu = false;
        lastMenuUpdateTime = new Date().getTime();
        const liveNewsCheck = JSON.parse(UserData.getItem(userDataKeys.receive_live_news));

        thisComponent.newsFetcher = liveNewsCheck && !thisComponent.newsFetcher ?
            new NotificationsFetcher() :
            null;

        thisComponent.state = {
            liveNewsCheck,
            newsFetcher: thisComponent.newsFetcher,
            randomPont: this.randomPoint,
            _isMounted: false,
        };
    }

    static contextType = MenuContext;

    static getMenu() {
        return Menu.thisStaticComponent;
    }

    toggleMenu() {
        if (new Date().getTime() - lastMenuUpdateTime < menuToggleTimeout) return;
        let oldClassName = document.getElementById('menu-drawer').className;
        let newClassName = oldClassName === 'menu-drawer' ? 'menu-drawer open' : 'menu-drawer';
        document.getElementById('menu-drawer').className = newClassName;
        if (newClassName === 'menu-drawer open') {
            document.body.style.overflow = 'hidden';
            document.getElementById('menu-drawer').style = '';
            document.getElementById('menu-options-container').style = '';
        } else if (newClassName === 'menu-drawer') {
            thisComponent.closeMenuSections();
            document.body.style.overflow = 'visible';
        }
        thisComponent.showMenu = !thisComponent.showMenu;
        if (thisComponent.showMenu) thisComponent.trackSwipping();
        else {
            // const { updateShowMenu } = this.context;
            // updateShowMenu()
            window.removeEventListener('touchstart', thisComponent.handleStart, false);
            window.removeEventListener('touchend', thisComponent.handleEnd, false);
            window.removeEventListener('touchmove', thisComponent.handleMove, false);
        }
        lastMenuUpdateTime = new Date().getTime();
    }

    liveRunningNewsChangeHandler({ target: { checked } }) {
        const req = ApiFacade.userUpdateLiveNewsPref(checked);
        req.then(() => {
            if (checked) {
                thisComponent.newsFetcher = new NotificationsFetcher();
            }
            else {
                UserData.setItem(userDataKeys.enforce_live_news, 1);
                thisComponent.newsFetcher?.removeActions();
                thisComponent.newsFetcher = null;
            }
            UserData.setItem(userDataKeys.receive_live_news, checked ? 1 : 0);
        });
        thisComponent.setState({ liveNewsCheck: checked });

        thisComponent.setState({ liveNewsCheck: checked });
    }


    closeMenuSections() {
        let aboutTreatspageSection = document.getElementById('about-treatcoin-section');
        if (aboutTreatspageSection !== null) {
            aboutTreatspageSection.parentNode.childNodes[1].className = 'menu-section';
            aboutTreatspageSection.childNodes[1].src = Images.imageURL('menu-arrow-down.png');
        }
        let myFinancesSection = document.getElementById('my-finances-section');
        if (myFinancesSection !== null) {
            myFinancesSection.parentNode.childNodes[1].className = 'menu-section';
            myFinancesSection.childNodes[1].src = Images.imageURL('menu-arrow-down.png');
        }
        let myTeamPortalSection = document.getElementById('my-team-portal-section');
        if (myTeamPortalSection !== null) {
            myTeamPortalSection.parentNode.childNodes[1].className = 'menu-section';
            myTeamPortalSection.childNodes[1].src = Images.imageURL('menu-arrow-down.png');
        }
        let accountSection = document.getElementById('account-section');
        if (accountSection !== null) {
            accountSection.parentNode.childNodes[1].className = 'menu-section';
            accountSection.childNodes[1].src = Images.imageURL('menu-arrow-down.png');
        }
        let reviewSection = document.getElementById('review-section');
        if (reviewSection !== null) {
            reviewSection.parentNode.childNodes[1].className = 'menu-section';
            reviewSection.childNodes[1].src = Images.imageURL('menu-arrow-down.png');
        }
    }

    toggleMenuSection(sectionId) {
        let menuSectionOptions = document.getElementById(sectionId).parentNode.childNodes[1];
        let className = menuSectionOptions.className;
        let newClassName = className === 'menu-section' ? 'menu-section open' : 'menu-section';
        menuSectionOptions.className = newClassName;

        let menuSectionArrow = document.getElementById(sectionId).childNodes[1];
        let newIndicatorImage =
            className === 'menu-section' ? 'menu-arrow-up.png' : 'menu-arrow-down.png';
        let newIndicatorURL = Images.imageURL(newIndicatorImage);
        menuSectionArrow.src = newIndicatorURL;
    }

    render() {
        const { liveNewsCheck } = thisComponent.state;
        const { navigate } = this.props;
 
        return (
            <div id="menu-div">
                <div className="menu-drawer" id="menu-drawer">
                    <div className="menu-container menu-top">
                        <RedirectWithLang
                            navigate={navigate} to={configuration.paths.home}>
                            <div id="logo-menu-div">
                                <img
                                    src={Images.imageURL('treat-coin-logo-new.png')}
                                    width="184"
                                    height="49"
                                    onClick={this.toggleMenu}
                                    alt="Home"
                                    className="home-image"
                                />
                            </div>
                        </RedirectWithLang>
                        <button
                            onClick={this.toggleMenu}
                            className="hidden-button" id="exit-menu-div">
                            <img
                                src={Images.imageURL('close.png')}
                                width="29"
                                height="29"
                                alt="Close menu"
                                className="close-menu"
                            />
                        </button>
                    </div>
                    <div className="menu-container menu-options-container" id="menu-options-container">
                        <RedirectWithLang
                            to={configuration.paths.home}
                            isButton
                            text={Content.getValue('menu_title_home')}
                            props={{
                                onClickHandler: (event) => {
                                    Mixpanel.trackByEvent(event);
                                    this.toggleMenu();
                                },
                                "data-event": mixpanelEvents.drawer_menu_click,
                                "data-click_from_main_menu": "homeOption",
                                className: "menu-option-button",
                            }}
                        />
                        <RedirectWithLang
                            navigate={navigate}
                            to={configuration.paths.top_12_offers}
                            isButton
                            text={Content.getValue('menu_title_top_12_offers')}
                            props={{
                                onClickHandler: (event) => {
                                    Mixpanel.trackByEvent(event);
                                    this.toggleMenu();
                                },
                                "data-event": mixpanelEvents.drawer_menu_click,
                                "data-click_from_main_menu": "top12OffersOption",
                                className: "menu-option-button",
                            }}
                        />
                        <RedirectWithLang
                            navigate={navigate}
                            to={configuration.paths.playground}
                            isButton
                            text={Content.getValue('menu_title_playground')}
                            props={{
                                onClickHandler: (event) => {
                                    Mixpanel.trackByEvent(event);
                                    this.toggleMenu();
                                },
                                "data-event": mixpanelEvents.drawer_menu_click,
                                "data-click_from_main_menu": "playgroundOption",
                                className: "menu-option-button",
                            }}
                        />
                        <RedirectWithLang
                            navigate={navigate}
                            to={configuration.paths.leaderboards}
                            isButton
                            text={Content.getValue('menu_title_leaderboards')}
                            props={{
                                onClickHandler: (event) => {
                                    Mixpanel.trackByEvent(event);
                                    this.toggleMenu();
                                },
                                "data-event": mixpanelEvents.drawer_menu_click,
                                "data-click_from_main_menu": "leaderboardsOption",
                                className: "menu-option-button",
                            }}
                        />
                        {ApiFacade.isLoggedIn() && (
                            <>
                                <RedirectWithLang
                                    navigate={navigate}
                                    to={configuration.paths.offers_clicked}
                                    isButton
                                    text={Content.getValue('menu_title_offers_clicked')}
                                    props={{
                                        onClickHandler: (event) => {
                                            Mixpanel.trackByEvent(event);
                                            this.toggleMenu();
                                        },
                                        "data-event": mixpanelEvents.drawer_menu_click,
                                        "data-click_from_main_menu": "homeOption",
                                        className: "menu-option-button",
                                    }}
                                />
                                <div className="menu-section-container">
                                    <button
                                        data-event={mixpanelEvents.drawer_menu_click}
                                        data-click_from_main_menu="myTeamPortalSection"
                                        className="menu-option-section-button"
                                        id="my-team-portal-section"
                                        onClick={(event) => {
                                            Mixpanel.trackByEvent(event);
                                            return this.toggleMenuSection('my-team-portal-section');
                                        }}
                                    >
                                        {Content.getValue('menu_section_my_team_portal')}
                                        <img
                                            data-event={mixpanelEvents.drawer_menu_click}
                                            data-click_from_main_menu="myTeamPortalSection"
                                            src={Images.imageURL('menu-arrow-down.svg')}
                                            width="14"
                                            height="14"
                                            alt="Toggle section"
                                            className="menu-section-arrow"
                                        />
                                    </button>
                                    <div className="menu-section">
                                        
                                        <RedirectWithLang
                                            navigate={navigate}
                                            to={configuration.paths.my_team_referrals}
                                            isButton
                                            text={Content.getValue('menu_title_build_your_team')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "myTeamReferralsOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        />
                                        <RedirectWithLang
                                            navigate={navigate}
                                            to={configuration.paths.my_team_referrals + '?stats=1'}
                                            isButton
                                            text={Content.getValue('menu_title_my_team_referrals')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "myTeamReferralsOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className="menu-section-container">
                                    <button
                                        data-event={mixpanelEvents.drawer_menu_click}
                                        data-click_from_main_menu="myFinancesSection"
                                        className="menu-option-section-button"
                                        id="my-finances-section"
                                        onClick={(event) => {
                                            Mixpanel.trackByEvent(event);
                                            return this.toggleMenuSection('my-finances-section');
                                        }}
                                    >
                                        {Content.getValue('menu_section_my_finances')}
                                        <img
                                            data-event={mixpanelEvents.drawer_menu_click}
                                            data-click_from_main_menu="myFinancesSection"
                                            src={Images.imageURL('menu-arrow-down.svg')}
                                            width="14"
                                            height="14"
                                            alt="Toggle section"
                                            className="menu-section-arrow"
                                        />
                                    </button>
                                    <div className="menu-section">
                                        <RedirectWithLang
                                            navigate={navigate}
                                            to={configuration.paths.my_treat_coin_earnings}
                                            isButton
                                            text={Content.getValue('menu_title_my_treat_coin_earnings')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "myTreatCoinEarningsOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        >
                                        </RedirectWithLang>
                                        <RedirectWithLang
                                            navigate={navigate}
                                            to={configuration.paths.my_treat_coin_payouts}
                                            isButton
                                            text={Content.getValue('menu_title_my_treat_coin_payouts')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "myTreatCoinPayoutsOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        >
                                        </RedirectWithLang>
                                        <RedirectWithLang
                                            navigate={navigate}
                                            to={configuration.paths.cashout}
                                            isButton
                                            text={Content.getValue('menu_title_cashout')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "cashoutOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        >
                                        </RedirectWithLang>

                                    </div>
                                </div>
                                <div className="menu-section-container">
                                    <button
                                        data-event={mixpanelEvents.drawer_menu_click}
                                        data-click_from_main_menu="accountSection"
                                        className="menu-option-section-button"
                                        id="account-section"
                                        onClick={(event) => {
                                            Mixpanel.trackByEvent(event);
                                            return this.toggleMenuSection('account-section');
                                        }}
                                    >
                                        {Content.getValue('menu_section_account')}
                                        <img
                                            data-event={mixpanelEvents.drawer_menu_click}
                                            data-click_from_main_menu="accountSection"
                                            src={Images.imageURL('menu-arrow-down.svg')}
                                            width="14"
                                            height="14"
                                            alt="Toggle section"
                                            className="menu-section-arrow"
                                        />
                                    </button>
                                    <div className="menu-section">
                                        <RedirectWithLang
                                            navigate={navigate}
                                            to={configuration.paths.account_settings}
                                            isButton
                                            text={Content.getValue('menu_title_account_settings')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "accountSettingsOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="menu-section-container">
                                    <button
                                        data-event={mixpanelEvents.drawer_menu_click}
                                        data-click_from_main_menu="reviewsSection"
                                        className="menu-option-section-button"
                                        id="review-section"
                                        onClick={(event) => {
                                            Mixpanel.trackByEvent(event);
                                            return this.toggleMenuSection('review-section');
                                        }}
                                    >
                                        {Content.getValue('menu_section_reviews')}
                                        <img
                                            data-event={mixpanelEvents.drawer_menu_click}
                                            data-click_from_main_menu="reviewsSection"
                                            src={Images.imageURL('menu-arrow-down.svg')}
                                            width="14"
                                            height="14"
                                            alt="Toggle section"
                                            className="menu-section-arrow"
                                        />
                                    </button>
                                    <div className="menu-section">
                                        <RedirectWithLang
                                            to={configuration.paths.my_reviews}
                                            isButton
                                            text={Content.getValue('menu_title_my_reviews')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "myReviewsOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        >
                                        </RedirectWithLang>
                                        <RedirectWithLang
                                            to={configuration.paths.search_for_reviews}
                                            isButton
                                            text={Content.getValue('menu_title_search_for_reviews')}
                                            props={{
                                                onClickHandler: (event) => {
                                                    Mixpanel.trackByEvent(event);
                                                    this.toggleMenu();
                                                },
                                                "data-event": mixpanelEvents.drawer_menu_click,
                                                "data-click_from_main_menu": "searchForReviewsOption",
                                                className: "menu-option-button-subtitle",
                                            }}
                                        >
                                        </RedirectWithLang>
                                    </div>
                                </div>
                            </>
                        )}  
                        <div className="menu-section-container">
                            <button
                                data-event={mixpanelEvents.drawer_menu_click}
                                data-click_from_main_menu="aboutTreatspageSection"
                                className="menu-option-section-button"
                                id="about-treatcoin-section"
                                onClick={(event) => {
                                    Mixpanel.trackByEvent(event);
                                    return this.toggleMenuSection('about-treatcoin-section');
                                }}
                            >
                                {Content.getValue('menu_section_about_treatcoin')}
                                <img
                                    data-event={mixpanelEvents.drawer_menu_click}
                                    data-click_from_main_menu="aboutTreatspageSection"
                                    src={Images.imageURL('menu-arrow-down.svg')}
                                    width="14"
                                    height="14"
                                    alt="Toggle section"
                                    className="menu-section-arrow"
                                />
                            </button>
                            <div className="menu-section">
                                <RedirectWithLang
                                    to={configuration.paths.about_us}
                                    isButton
                                    text={Content.getValue('menu_title_about_us')}
                                    props={{
                                        onClickHandler: (event) => {
                                            Mixpanel.trackByEvent(event);
                                            this.toggleMenu();
                                        },
                                        "data-event": mixpanelEvents.drawer_menu_click,
                                        "data-click_from_main_menu": "aboutUsOption",
                                        className: "menu-option-button-subtitle",
                                    }}
                                >
                                </RedirectWithLang>
                                <RedirectWithLang
                                    to={configuration.paths.treatcoins_mission}
                                    isButton
                                    text={Content.getValue('menu_title_treatcoins_mission')}
                                    props={{
                                        onClickHandler: (event) => {
                                            Mixpanel.trackByEvent(event);
                                            this.toggleMenu();
                                        },
                                        "data-event": mixpanelEvents.drawer_menu_click,
                                        "data-click_from_main_menu": "treatcoinsMissionOption",
                                        className: "menu-option-button-subtitle",
                                    }}
                                >
                                </RedirectWithLang>
                                <RedirectWithLang
                                    to={configuration.paths.faq}
                                    isButton
                                    text={Content.getValue('menu_title_faq')}
                                    props={{
                                        onClickHandler: (event) => {
                                            Mixpanel.trackByEvent(event);
                                            this.toggleMenu();
                                        },
                                        "data-event": mixpanelEvents.drawer_menu_click,
                                        "data-click_from_main_menu": "FAQOption",
                                        className: "menu-option-button-subtitle",
                                    }}
                                />
                            </div>
                        </div>

                        <button
                            onClick={(event) => {
                                Mixpanel.trackByEvent(event);
                                this.toggleMenu();
                                window.open(configuration.paths.help_center, '_blank');
                            }}
                            data-event={mixpanelEvents.drawer_menu_click}
                            data-click_from_main_menu="helpCenterOption"
                            className="menu-option-button">
                            {Content.getValue('menu_title_help_center')}
                        </button>
                        
                        {ApiFacade.isLoggedIn() && (
                            <>
                                <button className="menu-option-button flex justify-between">
                                    <span>{Content.getValue('menu_title_news_running_live')}</span>
                                    <FormGroup>
                                        <FormControlLabel
                                            checked={Boolean(liveNewsCheck)}
                                            onChange={thisComponent.liveRunningNewsChangeHandler}
                                            control={<AnimatedSwitch />}
                                        />
                                    </FormGroup>
                                </button>

                                {/*<div className='menu-gold-benefits-btn' onClick={()=> Alerts.showTreatcoinGoldBenefitsPopup()}>
                                    <span className='gold-color'>
                                        {Content.getValue('treatcoin_gold_benefits_btn')}
                                    </span>
                                </div> */}
                            </>
                        )}
                        
                        <div className="menu-section-container">
                            <LanguageSelect toggleMenu={this.toggleMenu} />
                        </div>
                    </div>
                    <div className="menu-bottom-container">
                        <LoginGoogle navigate={navigate} />
                        <MenuJoinButton clickHandler={() => this.toggleMenu()} navigate={navigate} />
                        <LoginLogoutButton fromMainMenu={true} clickHandler={() => this.toggleMenu()} />
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        thisComponent.setState(prev => ({
            ...prev, _isMounted: true
        }));
    }

    componentWillUnmount() {
        thisComponent.newsFetcher?.removeActions();
        thisComponent.newsFetcher = null;
        window.removeEventListener('touchstart', this.handleStart, false);
        window.removeEventListener('touchend', this.handleEnd, false);
        window.removeEventListener('touchmove', this.handleMove, false);
        thisComponent._isMounted = false;
    }

    trackSwipping() {
        window.addEventListener('touchstart', this.handleStart, false);
        window.addEventListener('touchend', this.handleEnd, false);
        window.addEventListener('touchmove', this.handleMove, false);
    }

    handleStart(e) {
        touchStart = e.targetTouches[0].clientX;
    }

    handleMove(e) {
        touchEnd = e.targetTouches[0].clientX;
    }

    handleEnd() {
        if (touchStart - touchEnd > menuSwipeSensitivity) {
            thisComponent.toggleMenu();
        }
    }
}

export default Menu;
