import React, { Component } from 'react';

class PrivacyText extends Component {

    render() {
        return (
            <div>
                <p className="legal-last-updated"><span className="bold-text">Last Updated:</span> October 2023</p>
                <div className="content-subtitle top-title">Introduction</div>
                <p className="long-content">Pythia Plus Ltd., including its affiliates (collectively, the <span className="bold-text">"Company"</span>, <span className="bold-text">"we"</span>, <span className="bold-text">"our"</span> or <span className="bold-text">"us"</span>) are deeply committed to safeguarding the privacy expectations of its users ("<span className="bold-text">User(s)</span>", <span className="bold-text">"you"</span> or <span className="bold-text">"your"</span>). Accordingly, we have put in place this privacy policy (<span className="bold-text">"Policy"</span>), which outlines our data protection practices, including how we collect, use, disclose and protect your personal information, as well as your rights with respect to your personal information.</p>
                <p className="long-content">Please read this Policy very carefully and use it to make informed decisions, as your access to and use of our service, whether by visiting our website ("<span className="bold-text">Websites</span>") or by creating an account through our service (collectively "<span className="bold-text">Service</span>"), signifies that you have read and understand all the terms detailed herein and you hereby agree to this Policy and to the collection, use, retention and disclosure of your personal information in accordance with these terms. If you do not agree to the terms of this Policy, please do not use our Services. You are not legally required to provide us with any personal information, but without it, we will not be able to provide you with the full range or with the best experience of using our Services.</p>
                <p className="long-content">Personal information is defined as any information that identifies an individual or could reasonably identify an individual with reasonable effort, including without limitation: your name, surname, date of birth, residing address, IP address, email address or telephone number, ID documentation, financial documents as utility bill or bank statements as well as other non-public information about you that is associated with or linked to any foregoing, hereinafter referred to as the "<span className="bold-text">Personal Information</span>". Processing activities include, among other things, the collection, use, retention and disclosure of such data.</p>
                <p className="long-content">Your Personal Information will be processed by us (including any of our agents and/or employees), and (where appropriate) any other company in our company group and/or our partners and subcontractors, in accordance with this Policy.</p>
                <p className="long-content">If you have any questions regarding this Policy or the way we use your Personal Information, please refer to our table of contents and to the section 12. "HOW TO CONTACT US?" below. The Company is the controller of the Personal Information collected, and is responsible for the processing of your Personal Information.</p>
                <p className="long-content">In this Policy, you can learn about:</p>
                <p className="long-content"><span className="bold-text">1.	WHAT INFORMATION WE COLLECT?</span></p>
                <p className="long-content"><span className="bold-text">2.	MINORS</span></p>
                <p className="long-content"><span className="bold-text">3.	PURPOSES AND CONDITIONS FOR PROCESSING PERSONAL INFORMATION</span></p>
                <p className="long-content"><span className="bold-text">4.	MARKETING</span></p>
                <p className="long-content"><span className="bold-text">5.	WITH WHOM WE SHARE INFORMATION?</span></p>
                <p className="long-content"><span className="bold-text">6.	INTERNATIONAL TRANSFER OF INFORMATION</span></p>
                <p className="long-content"><span className="bold-text">7.	THIRD PARTY TRACKING TECHNOLOGIES</span></p>
                <p className="long-content"><span className="bold-text">8.	RETENTION OF PERSONAL INFORMATION</span></p>
                <p className="long-content"><span className="bold-text">9.	YOUR RIGHTS</span></p>
                <p className="long-content"><span className="bold-text">10.	CHANGES TO THE PRIVACY POLICY</span></p>
                <p className="long-content"><span className="bold-text">11.	LANGUAGE VERSION</span></p>
                <p className="long-content"><span className="bold-text">12.	HOW TO CONTACT US?</span></p>

                <div className="content-subtitle">1. WHAT INFORMATION WE COLLECT?</div>
                <p className="long-content">We collect Personal Information from our Users and visitors of our Service, which consists of the following:</p>
                <ul className="legal-list">
                    <li><p className="long-content"><span className="bold-text">Registration and login:</span> When you open an account and register to use our Service, you will be asked to provide us with certain details about yourself, such as: your email address, zip code, username or similar identifier and password. Please note that when you wish to update your account profile, we also collect Personal Information from you.</p></li>
                    <li><p className="long-content"><span className="bold-text">Identification documentation:</span> We collect and store uploaded documents, in order to identify customers, to process your requests and/or to comply with effective laws and regulations. These documents are also used for the purpose of prevention and detection of fraud, prevention of money-laundering and terrorist funding.</p></li>
                    <li><p className="long-content"><span className="bold-text">Payment information:</span> we do not  collect your payment information, however in order for you to enjoy all of the functions of the Service, our trusted third party payment processors may collect certain payment information from you, such as your credit card number. </p></li>
                    <li><p className="long-content"><span className="bold-text">Voluntary information:</span> We also collect information which you provide us voluntarily in order to personalize your account or for a specific function, which you voluntarily provide to us at your discretion for a specific function. In addition, we collect your communication when you respond to communications from us, provide us with feedback, communicate with us via email or share additional information about yourself through your use of the Service.</p></li>
                    <li><p className="long-content"><span className="bold-text">Device information:</span> We collect specific types of connection details and information with regard to your device, software or hardware that could identify you, such as: device’s unique identifiers (e.g. UDID, IMEI, MAC address), browser fingerprinting, IP address, blockchain address, wallet type, and geo-location data.</p></li>
                    <li><p className="long-content"><span className="bold-text">Information we collect from third parties:</span> We sometimes collect Personal Information from third party service providers, such as information which is gathered in order to verify your identity and prevent fraudulent or illegal activity.</p></li>
                    <li><p className="long-content"><span className="bold-text">Log-in history and technical information:</span> To enhance the functionality of the Service and to provide you with a better user experience, we collect technical information transmitted by your device, including certain software and hardware information (e.g. the type of browser and operating system your device uses, language preference, access time and the domain name of the website from which you linked to the Service; etc.).</p></li>
                </ul>

                <div className="content-subtitle">2. MINORS</div>
                <p className="long-content">The Service are not designed or directed to persons under the age of 18 or persons under the age of legal consent with respect to the use of the Service of any jurisdiction, whichever is higher ("Legally of Age").</p>
                <p className="long-content">We do not collect Personal Information of a minor.</p>
                <p className="long-content"><span className="bold-text">If you are not Legally of Age, you are prohibited to access or use the Service and you are prohibited to provide any Personal Information to us.</span></p>
                <p className="long-content">We reserve the right to access and verify any Personal Information collected from you. In the event that we become aware that a user who is not Legally of Age has shared any information, we shall discard such information.</p>
                
                <div className="content-subtitle">3. PURPOSES AND CONDITIONS FOR PROCESSING PERSONAL INFORMATION</div>
                <p className="long-content">This section outlines the underlying purposes and legal bases for the processing of your Personal Information:</p>
                <table className="legal-table">
                    <thead>
                        <tr>
                            <th>Purpose</th>
                            <th>Legal basis</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><span className="bold-text">Provision of our Service; support and customer relations.</span> We use your Personal Information, such as your username and email address for customer services purposes as well as to present our Websites to you. This includes, for example, managing and updating your account, providing and operating the Service, keeping you informed about our last updates or responding to any of your inquiries, as well as your request to exercise your User rights.</td>
                            <td>The legal bases for processing this data are the performance of our contractual obligations towards you for example as described under this Policy and/or Terms of Use (Art. 6.1(b) GDPR); your consent (for example, when you agree to provide Personal Information by accepting our Cookie Notice, Policy and Terms of Use) (Art. 6.1(a) GDPR); compliance with our legal obligations (Art. 6.1(c) GDPR); and our legitimate interests (Art. 6.1(f) GDPR). Our legitimate interests in this case are enforcing our policies, protection against fraud and misuse of our Service.</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Improving our Services.</span> We collect and analyze information about you and your usage of our Service to improve the usability and effectiveness of our Service, including debugging to identify and repair errors or undertaking internal research for technological development and demonstration.</td>
                            <td>The legal basis for processing this data is our legitimate interests (Art. 6.1(f) GDPR), in this case – providing and improving our Service.</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Marketing, advertising and analytics.</span> We use your Personal Information in order to provide you with personalized advertisements, including behavioral advertising when you visit our Service, to market our Service and to gather aggregate usage information for analytics, statistical and research purposes, including notification of promotional offers.</td>
                            <td>The legal bases for processing this data are your consent (when required) and our legitimate interests (Art. 6.1(a), 6.1(f) GDPR). Our legitimate interests in this case are providing you with tailored services, content and advertisements that better fit your interests as well as to promote our Service.</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Integrity.</span> We process certain information about you and your usage of the Services in order to keep the integrity and security of the Services.</td>
                            <td>The legal bases for processing this data are compliance with our legal obligations (Art. 6.1(c) GDPR) and our legitimate interests (Art. 6.1(f) GDPR). Our legitimate interests in this case are keeping the integrity of our Services and the safety of our end-users.</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Dispute resolution and protection of our legal claims.</span> We collect your Personal Information in order to investigate violation of our policies, enable us to resolve disputes in connection with your use of the Service and to establish and defend our legal claims.</td>
                            <td>The legal basis for processing this data is our legitimate interests (Art. 6.1(f) GDPR). Our legitimate interests in this case are to establish and defend our legal claims.</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Payments.</span> We collect from our third-party payment processors certain information about your payment instruments for the purpose of connecting your payment instrument to your account on our Services.</td>
                            <td>The legal bases for processing this data are the performance of our contractual obligations (Art. 6.1(b) GDPR); the protection of our legitimate interests (Art. 6.1(f) GDPR), in this case enforcing our policies and preventing misuse of our Services; and compliance with legal obligations to which we are subject (Art. 6.1(c) GDPR).</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Corporate transactions.</span> We will share your Personal Information with a potential purchasers, successors or investors in the Company or in the event of a corporate transaction (e.g. sale of a substantial part of our business, merger, reorganization, bankruptcy, consolidation or asset sale of an asset or transfer in the operation thereof) in relation to the Company.</td>
                            <td>The legal basis for processing this data is our legitimate interests (Art. 6.1(f) GDPR).</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Prevention of fraud or violation of our policies.</span> We process your Personal Information to detect and prevent fraudulent and illegal activity or any other type of activity that jeopardizes or negatively affect the integrity of the Service, including by identifying risks associated with your activity on our Service. We will also investigate any violations of our policies and enforce them.</td>
                            <td>The legal basis for processing this data is our legitimate interests (Art. 6.1(f) GDPR). Our legitimate interests in this case are to protect our Company and other Users against fraud.</td>
                        </tr>
                        <tr>
                            <td><span className="bold-text">Compliance with applicable laws.</span> We process your Personal Information to comply with our various legal obligations such as adult content legislation (where applicable), anti-money laundering, identity verification (e.g., suspicion of minor using our Service), prevention of fraud, complying with data subject rights etc.</td>
                            <td>The legal basis for processing this data is to comply with our various legal obligations (Art. 6.1(c) GDPR) and our legitimate interests (Art. 6.1(f) GDPR). </td>
                        </tr>
                    </tbody>
                </table>
                <p className="long-content">Where you have not consented (or have withdrawn your consent) to the processing of your Personal Information by us, we will continue to process your Personal Information : (a) where processing is required for the performance of the contract (i.e. the Terms of Use) between you and us; and/or (b) where processing is necessary for compliance with a legal obligation to which we are subject; (c) where processing is necessary for the purposes of a legitimate interest of the Company and its affiliates (including for marketing).</p>

                <div className="content-subtitle">4. MARKETING</div>
                <p className="long-content">We will use your Personal Information, such as your email address, collected by ourselves or through our trusted third party subcontractors for the purpose of providing you with promotional materials via different marketing techniques such as direct email, telephone marketing, SMS and post concerning the Service as well as products, services, websites and applications which relate to the Company's business partners and its affiliates (collectively: "<span className="bold-text">Marketing Affiliates</span>"), which we believe could interest you.</p>
                <p className="long-content">We also share and disclose Personal Information with our Marketing Affiliates for the purpose of providing you different marketing offers, which we, or our Marketing Affiliates, believe are relevant for you.</p>
                <p className="long-content">We will use your Personal Information for the purpose of providing you with promotional materials solely where we have a legitimate interest in doing so, or where we have obtained your affirmative consent.</p>
                <p className="long-content">You have the possibility at any time to decline receiving further marketing offers from us or from our business partners and marketing affiliates by either: following the links inserted in the promotional emails (e.g., selecting the opt-out link) or contacting our customer support. Please note that even if you unsubscribe from our marketing mailing list, we shall continue to send you service-related updates and notifications.</p>
                
                <div className="content-subtitle">5. WITH WHOM WE SHARE INFORMATION?</div>
                <p className="long-content">We do not rent, sell, or share your Personal Information with third parties ("<span className="bold-text">Recipients</span>") except as described in this Policy.  The Personal Information will be disclosed to Recipients only to the extent required for the specific purpose, as stipulated in this Policy.</p>
                <p className="long-content">We disclose Personal Information with any of the following recipients:</p>

                <ul className="legal-list">
                    <li>
                        <p className="long-content">Companies within the Company and other affiliated companies;</p>
                    </li>
                    <li>
                        <p className="long-content">Subcontractors and third party service providers, as well as their subcontractors, which by way of example include (but is not limited to) cloud computing companies, marketing affiliates, identity verification and fraud prevention services, and other data verifiers;</p>
                    </li>
                    <li>
                        <p className="long-content">Payment service providers, payment processors </p>
                    </li>
                    <li>
                        <p className="long-content">To any third parties who provide services in relation to the operation or promotion of the Service;</p>
                    </li>
                    <li>
                        <p className="long-content">Auditors, contractors or advisers of any of the Company’s business processes;</p>
                    </li>
                    <li>
                        <p className="long-content">To any third parties who investigate, detect or prevent fraudulent or illegal activity (e.g. governmental authorities, police, banks and other investigatory organizations);</p>
                    </li>
                    <li>
                        <p className="long-content">Governmental and regulatory bodies, in accordance with applicable laws and regulations;</p>
                    </li>
                    <li>
                        <p className="long-content">Law enforcement agencies or authorities, as a response to their request; and</p>
                    </li>
                    <li>
                        <p className="long-content">Potential purchasers or investors in any of the companies within the Company's group (if applicable), or in the event of a corporate transaction (e.g. sale of a substantial part of our business, merger, reorganization, bankruptcy, consolidation or asset sale of an asset or transfer in the operation thereof) in relation to any company within the Company (in such event, the acquiring company or transferee will assume the rights and obligations as described in this Policy).</p>
                    </li>
                    <li>
                        <p className="long-content">Research, technical diagnostics and analytics vendors;</p>
                    </li>
                    <li>
                        <p className="long-content">Marketing and advertising partners, in accordance with our marketing policy (see above, under "Marketing"); and</p>
                    </li>
                    <li>
                        <p className="long-content">Whenever we believe in good faith that disclosure is necessary to protect our rights or legal claims, enforce our policies (including our Terms of Use and Policy), protect your safety or the safety of others, as well as to investigate or prevent any fraud, for security reasons or to help us with any other related technical issue.</p>
                    </li>
                </ul>
                <div className="content-subtitle">6. INTERNATIONAL TRANSFER OF INFORMATION</div>
                <p className="long-content">Since we operate globally, it is necessary for the provision of the Service and to the extent required for the specific purpose, as stipulated in this Policy to transfer your Personal Information to countries outside the European Economic Area ("EEA"). The data protection and other laws of these countries are not as comprehensive as those in the EEA.</p>
                <p className="long-content">In these circumstances, we use our best efforts to ensure that your Personal Information is protected in accordance with our Policy, through contractual means (such as by using the standard contractual clauses approved by the relevant European Commission for data transfer, as available <a className="legal-link" href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" target="_blank">here</a>) or other means (such as ensuring that the European Commission decisions determined that such jurisdictions offer an adequate level of protection as available <a className="legal-link" href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en" target="_blank">here</a>).</p>
                <p className="long-content">For more information about the transfer of your Personal Information outside of the EEA, our contact details are available under the section 12. "HOW TO CONTACT US?".</p>
                
                <div className="content-subtitle">7. THIRD PARTY TRACKING TECHNOLOGIES</div>
                <p className="long-content">We use third-party advertising technology across our Websites to serve advertisements when you visit or use our Service. We use and authorize third parties to use web beacons, cookies, pixels, scripts, tags and other technologies ("<span className="bold-text">Tracking Technologies</span>").</p>
                <p className="long-content">The Tracking Technologies automatically collect information about you, your online behavior on the Websites, and your device (for example your computer or mobile device), for different purposes, including improving and personalizing your navigation on our Service and enhancing our Service’ performance. Third parties use this information to provide you with targeted content.</p>
                <p className="long-content">You can set your browser to refuse all third party cookies, or to alert you when cookies are being sent, also you can opt-out of many third party ad networks, including those operated by members of the Network Advertising Initiative ("<span className="bold-text">NAI</span>") and the Digital Advertising Alliance ("<span className="bold-text">DAA</span>").</p>
                <p className="long-content">We also allow third parties to collect information about you through Tracking Technologies. To learn more please visit our Cookie Notice.</p>
                <p className="long-content">For more information regarding this practice by NAI members and DAA members, and your choices regarding having this information used by these companies, including how to opt-out of third party ad networks operated by NAI and DAA members, please visit their respective websites: <a className="legal-link" href="https://optout.networkadvertising.org/?c=1" target="_blank">optout.networkadvertising.org</a> and <a className="legal-link" href="https://www.aboutads.info/choices" target="_blank">www.aboutads.info/choices</a>.</p>

                <div className="content-subtitle">8. RETENTION OF PERSONAL INFORMATION</div>
                <p className="long-content">If you have registered with an account through our Service, the Company will retain your Personal Information during the period your account is active. In addition, the Company will retain your Personal Information for additional periods, to enable the Company to meet its legal obligations under applicable laws or regulations.</p>
                <p className="long-content">In addition, the Company shall retain your Personal Information for longer periods, provided that retaining such information is only necessary for the Company’s legitimate interests, such as fraud prevention and record keeping, resolving or excising claims regarding potential disputes.</p>
                
                <div className="content-subtitle">9. YOUR RIGHTS</div>
                <p className="long-content">You have certain rights regarding the collection and processing of Personal Information. To the extent these rights apply to and concern you, you can exercise them by contacting us via the contact details available under the section below "HOW TO CONTACT US?" and exercise the following rights:</p>
                <ol className="legal-list">
                    <li><p className="long-content">Rights of access – You can obtain a copy of the Personal Information held by the Company at any time;</p></li>
                    <li><p className="long-content">Right of data portability – You have the right to receive your Personal Information, in a structured, commonly used and machine-readable format and to request that your Personal Information be transmitted to a third party of your choice;</p></li>
                    <li><p className="long-content">Right of rectification – In the event that the data held about you by the Company is inaccurate, incomplete or outdated, you have the possibility to request the rectification of said data (except in cases where the information is required to be kept in its original format under any applicable laws and regulations);</p></li>
                    <li><p className="long-content">Right of erasure – You have the possibility at any time to request that the Company erases data held about you by sending an email to the Company as outlined in this Policy. However, you are advised that with the deletion of your Personal Information, you might not be able to use the Service anymore or the account might be totally closed;</p></li>
                    <li><p className="long-content">Right to restriction of processing, objection to processing – At any time, you have the right to request that the Company restricts or ceases to conduct certain data processes provided that there exists no other lawful basis on which the Company is authorized to process said data;</p></li>
                    <li><p className="long-content">Right to withdraw your consent – You have the right to withdraw your given consent at any time. The withdrawal of consent will not affect the lawfulness of processing;</p></li>
                    <li><p className="long-content">Right to lodge a complaint – All requests, complaints or queries should be addressed to the Company with the subject ‘Privacy’. We will consider any requests, complaints or queries and provide you with a reply in a timely manner. You can also file a complaint with the relevant data protection authority should you not be satisfied with the way in which we handle your Personal Information. We take our obligations seriously and we ask that any concerns be first brought to our attention, so that we can try to resolve this.</p></li>
                </ol>
                <p className="long-content">Please note that these rights are not absolute, and can be subject to our own legitimate interests and regulatory requirements. You are welcome to contact us for any questions, requests or complaints through our contact details below.</p>
                <p className="long-content">We will make an effort to reply within a reasonable timeframe. Please feel free to reach out to us at any time. If you are unsatisfied with our response, you can lodge a complaint with the competent supervisory authority.</p>
                <p className="long-content">Also, you should be aware that unless you have registered and created an account on our Website, we could not be able to respond to your request as we do not have sufficient information to identify you. We could also rectify, replenish or remove incomplete or inaccurate information, at any time and at our own discretion, in accordance with our internal policies. Similarly, we will ask you to provide us with additional information in order to adequately verify your identity upon your request.</p>
                
                <div className="content-subtitle">10. CHANGES TO THE PRIVACY POLICY</div>
                <p className="long-content">We reserve the right to change this Policy at any time, so please re-visit this page frequently. We will use reasonable efforts to notify you in the event substantial changes are made to this Policy, as required by law. Any modifications, updates, alteration will be effective upon our publishing of this Privacy on our Websites and as of the stated “Last Revised” date, and your continued use of the Service after the Last Revised date will constitute acceptance of, and agreement to be bound by those changes.</p>
                
                <div className="content-subtitle">11. LANGUAGE VERSION</div>
                <p className="long-content">The Policy has been drafted in the English language, which is the original and controlling version of this Policy. All translations of this Policy into other languages shall be solely for convenience and shall not control the meaning or application of this Policy. In the event of any discrepancy between the meanings of any translated versions of the Policy and the English language version, the meaning of the English language version shall prevail.</p>
                
                <div className="content-subtitle">12. HOW TO CONTACT US</div>
                <p className="long-content">If you have any general questions about the Service or the information that we collect about you and how we process it, please contact us via email:</p>
                <ul className="legal-list">
                    <li><p className="long-content"><a target="_blank" className="legal-link" href="mailto:support@treatcoin.com">Pythia Plus Limited</a></p></li>
                    <li><p className="long-content"><a target="_blank" className="legal-link" href="mailto:DPO@TreatCoin.com">Attn: Data Protection Officer</a></p></li>
                    <li><p className="long-content"><a target="_blank" className="legal-link" href="mailto:DPO@TreatCoin.com">DPO@TreatCoin.com</a></p></li>
                </ul>
                <p className="long-content">We will make an effort to reply within a reasonable timeframe. Please feel free to reach out to us at any time. If you are unsatisfied with our response, you can reach out to the applicable data protection supervisory authority.</p>
            </div>
        );
    }

}

export default PrivacyText;