import { forwardRef } from 'react';
import '../../design/inputs.css';
import Images from 'utils/Images';


const SearchInput = forwardRef((props, ref) => {

    const {
        label,
        direction, // row || column
        align, // start || center || end
        width,
        height,
        placeHolder,
        onChange,
        error,
        onClick,
        backgroundColor,
        borderColor,
        textColor
    } = props;

    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: direction || 'column',
            alignItems: align || 'start',
            maxWidth: width || 300,
            gap: 5,
            position: "relative"
        },
        input: {
            height: height || 40,
            backgroundColor: backgroundColor || "transparent",
            color: textColor || "#fff",
            borderColor: borderColor || '#33354F',
        },
        icon: {
            position: "absolute",
            right: 3,
            height: height || 40,
            cursor: "pointer",
            width: height || 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            opacity: 0.3
        }
    }


    const onChangeHandler = (e) => {
        onChange && onChange(e.target.value);
    }

    const onClickHandler = () => {
        onClick && onClick();
    }

    return (
        <div className='form-input' style={styles.wrapper}>
            {label && (<label>{label}</label>)}

            <div className={`input-wrap searchInput ${error ? 'validation-error' : ''}`}>
                <input
                    type='text'
                    onChange={onChangeHandler}
                    style={styles.input}
                    ref={ref}
                    placeholder={placeHolder}
                />
                <span style={styles.icon} onClick={onClickHandler}>
                    <img src={Images.imageURL('icons/search-icon.png')} alt="" />
                </span>
                {error && (<div className='input-error-message'>{error}</div>)}
            </div>

        </div>
    )

})

SearchInput.displayName = 'SearchInput';

export default SearchInput;