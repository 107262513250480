import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    minHeight: '600px',
    height: '75%',
    bgcolor: '#ffffff',
    borderRadius: '32px',
    boxShadow: 24,
    p: 4,
    fontFamily: 'Poppins',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
};



const InfoModal = ({ isOpened, closeModal, children }) => {
    return (
        <>

            {isOpened && (
                <Modal
                    open={isOpened}
                    onClose={() => {
                        closeModal();
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <button
                            className="hidden-button info-modal-button"
                            onClick={closeModal}
                        >
                            <CloseIcon/>
                        </button>
                        {children}
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default InfoModal;