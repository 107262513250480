import React from 'react';
import Backend from 'Backend.js';
import { Spinner } from 'react-spinners-css';
import Content from 'utils/Content.js';
import Formatting from 'utils/Formatting.js';
import Payments from 'utils/Payments.js';

let thisComponent;

const configuration = require('configuration.json');

const businessLogic = configuration.business_logic;
const UISettings = configuration.ui_settings;
const conversionModes = configuration.withdrawal_modes;

const minTreatCoin = businessLogic.min_treat_coin_conversion;
const maxWithdrawalInactivityTime = UISettings.max_withdrawal_inactivity_time;

class ConversionCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.loadURL = Backend.backendURLs.conversionDataURL;
        this.state = {
            error: null,
            isLoaded: false,
            usdAmountLoaded: true,
            isRestricted: false,
            treatCoinAmount: null,
            treatCoinAmountStr: '',
            usdAmount: null,
            amountAcceptable: false,
            conversionMode: null,
            platform: null
        };

        thisComponent = this;
    }

    updateUSDAmount() {
        const { treatCoinAmount } = thisComponent.state;
        const { conversionRate } = thisComponent.props;

        if (treatCoinAmount !== null) {
            thisComponent.setState({
                usdAmountLoaded: false
            });

            let usdAmount = (treatCoinAmount * conversionRate).toFixed(2);
            thisComponent.setState({
                usdAmountLoaded: true,
                usdAmount: usdAmount
            });
        }

        else {
            thisComponent.setState({
                usdAmountLoaded: true,
                usdAmount: null
            });
        }
    }

    initiateConversion() {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(function () { window.location.reload(); }, maxWithdrawalInactivityTime * 1000);

        const { treatCoinAmount, platform } = thisComponent.state;
        const { conversionRate } = thisComponent.props;

        const usdAmount = treatCoinAmount * conversionRate;

        const mixpanelObject = {
            withdraw_type: this.state.platform,
            withdraw_treatcoin_value: this.state.treatCoinAmount,
            withdraw_dollar_value: this.state.usdAmount,
            withdraw_account: this.state.usdAmount,
            is_withdraw_all: this.state.conversionMode === conversionModes.maximum,
            is_withdraw_minimum: this.state.conversionMode === conversionModes.minimum,
            treatcoin_available: this.state.coins,
            current_month_balance: this.state.monthlyCoins,
        }

        Payments.confirmConversion({ treatCoinAmount, convertedTreatCoinsAmount: usdAmount.toFixed(2), platform: platform, mixpanelObject: mixpanelObject });
    }

    updateConversionMode(newMode) {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(function () { window.location.reload(); }, maxWithdrawalInactivityTime * 1000);

        const { coins } = this.props;
        const { conversionMode } = this.state;

        if (conversionMode === newMode) {
            thisComponent.setState({
                conversionMode: null,
                treatCoinAmount: null,
                treatCoinAmountStr: '',
                amountAcceptable: false,
                usdAmount: null,
            });
        }
        else if (newMode === conversionModes.minimum || newMode === conversionModes.maximum) {
            let newTreatCoinAmount = null;
            if (newMode === conversionModes.minimum) newTreatCoinAmount = Math.min(minTreatCoin, coins);
            else if (newMode === conversionModes.maximum) newTreatCoinAmount = coins;
            thisComponent.setState({
                conversionMode: newMode,
                treatCoinAmount: newTreatCoinAmount,
                treatCoinAmountStr: newTreatCoinAmount.toFixed(2),
                amountAcceptable: (newTreatCoinAmount > minTreatCoin)
            });
            setTimeout(thisComponent.updateUSDAmount, 100);
        }
    }

    updateCoinsAmount(buttonPressed) {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(function () { window.location.reload(); }, maxWithdrawalInactivityTime * 1000);

        const { treatCoinAmountStr, coins } = this.state;

        let newAmountStr = null;

        if (!isNaN(buttonPressed) && !(treatCoinAmountStr.includes(".") && treatCoinAmountStr.split(".")[1].length === 2)) {
            newAmountStr = treatCoinAmountStr + buttonPressed;
        }
        else if (buttonPressed === '.' && treatCoinAmountStr.length > 0 && !treatCoinAmountStr.includes(".")) {
            newAmountStr = treatCoinAmountStr + buttonPressed;
        }
        else if (buttonPressed === 'del' && treatCoinAmountStr.length > 0) {
            newAmountStr = treatCoinAmountStr.slice(0, -1);
        }

        if (newAmountStr !== null) {
            let newAmountAcceptable;
            let newAmount = (newAmountStr.length > 0) ? Number(newAmountStr) : null;

            if (!newAmount || newAmount < minTreatCoin || newAmount > coins) newAmountAcceptable = false;
            else newAmountAcceptable = true;

            thisComponent.setState({
                treatCoinAmount: newAmount,
                treatCoinAmountStr: newAmountStr,
                amountAcceptable: newAmountAcceptable,
                conversionMode: null,
            });

            setTimeout(thisComponent.updateUSDAmount, 100);
        }
    }

    componentDidMount() {
        this.inactivityTimeout = setTimeout(function () { window.location.reload(); }, maxWithdrawalInactivityTime * 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.inactivityTimeout);
    }

    render() {
        const { conversionMode, treatCoinAmount, treatCoinAmountStr, amountAcceptable, usdAmount, usdAmountLoaded } = this.state;
        const { coins, conversionRate } = this.props;
        const buttonEnabled = (amountAcceptable && treatCoinAmount<=coins);
        
        return (
            <>
                <div className="treat-coins-balance-indicator margin-top-0">
                    {Formatting.format(Content.getValue("treat_coins_convert_balance_indicator"), ["(coins)"], [Formatting.formatTreatCoinAmount(coins)])}
                </div>
                <div className="treat-coins-balance-indicator margin-top-30 margin-bottom-30">
                    {Formatting.format(Content.getValue("conversion_rate"), ["(coins)"], [Formatting.formatTreatCoinAmount(conversionRate)])}
                </div>
                <div className="conversion-input-container">
                    <div className="withdrawal-treat-coins-amount">
                        <span>{(treatCoinAmountStr === "") ? "---" : treatCoinAmountStr}
                            <span className="cursor-blink">|</span>
                        </span>
                        <span className="convert-treat-coins-usd-amount">
                            {(usdAmount || !usdAmountLoaded) ? Formatting.format(Content.getValue("calculated_convert_amount"), ["(usdAmount)"], (usdAmount) ? [usdAmount.toLocaleString()] : [null]) : (null)}
                        </span>
                        {(!usdAmountLoaded) ? (<Spinner color="#6BC6F5" className="withdrawal-calculator-spinner" />) : (null)}
                    </div>
                    <div className="withdrawal-treat-coins-label">{Content.getValue("usable_treat_coins")}</div>
                    <div className="withdrawal-amount-options-container">
                        <button className={(conversionMode === conversionModes.maximum) ? "withdrawal-option-button selected" : "withdrawal-option-button"} onClick={() => { thisComponent.updateConversionMode(conversionModes.maximum); }}>{Content.getValue("all_button_text")}</button>
                    </div>
                    <div className="convert-digits-container">
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('1'); }}>1</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('2'); }}>2</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('3'); }}>3</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('4'); }}>4</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('5'); }}>5</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('6'); }}>6</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('7'); }}>7</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('8'); }}>8</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('9'); }}>9</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('.'); }}>.</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('0'); }}>0</button>
                        <button className="withdraw-digit" onClick={() => { thisComponent.updateCoinsAmount('del'); }}><>&#8592;</></button>
                    </div>
                    <button disabled={!buttonEnabled} onClick={() => { thisComponent.initiateConversion(); }} className={(buttonEnabled) ? "complete-withdraw-button" : "complete-withdraw-button disabled"}>{Content.getValue("convert_button_text")}</button>
                </div>
            </>
        );
    }

}

export default ConversionCalculator;