import React, { useState, useRef } from 'react';
import Images from 'utils/Images.js';
import Select from 'components/inputs/Select';
import TextInput from 'components/inputs/TextInput';
import TelInput from 'components/inputs/TelInput';
import DataValidator from 'utils/Data_Validator';
import Content from 'utils/Content.js';

const PixPaymentForm = props => {

    const PixTypeDropdown = [
        { label: "CPF / CNPJ", value: "CPFCNPJ" },
        { label: Content.getValue("payment_method_pix_phone_number"), value: "phoneNumber" },
        { label: "E-mail", value: "email" },
        { label: Content.getValue("payment_method_pix_random_key"), value: "randomKey" },
    ];
    
    const { onSubmit, onGoBack } = props;

    const [pixType, setPixType] = useState(null);
    const [validationErrorMsg, setValidationErrorMsg] = useState({});

    const CPFCNPJRef = useRef(null);
    const phoneNumberRef = useRef(null);
    const emailRef = useRef(null);
    const randomKeyRef = useRef(null);

    const styles = {
        formField: {
            marginTop: 15
        }
    }

    const validateForm = () => {
        if(!pixType){
            return;
        }

        let res;

        const isValidCpfCnpj = DataValidator.isValidPaymentMethod_Pix_cpfCnpj(CPFCNPJRef.current.value)
        setValidationErrorMsg(prev => ({...prev, CPFCNPJ: (!isValidCpfCnpj ? Content.getValue("payment_method_pix_validation_error_CPFCNPJ") : "")}))

        let isValidphoneNumber, isValidEmail, isValidRandomKey;
        switch(pixType){
            case 'phoneNumber':
                res = {phoneNumber: phoneNumberRef.current.value};
                isValidphoneNumber = DataValidator.isValidPhoneNumber_brazil(phoneNumberRef.current.value);
                setValidationErrorMsg(prev => ({...prev, phoneNumber: (!isValidphoneNumber ? Content.getValue("payment_method_pix_validation_error_phone_number") : "")}));
                break;
            case 'email':
                res = {email: emailRef.current.value};
                isValidEmail = DataValidator.isValidEmail(emailRef.current.value);
                setValidationErrorMsg(prev => ({...prev, email: (!isValidEmail ? Content.getValue("payment_method_pix_validation_error_email") : "")}));
                break;
            case 'randomKey':
                res = {randomKey: randomKeyRef.current.value};
                isValidRandomKey = DataValidator.isValidPaymentMethod_Pix_randomKey(randomKeyRef.current.value);
                setValidationErrorMsg(prev => ({...prev, randomKey: (!isValidRandomKey ? Content.getValue("payment_method_pix_validation_error_random_key") : "")}));
                break;
            default:
                break;
        }
        
        if(isValidCpfCnpj && (!validationErrorMsg.hasOwnProperty(pixType) || validationErrorMsg[pixType]?.length == 0)){
            onSubmit({
                ...{pixType, CPFCNPJ: CPFCNPJRef.current.value}, 
                ...res
            })
        }
       
    };


    return (
        <div className='font-14'>
            <div onClick={() => onGoBack()} className='pix-form-back'><img src={Images.imageURL(`arrow_back_dark.png`)} height="18" width="18" /></div>
            <div><img src={Images.imageURL(`payment-methods/pix-icon-green.png`)} alt="Pix account" height="54" /></div>
            <div>{Content.getValue('payment_method_pix_form_title')}</div>
            <div style={styles.formField}>
                <Select
                    label={Content.getValue("payment_method_pix_form_pix_type")}
                    data={PixTypeDropdown}
                    onChange={setPixType}
                    placeHolder={Content.getValue("payment_method_pix_form_pix_type")}
                />
            </div>
            {pixType == "phoneNumber" && (
                <TelInput
                    label={Content.getValue("payment_method_pix_form_phone_number")}
                    ref={phoneNumberRef}
                    error={validationErrorMsg.phoneNumber}
                    defaultValue="+55"
                />
            )}
            {pixType == "email" && (
                <TextInput
                    label="E-mail"
                    ref={emailRef}
                    error={validationErrorMsg.email}
                />
            )}
            {pixType == "randomKey" && (
                <TextInput
                    label={Content.getValue("payment_method_pix_form_random_key")}
                    ref={randomKeyRef}
                    error={validationErrorMsg.randomKey}
                />
            )}
            {pixType && (
                <TextInput
                    label="CPF / CNPJ"
                    ref={CPFCNPJRef}
                    error={validationErrorMsg.CPFCNPJ}
                />
            )}

            <button disabled={!pixType} onClick={() => validateForm()} className="start-withdraw-button">{Content.getValue("continue_button_text")}</button>
        </div>
    );

}



export default PixPaymentForm;