import AccountAccessPageHeader from 'components/sections/Account_Access_Page_Header.js';
import Redirects from 'utils/Redirects.js';
import * as queryString from 'query-string';
import LoginFacebook from 'components/social/Login_Facebook.js';
import LoginGoogle from 'components/social/Login_Google.js';
import LoginDiscord from 'components/social/Login_Discord.js';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { SpinnerContext } from 'context/Global_Spinner.js';
import UserData from 'User_Data.js';
import Backend from 'Backend.js';
import Join from 'components/widgets/Join/Join.js';
const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const cookies = configuration.cookies;

const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const Login = () => {

    const { updateIsVisibleSpinner } = useContext(SpinnerContext);
    const [regEmail, setRegEmail] = useState('');
    const navigate = useNavigate();

    const urlParams = queryString.parse(window.location.search);
    const code = urlParams.code;
    let facebookRedirectRequestCode = null;
    let discordRedirectRequestCode = null;
    if (code) {
        if (code.length > 128) {
            facebookRedirectRequestCode = urlParams.code;
        } 
        else {
            discordRedirectRequestCode = urlParams.code;
        }
    }

    const locationFragment = window.location.href.split("#")[1];
    const fragmentParams = queryString.parse(locationFragment);
    const googleRedirectRequestCode = fragmentParams.id_token;

    useEffect(() => {
        if (!Redirects.thisPageIsGoingToRedirectToDifferentLanguage()) {
            if (facebookRedirectRequestCode) {
                const startRegistration = function () {
                    const gclient_id = UserData.getItem(userDataKeys.gclient_id);
                    const fbclid = UserData.getCookie(cookies.fbc) || UserData.getItem(userDataKeys.fbclid);
                    const fbp = UserData.getCookie(cookies.fbp);
                    LoginFacebook.handleFacebookLogin(facebookRedirectRequestCode, gclient_id, fbclid, fbp);
                };
                Backend.startAfterAnalyticsInitialized(startRegistration);

            }
            else if (googleRedirectRequestCode) {
                const startRegistration = function () {
                    const gclient_id = UserData.getItem(userDataKeys.gclient_id);
                    const fbclid = UserData.getCookie(cookies.fbc) || UserData.getItem(userDataKeys.fbclid);
                    const fbp = UserData.getCookie(cookies.fbp);
                    LoginGoogle.handleGoogleLogin(googleRedirectRequestCode, gclient_id, fbclid, fbp, navigate, updateIsVisibleSpinner);
                };
                Backend.startAfterAnalyticsInitialized(startRegistration);
            }

            else if (discordRedirectRequestCode) {
                LoginDiscord.handleDiscord(discordRedirectRequestCode, navigate, updateIsVisibleSpinner);
            }
        }

        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.login });
    }, []);

    return (
        <>
            <div id="main" className="light-mode">
                <div id="content" className="relative overflow-hidden flex-column">
                    <div id="content">
                        <AccountAccessPageHeader />
                        <section className="light-mode login-container page-container ">
                            <div className="light-mode">
                                <Join isLogin={true} variant={"expanded"} regEmail={regEmail} setRegEmail={setRegEmail} />
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;