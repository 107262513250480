import moengage from "@moengage/web-sdk";
import UserData from "User_Data.js";
const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const globalConf = require('global_conf.json');
const moeConfig = globalConf.moengageSdk;
const isProd = globalConf.website_connection.environment === 'Production';

// let moeCmpEvnets = {
//     MOE_ONSITE_MESSAGE_SHOWN:'MOE_ONSITE_MESSAGE_SHOWN',
//     MOE_ONSITE_MESSAGE_CLICKED:'MOE_ONSITE_MESSAGE_CLICKED',
//     MOE_ONSITE_MESSAGE_DISMISSED: 'MOE_ONSITE_MESSAGE_DISMISSED',
//     MOE_ONSITE_MESSAGE_AUTO_DISMISS: 'MOE_ONSITE_MESSAGE_AUTO_DISMISS'
// };

let initialized = false;
const Moengage = {
    init: function (uniqueUserID) {
    try {

        if (!initialized && uniqueUserID) {

            // {
            //     app_id: "AU1S5NDO1F0N4L4NZPH7YETZ_DEBUG",
            //     debug_logs: 0,
            //     swPath: "/serviceworker.js",
            //     cluster: "DC_2",
            //     disableOnsite: false,
            //     disableCookies: false,
            //     disable_web_push: false,
            //     enableSPA: false,
            //     cards: null
            //   }


            let moeParams = {
                app_id: moeConfig.appId,
                cluster: moeConfig.dc,
                disable_web_push: true
            };
            if (!isProd) {
                moeParams.debug_logs = 1;
            }

            moengage.initialize(moeParams);

            if (UserData.getItem(userDataKeys.moengageUserIdAdded) == null) {
                moengage.add_unique_user_id(uniqueUserID);
                UserData.setItem(userDataKeys.moengageUserIdAdded, 1);
            }

            // window.addEventListener('MOE_AUTOMATED_EVENTS', function (event) {
            //     if (event.detail.name === 'MOE_ONSITE_MESSAGE_SHOWN' && event.detail.data && event.detail.data.length) {
            //             // send mx events



            //         //moengage.track_page_view();
            //     }
            // });

            initialized = true;
        }
    } catch {
        initialized = false;
    }
}
}

export default Moengage;





