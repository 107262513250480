import React from "react";
import ApiFacade from 'ApiFacade.js';
import Images from "utils/Images.js";
import Joining from "utils/Joining.js";
import Offers from "utils/Offers.js";
import OfferEvents from "utils/Offer_Events.js";
import Sharing from "utils/Sharing.js";
import LinkCreator from "utils/Link_Creator.js";
import Formatting from "utils/Formatting.js";
import OfferCompletionDisclaimer from "components/offers/Offer_Completion_Disclaimer.js";
import UserData from 'User_Data.js';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Platform from 'utils/Platform.js';

import Content from 'utils/Content.js';
import { Mixpanel } from "utils/User_Events_Util.js";
import InfoModal from "components/modals/Info_Modal";

const configuration = require("configuration.json");
const offerDisplayTypes = configuration.offer_display_types;
const platformTypes = configuration.platform_types;

const iOSPlatformType = platformTypes.ios;

const eventTypes = configuration.event_types;
const userDataKeys = configuration.user_data_keys;
const mixpanelEvents = configuration.mixpanel_events;

let thisComponent;

class OfferDetailsPlaceholder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            referOfferDisplayed: false,
            checked: false,
            isInfoVisible: false,
            manualChange: false,
            trackingModal: false,
        }
        thisComponent = this;
    }

    componentWillUnmount() {
        OfferEvents.recordOfferPageVisit();
    }

    toggleReferralCoinsDialog() {
        let idToToggle = document.getElementById("offer-details-popup-id").value;
        let referralClassName = document.getElementById(idToToggle).className;
        if (referralClassName === "offer-referral-content") {
            document.getElementById(idToToggle).className = "offer-referral-content visible";
        }
        else {
            document.getElementById(idToToggle).className = "offer-referral-content";
        }  
    }

    handleChangeSwitch = ({ target: { checked } }) => {
        this.setState(prev => ({ ...prev, checked, manualChange: true }));
        const offerStartType = checked ? 2 : 1;

        OfferEvents.sendEvent({
            offerStartType,
            offerID: document.getElementById("offer-details-id").value,
            offerType: document.getElementById("offer-details-type").value,
            eventType: eventTypes.toggle_completion_mode,
            offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id),
        });
    }

    closePopupHandler = () => {
        this.setState({ isInfoVisible: false, referOfferDisplayed: false, checked: false, manualChange: false });
        Offers.hideOfferDetails();
    }

    handleToggleTracking = () => {
        thisComponent.setState(prev => ({ ...prev, trackingModal: !prev.trackingModal }));
    }

    render() {
        const { navigate } = this.props;
        const langForUrl =
            UserData.getItem(userDataKeys.language)?.slice(0, 2) !== 'en'
                ? '/' + UserData.getItem(userDataKeys.language)?.slice(0, 2)
                : '';
        let { offer } = thisComponent.props;
        let { checked, manualChange, referOfferDisplayed, isInfoVisible, trackingModal } = thisComponent.state;
        let multiSteps = (offer && JSON.parse(offer.multiSteps || 0)),
            partiallyCompleted = offer?.partiallyCompleted,
            offerStartType = 1,
            displayType = offer?.displayType,
            allowsUsableAndCompetition = offer?.allowsUsableAndCompetition,
            previousUserStartModeChoice = offer?.previousUserStartModeChoice,
            coins = offer?.coins,
            prefixCpx = offer?.uniqueID.substring(0, 3) === 'cpx',
            coinsReferral = offer?.coinsReferral, 
            coins2ndGenReferral = offer?.coins2ndGenReferral, 
            coins3rdGenReferral = offer?.coins3rdGenReferral,
            coinsCompetition = offer?.coinsCompetition,
            coinsReferralCompetition = offer?.coinsReferralCompetition,
            coins2ndGenReferralCompetition = offer?.coins2ndGenReferralCompetition,
            coins3rdGenReferralCompetition = offer?.coins3rdGenReferralCompetition,
            newCoins = offer?.newCoins,
            newCoinsReferral = offer?.newCoinsReferral,
            newCoins2ndGenReferral = offer?.newCoins2ndGenReferral,
            newCoins3rdGenReferral = offer?.newCoins3rdGenReferral,
            newCoinsCompetition = offer?.newCoinsCompetition,
            newCoinsReferralCompetition = offer?.newCoinsReferralCompetition,
            newCoins2ndGenReferralCompetition = offer?.newCoins2ndGenReferralCompetition,
            newCoins3rdGenReferralCompetition = offer?.newCoins3rdGenReferralCompetition,
            isPercentage = offer?.isPercentage,
            isSystem = offer?.isSystem,
            platform = Platform.getPlatformName(),
            givesBonusToReferrals = offer?.givesBonusToReferrals;

        if (manualChange) {
            if (offer && allowsUsableAndCompetition) {
                if (checked) {
                    offerStartType = 2;
                    if (displayType === offerDisplayTypes.hot) {
                        coins = newCoinsCompetition;
                        coinsReferral = newCoinsReferralCompetition;
                        coins2ndGenReferral = newCoins2ndGenReferralCompetition;
                        coins3rdGenReferral = newCoins3rdGenReferralCompetition;
                    }
                    else if (displayType === offerDisplayTypes.x2) {
                        coins = coinsCompetition * 2;
                        coinsReferral = coinsReferralCompetition * 2;
                        coins2ndGenReferral = coins2ndGenReferralCompetition * 2;
                        coins3rdGenReferral = coins3rdGenReferralCompetition * 2;
                    }
                    else {
                        coins = coinsCompetition;
                        coinsReferral = coinsReferralCompetition;
                        coins2ndGenReferral = coins2ndGenReferralCompetition;
                        coins3rdGenReferral = coins3rdGenReferralCompetition;
                    }
                }
                else {
                    offerStartType = 1;
                    if (displayType === offerDisplayTypes.hot) {
                        coins = newCoins;
                        coinsReferral = newCoinsReferral;
                        coins2ndGenReferral = newCoins2ndGenReferral;
                        coins3rdGenReferral = newCoins3rdGenReferral;
                    }
                    else if (displayType === offerDisplayTypes.x2) {
                        coins = coins * 2;
                        coinsReferral = coinsReferral * 2;
                        coins2ndGenReferral = coins2ndGenReferral * 2;
                        coins3rdGenReferral = coins3rdGenReferral * 2;
                    }
                }
                const referralsAmountKey = isPercentage ? "treatcoins_percentage_amount_label" : 'treatcoins_amount_label';

                const coinsElement = document.getElementById('offer-data-button');

                UserData.setItem(userDataKeys.offer_coins_amount, coins);

                coinsElement.innerHTML = Formatting.format(
                    Content.getValue(multiSteps ? "earn_up_to_amount_treat_coins" : "earn_amount_treat_coins"),
                    ['(coins)'],
                    [(prefixCpx ? '1 - ' : '') +Formatting.formatTreatCoinAmount(coins + (isPercentage ? '%' : ''))]
                );
                if (givesBonusToReferrals) {
                    const coinsReferralElement = document.getElementById("offer-details-coins-referral");
                    const coins2ndGenReferralElement = document.getElementById("offer-details-coins-2nd-gen-referral");
                    const coins3rdGenReferralElement = document.getElementById("offer-details-coins-3rd-gen-referral");

                    coinsReferralElement.innerHTML = Formatting.format(Content.getValue(referralsAmountKey), ["(amount)"], [Formatting.formatTreatCoinAmount(coinsReferral)]);
                    coins2ndGenReferralElement.innerHTML = Formatting.format(Content.getValue(referralsAmountKey), ["(amount)"], [Formatting.formatTreatCoinAmount(coins2ndGenReferral)]);
                    coins3rdGenReferralElement.innerHTML = Formatting.format(Content.getValue(referralsAmountKey), ["(amount)"], [Formatting.formatTreatCoinAmount(coins3rdGenReferral)]);
                }
            }
        }
        else {
            if (previousUserStartModeChoice === 2) {
                offerStartType = 2;
                checked = true;
            }
            if (!allowsUsableAndCompetition) {
                offerStartType = 2;
            }
        }

        return (
            <>
                <div className="offer-details-div-overlay hidden" id="offer-details-div-overlay"></div>
                <div className="offer-details-div hidden" id="offer-details-div">
                    <input type="hidden" id="offer-details-url"></input>
                    <input type="hidden" id="offer-details-id"></input>
                    <input type="hidden" id="offer-details-type"></input>
                    <input type="hidden" id="offer-details-unique-offer-id"></input>
                    <input type="hidden" id="offer-details-unique-user-id"></input>
                    <input type="hidden" id="offer-details-popup-id"></input>
                    <div className="offer-details-icon-container">
                        <img
                            className="offer-details-icon"
                            id="offer-data-image-details"
                            alt="Treat Coins"
                            width="60%"
                            height="auto"
                            src=''
                        />
                        <div className="offer-details-completed-users-container" id="offer-details-users-completed"> </div>
                        <img
                            className="close-offer-icon"
                            alt="Close offer details"
                            width="47"
                            height="47"
                            src={Images.imageURL("close-offer-details.png")}
                            onClick={thisComponent.closePopupHandler}
                        />
                    </div>
                    <div id="offer-details-data">
                        <div className="offer-details-container">
                            <div className="list-offer-container-image">
                                <img
                                    className="list-offer-difficulty-image"
                                    id="offer-data-difficulty-image"
                                    src=""
                                    alt="Offer difficulty"
                                    width="50"
                                    height="auto"
                                />
                                <span className="difficulty-popup-image-container">
                                    <div className="difficulty-popup-text" id="offer-data-difficulty-popup-text"></div>
                                    <img
                                        className="difficulty-popup-image"
                                        src={Images.imageURL("difficulty-popup.png")}
                                        alt="Difficulty popup"
                                        width="79"
                                        height="28"
                                    />
                                </span>
                                <img className="list-offer-image" id="offer-data-image" src="" alt="Offer" width="50" height="auto" />
                            </div>
                            <div className="list-offer-container-titles">
                                <div>
                                    <div className="list-offer-app-name" id="offer-data-app-name">
                                        appName
                                    </div>
                                    <div className="list-offer-description" id="offer-data-description">
                                        offerDescription
                                    </div>
                                </div>
                            </div>
                            <div className="list-offer-container-difficulty">
                                <div className="list-offer-container-difficulty-mark" id="offer-data-difficulty-mark"></div>
                                <div id="offer-data-difficulty-level" className="offer-difficulty-label" />
                            </div>
                        </div>
                        <div className="details-hash-tags" id="offer-data-hash-tags"></div>
                        <div className="offer-details-percentage-container">
                            <div className="offer-details-percentage-sign" id="offer-data-percentage-sign">
                                <img
                                    className="percentage-indicator-details"
                                    alt="Percentage offer"
                                    width="18"
                                    height="auto"
                                    src={Images.imageURL("percentage-indicator-gray.png")}
                                />
                            </div>
                            <div className="offer-details-percentage" id="offer-data-percentage"></div>
                        </div>

                        {
                            multiSteps ?
                            (
                                <div className="offer-steps-container" id="offer-steps-container">
                                    <div className="flex justify-between margin-bottom-10">
                                        <div className="text-center w-full-3">
                                            <span className="title-font capitalize">{ Content.getValue("step") }</span>
                                        </div>
                                        <div className="text-center w-full-3">
                                            <span className="title-font">{ Content.getValue("treat_coins_earned") }</span>
                                        </div>
                                        <div className="text-center w-full-3">
                                            <span className="title-font">{ Content.getValue("completed") }</span>
                                        </div>
                                    </div>
                                    {
                                        multiSteps.map(({ id, desc, coins, isCompleted, stepNumber }) => (
                                            <div className="flex w-full step-row" key={id}>
                                                <div className="flex flex-grow">
                                                    <div className="text-center w-full-3">
                                                        <div className="flex items-start gap-5">
                                                            <span className="title-font opacity">{ stepNumber + "." }</span>
                                                            <span className="title-font opacity text-left">{ desc }</span>
                                                        </div>
                                                    </div>
                                                    <div className="text-center w-full-3">
                                                        <span className="title-font opacity">{ coins }</span>
                                                    </div>
                                                    <div className="text-center w-full-3">
                                                        <div className="flex-column justify-center gap-10 items-center outlined">
                                                            { 
                                                                isCompleted ?
                                                                <img 
                                                                    src={Images.imageURL("green-checkbox.png")}
                                                                    alt={Content.getValue("green_checkbox")}
                                                                    width={18}
                                                                    height={18}
                                                                /> :
                                                                <span className="expanded-step-completion-indicator"></span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) :
                            null
                        }

                        <div className="relative">
                            <div className="offer-details-use-competition-container" id="offer-details-use-competition-container">
                                <h2 className={`offer-details-switch-text ${checked ? "opacity-5" : ""}`}>{Content.getValue("usable_competition")}</h2>
                                <FormGroup>
                                    <FormControlLabel
                                        disabled={Boolean(multiSteps && partiallyCompleted)}
                                        sx={{
                                            "&.Mui-disabled": {
                                                opacity: 0.3
                                            },
                                        }}
                                        control={
                                            <Switch 
                                                checked={checked}
                                                onChange={thisComponent.handleChangeSwitch}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                                sx={{ 
                                                    width: 46,
                                                    height: 28,
                                                    padding: 0,
                                                    border: '1px solid rgba(0,0,0,.1)',
                                                    borderRadius: 26 / 2,
                                                    "& .MuiSwitch-switchBase": {
                                                    padding: 0,
                                                    margin: "2px",
                                                    transitionDuration: "300ms",
                                                    "&.Mui-checked": {
                                                        transform: "translateX(18px)",
                                                        "& + .MuiSwitch-track": {
                                                        backgroundColor: "transparent",
                                                        opacity: 1,
                                                        },
                                                    },
                                                    },
                                                    "& .MuiSwitch-thumb": {
                                                    boxSizing: "border-box",
                                                    background:
                                                        "linear-gradient(85.33deg, #5352B5 -20.5%, #9F7DDB 96.23%), #858585;",
                                                    width: 22,
                                                    height: 22
                                                    },
                                                    "& .MuiSwitch-track": {
                                                    backgroundColor: "transparent",
                                                    opacity: 1,
                                                    }
                                                }} 
                                        />}
                                    />
                                </FormGroup>
                                <div className='flex'>
                                    <h2 className={`offer-details-switch-text margin-right-10 ${!checked ? "opacity-5" : ""}`}>{Content.getValue("competition_only")}</h2>
                                    <img 
                                        src={Images.imageURL('help.png')}
                                        alt='Offer info help'
                                        width={20}
                                        height={20}
                                        onClick={() => thisComponent.setState(prev => ({ ...prev, isInfoVisible: !prev.isInfoVisible}) )}
                                    /> 
                                </div>
                            </div>
                            <div className='offer-details-popup-info'>
                                {
                                    isInfoVisible ?
                                    (
                                        <div className='offer-details-popup-info-text'>
                                            { 
                                                !multiSteps ?
                                                    Content.getValue("offer_details_popup_info_text") :
                                                    !partiallyCompleted ?
                                                        Content.getValue("offer_details_popup_info_text_multi") :
                                                        Content.getValue("offer_details_popup_info_text_multi_partially") 
                                            }
                                        </div>
                                    ) : 
                                    ( null )
                                }
                            </div>
                        </div>

                        <div className="offer-details-daily-container">
                            <div className="offer-details-daily-sign" id="offer-data-daily-sign">
                                <span className="offer-details-daily-amount" id="offer-data-daily-amount"></span>
                                <img
                                    className="daily-indicator-details"
                                    alt="Daily offer"
                                    width="18"
                                    height="auto"
                                    src={Images.imageURL("daily-indicator-gray.png")}
                                />
                            </div>
                            <div className="offer-details-daily" id="offer-data-daily"></div>
                        </div>
                        
                        <div className="offer-details-unlimited-container">
                            <div className="offer-details-unlimited-sign" id="offer-data-unlimited-sign">
                                <img
                                    className="unlimited-indicator-details"
                                    alt="Unlimited offer"
                                    width="18"
                                    height="auto"
                                    src={Images.imageURL("unlimited-indicator-gray.png")}
                                />
                            </div>
                            <div className="offer-details-unlimited" id="offer-data-unlimited"></div>
                        </div>
                        <div className="offer-details-unlimited-container">
                            <div className="offer-details-unlimited-sign" id="offer-data-award-sign">
                                <img
                                    className="unlimited-indicator-details"
                                    alt="Award"
                                    width="18"
                                    height="auto"
                                    src={Images.imageURL("award-indicator-gray.png")}
                                />
                            </div>
                            <div className="offer-details-unlimited" id="offer-data-award"></div>
                        </div>

                        <div className='offer-details-action-container'>
                            <button
                                className="offer-details-button"
                                id="offer-data-button"
                                onClick={() => {
                                    if (!ApiFacade.isLoggedIn()) {
                                        Joining.showPopup(navigate);
                                    } else {
                                        let link = document.getElementById("offer-details-url").value;
                                        let redirectURLAfterEventRecorded = null;
                                        let clientRedirect = false;
                                        if (window.location.pathname === `${langForUrl}${link}`) {
                                            thisComponent.closePopupHandler();
                                        } else if (link === configuration.system_offer_urls.refer_friends) {
                                            document.getElementById('one-two-treat-button').click();
                                            thisComponent.closePopupHandler();
                                        } else if (link === configuration.system_offer_urls.connect_to_discord) {
                                            link = LinkCreator.createDiscordLink();
                                            clientRedirect = true;
                                            window.location.href = link;
                                        } else if (link === configuration.system_offer_urls.connect_to_bot) {
                                            link = LinkCreator.createBotLink();
                                            clientRedirect = true;
                                            window.location.href = link;
                                        } 
                                        else {
                                            if (!isSystem) {
                                                redirectURLAfterEventRecorded = link;
                                            }
                                            else {
                                                redirectURLAfterEventRecorded = `${langForUrl}${link}`;
                                            }
                                        }
                                        if (!clientRedirect) {
                                            OfferEvents.sendEvent({
                                                offerStartType,
                                                offerID: offer.ID,
                                                eventType: eventTypes.clickthrough,
                                                appName: offer.appName,
                                                category: offer.category,
                                                offerType: offer.offerType,
                                                UserPayout: Number(UserData.getItem(userDataKeys.offer_coins_amount)),
                                                isFree: offer.isFree,
                                                platformName: Platform.getPlatformName(),
                                                offerWallVisitID: UserData.getItem(userDataKeys.offer_wall_visit_id),
                                                uniqueID: offer.uniqueID,
                                                url: offer.url,
                                                providerID: offer.providerID,
                                                isSystem: offer.isSystem,
                                            },
                                            redirectURLAfterEventRecorded
                                            );
                                        }
                                    }
                                }}
                            ></button>
                            <div className="offer-referral-button-container">
                                <img
                                    className="offer-referral-button"
                                    id="refer-users-widget"
                                    alt="Refer users"
                                    onClick={thisComponent.toggleReferralCoinsDialog}
                                    width="52" height="52"
                                    src={Images.imageURL((referOfferDisplayed === true) ? 'refer-friends-purple.png' : 'refer-friends-white.png')}
                                />
                                <span className='offer-referral-content' id='offer-details-referral-container'>
                                        <>
                                            <p className="offer-referral-title">{Content.getValue("offers_refer_popup_title")}</p>
                                            <p className="offer-referral-text">{Content.getValue("offers_referral_coins_popup_text").first_generation} <span id='offer-details-coins-referral'></span></p>
                                            <p className="offer-referral-text">{Content.getValue("offers_referral_coins_popup_text").second_generation} <span id='offer-details-coins-2nd-gen-referral'></span></p>
                                        <p className="offer-referral-text">{Content.getValue("offers_referral_coins_popup_text").third_generation} <span id='offer-details-coins-3rd-gen-referral'></span></p>
                                    </> 
                                </span>
                                <span className='offer-referral-content' id='offer-details-system-container'>
                                    <div className="offer-referral-title" dangerouslySetInnerHTML={{ __html: Content.getValue("offers_refer_popup_title_treatcoin") }}></div>
                                </span>
                                <span className='offer-referral-content' id='offer-details-not-available-container'>
                                    <div className="offer-referral-title" dangerouslySetInnerHTML={{ __html: Content.getValue("offers_refer_popup_title_not_available") }}></div>
                                </span>
                            </div>
                        </div>
                        {platform === iOSPlatformType &&

                            <p className="offer-tracking-notification padding-x-20">
                                {Content.getValue('offer_tracking_desc_1')}
                                <button
                                    className="hidden-button padding-x-6"
                                    onClick={thisComponent.handleToggleTracking}
                                >
                                    {Content.getValue('offer_tracking_desc_link')}
                                </button>
                                {Content.getValue('offer_tracking_desc_2')}
                            </p>
                        }
                        <OfferCompletionDisclaimer />
                        <div className="what-to-do">{Content.getValue("offer_what_to_do_label")}</div>
                        <div className="offer-details-description" id="offer-data-detailed-description"></div>
                    </div>
                    <div id="offers-policy-cta">
                        <span onClick={() => {
                            Mixpanel.trackByArgs(mixpanelEvents.offers_policy_click, {})
                            return Offers.displayOffersPolicy()
                        }}>{Content.getValue("offers_policy_label")}</span>
                    </div>
                    <div className="share-offer-frame hidden" id="share-offer-frame">
                        <div className="share-teaser-container hidden" id="share-teaser-container" onClick={() => document.getElementById("share-teaser-container").className = "share-teaser-container hidden"} >
                            <img className="share-teaser" id="share-offer-teaser" alt="Refer users teaser" width="236" height="126" src={Images.imageURL('share-offer-hint-popup.png')} />
                            <div className="share-teaser-text">{Content.getValue("share_bubble_cta")}</div>
                        </div>
                        <img className="share-float-button" id="share-offer-widget" alt="shareOffer" onClick={async () => {
                            let offerID = document.getElementById('offer-details-unique-offer-id').value;
                            let userID = document.getElementById('offer-details-unique-user-id').value;
                            let offerShareLink = LinkCreator.createOfferShareLink(offerID, userID);
                            let appName = document.getElementById("offer-data-app-name").innerHTML;
                            let offerDescription = document.getElementById("offer-data-description").innerHTML;
                            await Sharing.shareLink(
                                {
                                    title: Content.getValue("check_offer_on_treatcoin"),
                                    text: appName + ": " + offerDescription,
                                    url: offerShareLink,
                                    fallbackTitle: Content.getValue("share_offer_fallback_title"),
                                    fallbackMessage: Content.getValue("share_offer_fallback_text"),
                                    detailsImageURL: offer?.detailsImageURL 
                                }
                            );
                    }
                    } width="52" height="52" src={Images.imageURL('share-offer.png')} />
                    </div>
                    {trackingModal &&
                        < InfoModal
                            isOpened={trackingModal}
                            closeModal={thisComponent.handleToggleTracking}
                        >
                            <div className="offer-tracking-modal-content">
                                <h3 className="offer-tracking-modal-heading">{Content.getValue('offer_tracking_modal_heading')}</h3>
                                <ol className="offer-tracking-modal-list">
                                    <li>{Content.getValue('offer_tracking_modal_item_1')} </li>
                                    <li>{Content.getValue('offer_tracking_modal_item_2')} </li>
                                </ol>
                                <div className="offer-tracking-modal-images">
                                    <img src={Images.imageURL('tracking1.png')} height="160" width="auto" alt="" className="offer-tracking-modal-image" />
                                    <img src={Images.imageURL('tracking2.png')} height="160" width="auto" alt="" className="offer-tracking-modal-image" />
                                </div>
                            </div>
                        </InfoModal>
                    }
                </div>
            </>
        );
    }
}

export default OfferDetailsPlaceholder;