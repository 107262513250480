import UserData from 'User_Data.js';
import Backend from 'Backend.js';
import queryString from 'query-string';
import SanitizeQueryParams from './utils/Sanitize_Query_Params';
import {  redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import Redirects from 'utils/Redirects.js';

const configuration = require('configuration.json');

const cookies = configuration.cookies;
const loginTypes = configuration.login_types;

const MAXRETRY = 2;
const userDataKeys = configuration.user_data_keys;
const paymentMethodTypes = configuration.payment_method_types;

class ApiFacade {

    static isLoggedIn(){
        if (UserData.getItem(userDataKeys.jwt_token)){
            return true;
        }
        return false;
    }

    static async top100Users(language){
        let url = Backend.backendURLs.top100URL;
        if (language){
            url+=`?language=${encodeURIComponent(language)}`;
        }
        //let r = ApiHelpers.callApiWithRetry(url, Backend.apiLevels.ANONYMOUS);
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS/*, alwaysSendGuest: true*/});
        return r;
    }

    static async topScoreHistory(month, year, language){
        let url = `${Backend.backendURLs.scoreHistoryURL}?month=${encodeURIComponent(month)}&year=${encodeURIComponent(year)}`;
        if (language){
            url+=`&language=${encodeURIComponent(language)}`;
        }
        //let r = ApiHelpers.callApiWithRetry(url, Backend.apiLevels.MEMBER);
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async conversionRate(){
        //let r = ApiHelpers.callApiWithRetry(Backend.backendURLs.conversionDataURL, Backend.apiLevels.MEMBER);
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.conversionDataURL});
        return r;
    }

    static async connectedToBot(){
        //let r = ApiHelpers.callApiWithRetry(Backend.backendURLs.connectedToBotURL, Backend.apiLevels.MEMBER);
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.connectedToBotURL});
        return r;
    }

    static async lifetimeTeamStats(){
        //let r = ApiHelpers.callApiWithRetry(Backend.backendURLs.lifetimeTeamStats, Backend.apiLevels.MEMBER);
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.lifetimeTeamStats});
        return r;
    }

    static async monthlyTeamStats(month, year){
        let url = `${Backend.backendURLs.monthlyTeamStatsUrl}?month=${encodeURIComponent(month)}&year=${encodeURIComponent(year)}`;
        //let r = ApiHelpers.callApiWithRetry(url, Backend.apiLevels.MEMBER);
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async myEarningsHistory(month, year, minutesOffset, platform, excludeList = 0){
        let url = `${Backend.backendURLs.myEarningsHistoryURL}?month=${encodeURIComponent(month)}&year=${encodeURIComponent(year)}&minutesOffset=${encodeURIComponent(minutesOffset)}&platformName=${encodeURIComponent(platform)}&excludeList=${encodeURIComponent(excludeList)}`;
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async myPayoutHistory(minutesOffset){
        let url = `${Backend.backendURLs.myPayoutHistoryURL}?minutesOffset=${encodeURIComponent(minutesOffset)}`;
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async myCoins(){
        let url = `${Backend.backendURLs.myCoinsURL}`;
        //let r = ApiHelpers.callApiWithRetry(url, Backend.apiLevels.MEMBER);
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async withdrawCoins(options){

        if(options.mixpanelObject){
            delete options.mixpanelObject;
        }
        const body = JSON.stringify(options);

        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.withdrawCoinsURL, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async convertCoins(treatCoinAmount){
        const postBody = {
            treatCoinAmount: treatCoinAmount
        };
        const body = JSON.stringify(postBody);

        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.convertCoinsURL, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }    

    static async paymentMethodsList(){
        let url = `${Backend.backendURLs.paymentMethodsListURL}`;
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async removePaymentMethod(paymentMethod){
        const postBody = {};
        const body = JSON.stringify(postBody);

        let url = null;
        if (paymentMethod === paymentMethodTypes.plum) {
            url = Backend.backendURLs.removePlumURL;
        } else{
            return null;
        }
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async requestPlum(email){
        const postBody = {
            email: email
        };
        const body = JSON.stringify(postBody);

        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.requestPlumURL, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async addPlum(email, code){
        const postBody = {
            email: email,
            code: code
        };
        const body = JSON.stringify(postBody);

        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.addPlumURL, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async userStats(){
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.userStatsUrl});
        return r;
    }

    static async fetchResourceContent(language){
        let url = Backend.backendURLs.fetchContentURL;
        if (language){
            url+=`?language=${encodeURIComponent(language)}`;
        }

        //let r = ApiHelpers.callApiWithRetry(url, Backend.apiLevels.ANONYMOUS, true);
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS, alwaysSendGuest: true});
        return r;
    }

    static async currentRewards(language){
        let url = `${Backend.backendURLs.currentRewardsURL}?language=${encodeURIComponent(language)}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS, alwaysSendGuest: true});
        return r;
    }

    static async updateLanguage(language){
        let url = `${Backend.backendURLs.updateLanguageURL}`;
        const postBody = {
            language: language
        };
        const body = JSON.stringify(postBody);
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async faq(language){        
        let url = `${Backend.backendURLs.faqURL}`;
        if (language){
            url+=`?language=${encodeURIComponent(language)}`;
        }
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS, alwaysSendGuest: true});
        return r;
    }

    static async deleteAccount(){
        const postBody = {};
        const body = JSON.stringify(postBody);

        let url = `${Backend.backendURLs.deleteAccountURL}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async emailSubscriptionSettings(){
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.emailSubscriptionSettingsURL});
        return r;
    }

    static async updateEmailSubscriptionSettings(receiveNotificationsEmail){
        const postBody = {
            receiveNotificationsEmail: receiveNotificationsEmail
        }
        const body = JSON.stringify(postBody);

        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.updateEmailSubscriptionSettingsURL, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async subscriptionSettings(){
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.subscriptionSettingsURL});
        return r;
    }

    static async updateSubscriptionSettings(receiveNotificationsTelegram, 
                                        receiveOfferCompletionTelegram,
                                        receiveReferralOfferCompletionTelegram,
                                        receiveReferralRegistrationTelegram,
                                        receiveNotificationsEmail){
        const postBody = {};
        if (receiveNotificationsTelegram!==undefined && receiveNotificationsTelegram!=null){
            postBody.receiveNotificationsTelegram=receiveNotificationsTelegram;
        }
        if (receiveOfferCompletionTelegram!==undefined && receiveOfferCompletionTelegram!=null){
            postBody.receiveOfferCompletionTelegram=receiveOfferCompletionTelegram;
        }
        if (receiveReferralOfferCompletionTelegram!==undefined && receiveReferralOfferCompletionTelegram!=null){
            postBody.receiveReferralOfferCompletionTelegram=receiveReferralOfferCompletionTelegram;
        }
        if (receiveReferralRegistrationTelegram!==undefined && receiveReferralRegistrationTelegram!=null){
            postBody.receiveReferralRegistrationTelegram=receiveReferralRegistrationTelegram;
        }
        if (receiveNotificationsEmail!==undefined && receiveNotificationsEmail!=null){
            postBody.receiveNotificationsEmail=receiveNotificationsEmail;
        }

        const body = JSON.stringify(postBody);
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.updateSubscriptionSettingsURL, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }


    static async hotOffers(platform){
        let url = `${Backend.backendURLs.hotOfferUrl}?platformName=${encodeURIComponent(platform)}`;
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async offers(language, country, platform, isCashBack, isSpecial){
        const cashBackParam = ApiHelpers.getIntParam(isCashBack);
        const specialParam = ApiHelpers.getIntParam(isSpecial);
        const url = `${Backend.backendURLs.offersURL}?language=${encodeURIComponent(language)}&country=${encodeURIComponent(country)}&platformName=${encodeURIComponent(platform)}&isCashback=${cashBackParam}&specialOffers=${specialParam}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS});
        return r;
    }

    static async additionalOffers(platform, country, language) {
        let url = `${Backend.backendURLs.additionalOffersURL}?country=${country}&platformName=${platform}`;
        if (!language) {
            url = url + `&language=${language} `;
        }
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS});
        return r;
    }

    static async completedOffers(platform){
        let url = `${Backend.backendURLs.completedOffersURL}?platformName=${encodeURIComponent(platform)}`;
        let r = ApiHelpers.callApiWithRetry({url: url});
        return r;
    }

    static async recommendedOffer(recommendedOffer, offerRecommender, platform){
        let url = `${Backend.backendURLs.recommendedOfferURL}?recommendedOffer=${encodeURIComponent(recommendedOffer)}&platform=${encodeURIComponent(platform)}`;
        if (offerRecommender){
            url += `&offerRecommender=${encodeURIComponent(offerRecommender)}`;
        }
        let r = ApiHelpers.callApiWithRetry({url: url, alwaysSendGuest: true,  apiLevel: Backend.apiLevels.ANONYMOUS});
        return r;
    }

    static async top12Offers(language, country, platform){
        const url = `${Backend.backendURLs.top12OffersURL}?language=${encodeURIComponent(language)}&country=${encodeURIComponent(country)}&platformName=${encodeURIComponent(platform)}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS });
        return r;
    }

    static async recordOfferEvent(params){
        const body = JSON.stringify(params);
        let r = ApiHelpers.callApiWithRetry({url: Backend.backendURLs.recordOfferEventURL, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async recordOfferEventNew(queryParams) {
        let r = ApiHelpers.callApiWithRetry({
            url: Backend.backendURLs.offerEventsURL + '?' + queryParams,
            apiLevel: Backend.apiLevels.MEMBER,
            method: Backend.backendMethods.get
        });
        return r;
    }

    static async offerSharingStatus(offerID){
        const url = `${Backend.backendURLs.offerSharingStatusURL}?offerID=${encodeURIComponent(offerID)}`;
        let r = ApiHelpers.callApiWithRetry({url: url });
        return r;
    }

    static async markShareTeaserDisplayed(){
        const postBody = {};
        const body = JSON.stringify(postBody);

        let url = `${Backend.backendURLs.markShareTeaserDisplayedURL}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async markCoinsDisclaimerDisplayed(){
        const postBody = {};
        const body = JSON.stringify(postBody);

        let url = `${Backend.backendURLs.markCoinsDisclaimerDisplayedURL}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async userUpdateLiveNewsPref(receiveLiveNews){
        const receiveLiveNewsParam = ApiHelpers.getIntParam(receiveLiveNews);
        const postBody = {
            receiveLiveNews: receiveLiveNewsParam
        };
        const body = JSON.stringify(postBody);

        let url = Backend.backendURLs.userUpdateLiveNewsPrefURL;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async recordThirdPartyConsent(){
        const postBody = {};
        const body = JSON.stringify(postBody);

        let url = `${Backend.backendURLs.recordThirdPartyConsentURL}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.ANONYMOUS, alwaysSendGuest: true, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async saveDeliveredNotifications(notifications){
        const postBody = {
            notifications: notifications
        };
        const body = JSON.stringify(postBody);

        let url = Backend.backendURLs.saveDeliveredNotificationsUrl;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.MEMBER, body: body, method: Backend.backendMethods.post});
        return r;
    }

    static async incomingNotifications(enforceLiveNews){
        const url = `${Backend.backendURLs.incomingNotificationsUrl}?enforceLiveNews=${encodeURIComponent(enforceLiveNews)}`;
        let r = ApiHelpers.callApiWithRetry({url: url });
        return r;
    }

    static async receiveTopOffers(language, country, platform) {
        let url = `${Backend.backendURLs.top12OffersURL}?language=${encodeURIComponent(language)}&country=${encodeURIComponent(country)}&platformName=${encodeURIComponent(platform)}`;
        let r = ApiHelpers.callApiWithRetry({url: url,apiLevel: Backend.apiLevels.ANONYMOUS });
        return r;
    }

    static async receiveClickedOffers({ amount = 50, page = 1, order = 1, status }) {
        const queryParams = queryString.stringify(SanitizeQueryParams({ amount, page, order, status }));
        let url = `${Backend.backendURLs.offersClickedURL}?${queryParams}`;
        let r = ApiHelpers.callApiWithRetry({ url: url, method: Backend.backendMethods.get });
        return r;
    }

    static async getReportTicketProperties() {
        let r = ApiHelpers.callApiWithRetry({ url: Backend.backendURLs.getTicketReportPropertiesURL, method: Backend.backendMethods.get });
        return r;
    }

    static async getReportTicketLinks(body) {
        let r = ApiHelpers.callApiWithRetry({
            url: Backend.backendURLs.getTicketReportImgLinksURL,
            method: Backend.backendMethods.post,
            body: JSON.stringify(body)
        });
        return r;
    }

    static async getReportToken() {
        let r = ApiHelpers.callApiWithRetry({ url: Backend.backendURLs.getTicketReportTokenURL, method: Backend.backendMethods.get });
        return r;
    }

    static async submitTicketReport(body) {
        let r = ApiHelpers.callApiWithRetry({ 
            url: Backend.backendURLs.submitTicketReportURL, 
            method: Backend.backendMethods.post,
            body: JSON.stringify(body) });
        return r;
    }

    static async getLinkedOffers({ platformName, uniqueOfferID }) {
        let url = `${Backend.backendURLs.getLinkedOffersURL}?platformName=${encodeURIComponent(platformName)}&uniqueOfferID=${encodeURIComponent(uniqueOfferID)}`;
        let r = ApiHelpers.callApiWithRetry({
            url,
            method: Backend.backendMethods.get
        });
        return r;
    }
    static async getStockProperties({ uniqueOfferID }) {
        let url = `${Backend.backendURLs.getStockPropertiesURL}?uniqueOfferID=${uniqueOfferID}`;
        let r = ApiHelpers.callApiWithRetry({
            apiLevel: Backend.apiLevels.MEMBER,
            url,
            method: Backend.backendMethods.get
        });
        return r;
    }
    static async getUserReferrals({ page = 1 }) {
        let url = `${Backend.backendURLs.getUserReferralsURL}?page=${page}`;
        let r = ApiHelpers.callApiWithRetry({
            apiLevel: Backend.apiLevels.MEMBER,
            url,
            method: Backend.backendMethods.get
        });
        return r;
    }
    static async getOfferReviews({ reviewTopicID }) {
        let url = `${Backend.backendURLs.userReviewURL}?reviewTopicID=${reviewTopicID}&offerPopup=1`;
        let r = ApiHelpers.callApiWithRetry({
            apiLevel: Backend.apiLevels.MEMBER,
            url,
            method: Backend.backendMethods.get
        });
        return r;
    }

    static async refreshToken(){
        return ApiHelpers.prepareForRetry(Backend.apiLevels.MEMBER);
    }

    static async registerWithEmail(body) {
        UserData.setItem(userDataKeys.email_register, body.email);
        const url = Backend.backendURLs.startRegistrationURL;
        let r = ApiHelpers.callApiWithRetry({
            url,
            apiLevel: Backend.apiLevels.ANONYMOUS,
            method: Backend.backendMethods.post,
            body: JSON.stringify(body)
        });
        return r;
    }

    static async loginWithEmail(postBody) {
        UserData.setItem(userDataKeys.email_register, postBody.email);
        const url = Backend.backendURLs.signInURL;
        let r = ApiHelpers.callApiWithRetry({
            url,
            alwaysSendGuest: true,
            apiLevel: Backend.apiLevels.ANONYMOUS,
            method: Backend.backendMethods.post,
            body: JSON.stringify(postBody)
        });
        return r;
    }

    static async userAcceptTerms(body) {
        let r = ApiHelpers.callApiWithRetry({
            url: Backend.backendURLs.userAcceptTermsURL,
            method: Backend.backendMethods.post,
            body: JSON.stringify(body)
        });
        return r;
    }

    static processVerification({ registerToken, navigate, updateIsVisibleSpinner }) {
        const startRegistration = function () {
            const gclient_id = UserData.getItem(userDataKeys.gclient_id);
            const fbclid = UserData.getCookie(cookies.fbc) || UserData.getItem(userDataKeys.fbclid);
            const fbp = UserData.getCookie(cookies.fbp);
            fetch(Backend.backendURLs.registerURL, {
                credentials: "include",
                method: Backend.backendMethods.post,
                headers: Backend.generateHeader(true),
                body: JSON.stringify({
                    registerToken,
                    url: Backend.backendURLs.registerURL,
                    gclient_id: gclient_id,
                    fbclid: fbclid,
                    fbp: fbp
                })
            }).then(async res => {
                let result = await res.json();
                let success = result.registerSuccess;
                updateIsVisibleSpinner({ isVisible: false });
                if (success) {

                    await Backend.setUserSessionData(result, loginTypes.email);
                    UserData.removeItem(userDataKeys.referrer_code);
                    Redirects.makeRedirectAfterLogin(false, navigate);

                } else {
                    if (ApiFacade.isLoggedIn()) {
                        redirectWithLangFunc(configuration.paths.home, navigate);
                    }
                    else {
                        redirectWithLangFunc(configuration.paths.login, navigate);
                    }

                }
            })
        };

        Backend.startAfterAnalyticsInitialized(startRegistration);

    }

    static async getUserReviews(params){
        //const {mine, all, appName, page} = params
        const queryString = new URLSearchParams(params).toString();
        const url = `${Backend.backendURLs.userReviewURL}?${queryString}`;
        let r = ApiHelpers.callApiWithRetry({url: url, apiLevel: Backend.apiLevels.MEMBER });
        return r;
    }

    static async setUserReview(params){
        
        let r = ApiHelpers.callApiWithRetry({
            url: Backend.backendURLs.userReviewURL, 
            apiLevel: Backend.apiLevels.MEMBER, 
            body: JSON.stringify(params), 
            method: Backend.backendMethods.put
        });
        return r;
    }

}

class ApiHelpers{
    static getIntParam(param){
        let intParam = 0;
        if (param === true){
            intParam = 1;
        } else if (param === false){
            intParam = 0;
        } else if (param !== undefined){
            intParam = param;
        }
        return intParam;
    }

    static shouldRetry(response){
        const shouldRetry = !response.ok && (response.unauthorized);
        return shouldRetry;
    }

    static getOwnLSKey(){
        return userDataKeys.refresh_call_time+"_api";
    }

    static lastPromise = null;
    static lastReloadTime = null;

    static _secondsLeft() {
        const now = Date.now();
        //const refreshKey = ApiHelpers.getOwnLSKey();
        let lastTime = ApiHelpers.lastReloadTime; //UserData.getItem(refreshKey);
        let seconds = 30;
        if (lastTime){
            lastTime = parseInt(lastTime);
            seconds = (now - lastTime) / 1000;
        }
        return seconds;
    }

    static setLastTokenRefreshTime(){
        const now = Date.now();
        //const refreshKey = ApiHelpers.getOwnLSKey();
        //UserData.setItem(refreshKey, now);
        ApiHelpers.lastReloadTime = now;
        return now;
    }

    static async refreshTheTokenAndUpdateTime() {
        let temp = await Backend.refreshToken(null);
        const now = ApiHelpers.setLastTokenRefreshTime();
        UserData.setItem(userDataKeys.refresh_call_time, now);
        return temp;
    }

    static async prepareForRetry(apiLevel){
        const user_token = UserData.getItem(userDataKeys.jwt_token);
        if ((!user_token) && (Backend.apiLevels.MEMBER <= apiLevel)){
            return false;
        }
        
        let seconds = ApiHelpers._secondsLeft();
        if (seconds >= 30) {
            if (ApiHelpers.lastPromise !== null) {
                const res = await ApiHelpers.lastPromise;
                seconds = ApiHelpers._secondsLeft();
                if (seconds < 30){
                    return res;
                }
            }
            ApiHelpers.lastPromise = ApiHelpers.refreshTheTokenAndUpdateTime();
            const refreshRes = await ApiHelpers.lastPromise;
            return refreshRes;
        } else {
            if (ApiHelpers.lastPromise !== null){
                const refreshRes = await ApiHelpers.lastPromise;
                return refreshRes;
            } else {
                return false;
            }
        }
    }

    static isBadHeader(headers, apiLevel){
        if (Backend.apiLevels.MEMBER === apiLevel){
            if (!headers.get(Backend.serverHeaders.authorization)){
                return true;
            }
        } else if (Backend.apiLevels.ANONYMOUS === apiLevel){
            if (!headers.get(Backend.serverHeaders.authorization) && !headers.get(Backend.serverHeaders.guest_auth)){
                return true;
            }
        }
        return false;
    }

    static async callApiWithRetry( { url="", 
                            apiLevel = Backend.apiLevels.MEMBER, 
                            alwaysSendGuest = false, 
                            maxretry = MAXRETRY,
                            method = Backend.backendMethods.get,
                            body}){
        let retries = maxretry;
        let response = { ok: false};
        for (let i=0; i<retries; i++){
            let lastAttempt = i+1>=retries;
            response = await ApiHelpers.callApi({url: url, 
                                                apiLevel: apiLevel, 
                                                alwaysSendGuest: alwaysSendGuest, 
                                                last: lastAttempt,
                                                method: method, 
                                                body: body});
            if (!lastAttempt && ApiHelpers.shouldRetry(response)){
                let canRetry = await ApiHelpers.prepareForRetry(apiLevel);
                if (canRetry) {
                    continue; // to next itereation/retry
                }
            }
            break;
        }
        return response;
    }

    static async callApi({ url="", 
        apiLevel = Backend.apiLevels.MEMBER, 
        alwaysSendGuest = false, 
        last = false,
        method = Backend.backendMethods.get,
        body}
    /*url, apiLevel, alwaysSendGuest, last*/){
        const response = { ok: false};
        if (last === undefined){
            last = true;
        }
        try {
            const requestHeaders = Backend.generateHeaderApi(apiLevel, alwaysSendGuest);
            if (ApiHelpers.isBadHeader(requestHeaders, apiLevel)){
                response.error = "no authentication headers";
                response.status = 0;
                return response;
            }

            var options = {
                method: method,
                headers: requestHeaders,
                redirect: "manual"
            };
            if (body){
                options.body = body;
            }
            const fetchResponse = await fetch(url, options);

            response.ok = fetchResponse.ok || fetchResponse.redirected;
            response.redirectUrl = fetchResponse.url;
            response.status = fetchResponse.status;
            response.statusText = fetchResponse.statusText;
            response.unauthorized = (Backend.backendResponseCodes.unauthorizedCode === response.status);
            response.forbidden = (Backend.backendResponseCodes.forbiddenCode === response.status);
            response.badRequest = (Backend.backendResponseCodes.badRequestCode === response.status);
            response.baseCall = fetchResponse; // for debug only!

            if (response.ok || response.unauthorized){
                response.response = await fetchResponse.json();
                if (response.ok || last){
                    // This logic will not be triggered if there's a retry on the way
                    if (response.response.tokenValid){
                        //Backend.clearTokenIfRequired(response.response.tokenValid, false);
                        // We'll clear token only on refreshToken case
                    }
                }
            }
            if (!response.ok){
                response.error = (response.statusText || !response.ok);
            }
        }
        catch (e){
            response.ok = false;
            response.error = e;
        }
        return response;
    }
}

export default ApiFacade;