/* eslint-disable no-unused-vars */
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, CircularProgress } from '@mui/material';
import ApiFacade from 'ApiFacade';
import NewsTrack from 'components/live_news/News_Track.js';
import TermsPopup from 'components/modals/Terms_Popup.js';
import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Footer from 'components/sections/Footer.js';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import MyTeamInfoModal from '../components/modals/my_team_info_modal/My_Team_Info_Modal.js';
import Formatting from '../utils/Formatting';
import Images from '../utils/Images';
import Sharing from '../utils/Sharing';
import Alerts from '../utils/Alerts.js';


const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const MyTeamReferrals = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const stats = searchParams.get('stats');

    const [openTermsPopup, setOpenTermsPopup] = useState(false);
    const [openMyTeamInfoModal, setOpenMyTeamInfoModal] = useState(false);
    const [myReferrals, setMyReferrals] = useState(null);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [state, setState] = useState({
        error: null,
        isLoaded: false,
        isRestricted: false,
        userStats: {},
        coinsHistory: [],
        webReferralLink: null,
    });

    const getMyReferrals = () => {
        const req = ApiFacade.getUserReferrals({ page });
        setLoading(true);
        req.then(({ response }) => {
            setMyReferrals(response);
            setLoading(false);
        }).catch(error => setState({ isLoaded: true, error }));
    };

    useEffect(() => {
        getMyReferrals();
    }, [page]);


    useEffect(() => {
        if (ApiFacade.isLoggedIn()) {
            let getLifetimeStats = ApiFacade.lifetimeTeamStats();
            getLifetimeStats.then(function (res) {
                if (res.unauthorized) {
                    setState({
                        isLoaded: true,
                        isRestricted: true
                    });
                }
                else if (!res.ok) {
                    setState({
                        isLoaded: true,
                        error: res.error
                    });
                } else {
                    let result = res.response;
                    setState({
                        isLoaded: true,
                        userStats: result.userStats,
                        webReferralLink: UserData.getItem(userDataKeys.website_referral_link),
                        coinsHistory: result.coinsHistory,
                    });
                    if (stats === '1') {
                        setOpenMyTeamInfoModal(prev => ({ type: 'stats', open: true }));
                    }
                }
            },
                (error) => {
                    setState({
                        isLoaded: true,
                        error
                    });
                }
            )
                .catch(error => setState({ isLoaded: true, error }));
        }
    }, []);

    useEffect(() => {
        const terms = Number(UserData.getItem(userDataKeys.terms));
        if (!terms) {
            setTimeout(() => {
                setOpenTermsPopup(true);
            }, 100);
        }
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.my_team_referrals });
    }, []);

    const copyLink = async () => {
        Mixpanel.trackByArgs(mixpanelEvents.referral_copylink_click, {});
        const terms = Number(UserData.getItem(userDataKeys.terms));
        if (!terms) {
            setOpenTermsPopup(true);
        } else {
            await Sharing.copyToClipboard(state.webReferralLink, Content.getValue("copy_to_clipboard_fallback_title"), Content.getValue("copy_to_clipboard_fallback_text"));
            Alerts.showSuccessAlert(Content.getValue('copied_to_clipboard'));
        }
    };

    const shareLink = () => {
        Mixpanel.trackByArgs(mixpanelEvents.referral_share_click, {});
        const terms = Number(UserData.getItem(userDataKeys.terms));
        if (!terms) {
            setOpenTermsPopup(true);
        } else {
            Sharing.shareLink(
                {
                    title: Content.getValue("join_treatcoin"),
                    text: Content.getValue("share_link_cta_message"),
                    url: state.webReferralLink,
                    fallbackTitle: Content.getValue("share_link_fallback_title"),
                    fallbackMessage: Content.getValue("share_link_fallback_message"),
                    detailsImageURL: null
                });
        }

    };

    return (
        <>
            <div id="main" className='relative'>
                <div id="content">
                    <ContentPageHeader />
                    <NewsTrack />
                    <section className="content-page-section build-my-team">
                        <div>
                            <h1 className="page-title build-my-team">{Content.getValue('build_my_team')}</h1>
                        </div>
                        <div className="build-my-team-refer-friends">
                            <p className="build-my-team-refer-friends-desc">{Content.getValue('refer_your_friends')}</p>
                            <p className="build-my-team-refer-friends-desc-pink">{Content.getValue('earn_treatcoins')}</p>
                            <div className='flex gap-20 margin-bottom-16-25'>
                                <div className="build-my-team-refer-friends-link-container w-full hover-scale-transitioned">
                                    <p className="build-my-team-refer-friends-link-value">
                                        {`${(state?.webReferralLink) && (state?.webReferralLink !== "null")  ? state?.webReferralLink : Content.getValue('referral_link')}`}
                                    </p>
                                    <button
                                        onClick={copyLink}
                                        className="hidden-button build-my-team-refer-copy-btn">
                                        <img src={Images.imageURL('referrals-page/copy.png')} alt="" />
                                    </button>
                                </div>
                                <div className="build-my-team-refer-friends-link-container  padding-6-16 hover-scale-transitioned">
                                    <button
                                        onClick={shareLink}
                                        className="hidden-button build-my-team-refer-share-btn">
                                        <img src={Images.imageURL('referrals-page/share-link.png')} alt="" />
                                    </button>
                                </div>
                            </div>
                            <img className='build-my-team-refer-friends-bots' src={Images.imageURL('referrals-page/two-bots.png')} alt="" />
                            <button
                                onClick={() => setOpenMyTeamInfoModal(prev => ({ type: 'how', open: true }))}
                                className="hover-scale-transitioned hidden-button build-my-team-refer-friends-how build-my-team-underline-14">{Content.getValue('how_it_works')}</button>
                        </div>
                        <div className="pink-bordered margin-top_15 margin-bottom-16-25">
                            <h5 className="build-my-team-earnings-name">{Content.getValue('my_team_earnings')}</h5>
                            <div className="flex-row-centered margin-bottom-10">
                                <img className='build-my-team-earnings-tc' src={Images.imageURL('stats-header-money-icon.png')} alt="" />
                                <p className="build-my-team-earnings-value">
                                    {state.userStats?.totalCoinsEarned}
                                </p>
                            </div>
                            <button
                                onClick={() => { setOpenMyTeamInfoModal(prev => ({ type: 'stats', open: true })); }}
                                className="hidden-button build-my-team-underline-14 hover-scale-transitioned">{Content.getValue('menu_title_my_team_referrals')}</button>
                        </div>
                        <div className="pink-bordered margin-bottom-20">
                            <div className="build-my-team-referrals-head">
                                <p>{Content.getValue('referral_name')}</p>
                                <p>{Content.getValue('treatcoin_earned')}</p>
                            </div>
                            <ul className={`build-my-team-referrals-stats-list ${myReferrals?.referrals ? '' : 'justify-center'}`}>

                                {loading ? <Box className="loader-mask">
                                    <CircularProgress className="loader" />
                                </Box> : <></>}
                                {myReferrals?.referrals ?
                                    <>
                                        {myReferrals?.referrals?.map((i, index) =>
                                            <li className='build-my-team-referrals-stats-list-item hover-scale-transitioned' key={i.username + index}>
                                                <div className='build-my-team-referrals-stats-list-item-name'>
                                                    <p className='build-my-team-referrals-stats-list-item-username'>{i.username}</p>
                                                    <p className="build-my-team-referrals-stats-list-item-gen">{Formatting.createOrdinal(i.generation)} Gen</p>
                                                </div>
                                                <div className="build-my-team-referrals-stats-list-item-earnings">
                                                    <img className='build-my-team-earnings-tc' src={Images.imageURL('stats-header-money-icon.png')} alt="" />
                                                    <p>{i.earnings}</p>
                                                </div>
                                            </li>
                                        )}</>
                                    : <></>}
                            </ul>
                        </div>
                        {myReferrals?.totalReferrals > 1 ? (
                            <div
                                className='flex margin-bottom-20 relative'>
                                {page > 1 ?
                                    <button
                                        onClick={() => { setPage(prev => prev - 1); }}
                                        className="hidden-button build-my-team-pagination-arrow previous">
                                        <ArrowBackIosIcon />
                                    </button>
                                    : <></>}
                                {[...Array(Math.ceil(myReferrals?.totalReferrals / myReferrals.pageSize)).keys()].slice(
                                    page === 1 ? page - 1 : page === (myReferrals?.totalReferrals / myReferrals.pageSize) ? page - 3 : page - 2,
                                    (page === 1 ? page + 2 : page + 1)
                                ).map(i => (
                                    <button
                                        onClick={() => setPage(i + 1)}
                                        key={i} className={`hidden-button build-my-team-pagination-item margin-right-8 hover-scale-transitioned ${page === (i + 1) ? 'chosen' : ''}`}>{i + 1}</button>
                                ))}
                                {page < (myReferrals.totalReferrals / myReferrals.pageSize) ?
                                    <button
                                        onClick={() => { setPage(prev => prev + 1); }}
                                        className="hidden-button build-my-team-pagination-arrow next">
                                        <ArrowForwardIosIcon />
                                    </button>
                                    : <></>}

                            </div>
                        ) : <></>}

                    </section>
                </div>
                <MyTeamInfoModal userStats={state.userStats} type={openMyTeamInfoModal.type}
                    openHowItWorks={openMyTeamInfoModal.open} onClose={(e) => setOpenMyTeamInfoModal(prev => {
                        e.preventDefault();
                        if (stats === '1') {
                            searchParams.delete('stats');
                            setSearchParams(searchParams, { replace: false });
                        }
                        return ({ ...prev, open: false });
                    })} />
                <TermsPopup setOpenTermsPopup={setOpenTermsPopup} openTermsPopup={openTermsPopup} setState={setState} />
                <Footer />
            </div>
        </>
    );
};

export default MyTeamReferrals;