import IntroSurveyFinishingSection from 'components/static/Intro_Survey_Finishing_Section.js';
import ApiFacade from 'ApiFacade.js';
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Mixpanel } from "utils/User_Events_Util.js";
import { redirectWithLangFunc } from "utils/RedirectWithLang.js";

const configuration = require('configuration.json');

const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const IntroSurveyFinished = () => {
    const navigate = useNavigate();
    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.intro_survey_finished });
    }, []);

    if (!ApiFacade.isLoggedIn()) {
        redirectWithLangFunc(configuration.paths.playground, navigate);
    }

    return (ApiFacade.isLoggedIn()) ? (
        <>
            <IntroSurveyFinishingSection />
        </>
    ) : (null);
};

export default IntroSurveyFinished;

