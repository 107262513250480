import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackButton from 'components/buttons/Back_Button.js';
import AllowThirdPartySharingCheckbox from 'components/checkboxes/Allow_Third_Party_Sharing_Checkbox.js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import UserData from 'User_Data.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';

const MySwal = withReactContent(Swal);


const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

function IntroSurveyFinishingSection() {
    const navigate = useNavigate();

    const handleBackToPlayground = () => {

        let allow3rdPartySharingChecked = document.getElementById('allow3rdPartySharing').checked;

        if (allow3rdPartySharingChecked) {
            let robotIconURL = Images.imageURL('robot-icon.png');

            const req = ApiFacade.allowSurveyDataSharing();
            req.then(
                function (res) {
                    if (res.ok){
                        //let result = res.response;
                        redirectWithLangFunc(configuration.paths.playground, navigate);
                    } else if (res.unauthorized){
                        //...
                    } else {
                        MySwal.fire({
                            title: '<p>' + Content.getValue("error_submitting_survey_title") + '</p>',
                            html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                            showCancelButton: false,
                            confirmButtonText: Content.getValue("close_button_text"),
                            customClass: {
                                confirmButton: 'standard-confirm-button'
                            }
                        });
                    }
                },
                () => {
                    MySwal.fire({
                        title: '<p>' + Content.getValue("error_submitting_survey_title") + '</p>',
                        html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                        iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                        background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                        showCancelButton: false,
                        confirmButtonText: Content.getValue("close_button_text"),
                        customClass: {
                            confirmButton: 'standard-confirm-button'
                        }
                    });
                }
            );
        }
        else {
            redirectWithLangFunc(configuration.paths.playground);
        }
    }

    return (
        <div className='intro-survey-finishing-section-container'>
            <div className='intro-survey-finishing-section-absolute' />
            <div className='intro-survey-finishing-section intro-survey-finishing-section'>
                <div className='intro-survey-finishing-section-info'>
                    <div>
                        <h1 className='intro-survey-finishing-section-header'>{ Content.getValue("intro_survey_finishing_section_header") }</h1>
                        <h4 className='intro-survey-finishing-section-content'>
                            { Formatting.format(Content.getValue("intro_survey_finishing_section_content"), ["(treatCoinsAmount)"], [UserData.getItem(userDataKeys.offer_coins_amount)]) }
                        </h4>
                        <AllowThirdPartySharingCheckbox />
                    </div>
                    <div className="join-treatcoin-button-container">
                        <BackButton
                            enabled={true}
                            className='back-to-gain-coins'
                            contentKey='back_to_the_playground'
                            onClick={handleBackToPlayground} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroSurveyFinishingSection;