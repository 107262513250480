import CloseIcon from '@mui/icons-material/Close';
import { Box, Modal, useMediaQuery } from '@mui/material';


const PopupModal = (props) => {

    const {isOpened, closeModal, darkMode, style, blurBackground, children} = props;

    const isMobile = useMediaQuery('(max-width:750px)');


    const styles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? "94vw" : 730,
        bgcolor: darkMode ? '#33354F' : '#fff',
        color: darkMode ? '#fff' : '#000',
        borderRadius: 2,
        boxShadow: 24,
        padding: isMobile ? "15px" : "20px",
        fontFamily: 'Poppins',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: '100%',
        overflow: 'scroll',

        ...style
    };


    return (
        <>

            {isOpened && (
                <Modal
                    open={isOpened}
                    onClose={() => {
                        closeModal();
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{backdropFilter: blurBackground ? "blur(3px)" : ""}}
                    
                >
                    <Box sx={styles}>
                        <button
                            className="hidden-button info-modal-button"
                            onClick={closeModal}
                            style={{right: styles.padding, zIndex: 99}}
                        >
                            <CloseIcon sx={{color: darkMode ? "#fff" : "#000"}}/>
                        </button>
                        {children}
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default PopupModal;