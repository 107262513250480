import React from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;

const socialLinks = configuration.social;
const facebookLink = socialLinks.facebook;
const tiktokLink = socialLinks.tiktok;
const instagramLink = socialLinks.instagram;
const twitterLink = socialLinks.twitter;
const telegramLink = socialLinks.telegram;

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.lightMode = props.lightMode || false;
    }

    render() {


        return (
            <footer className={(this.lightMode) ? "light-mode" : ""}>
                <div className='footer-icons'>
                    <a target="_blank" href={facebookLink}>
                        <img
                            onClick={Mixpanel.trackByEvent}
                            data-event={mixpanelEvents.social_media_click}
                            data-social_media_clicked='facebook'
                            src={Images.imageURL('social/facebook.png')}
                            alt="Facebook"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                    <a target="_blank" href={tiktokLink}>
                        <img
                            onClick={Mixpanel.trackByEvent}
                            data-event={mixpanelEvents.social_media_click}
                            data-social_media_clicked='tiktok'
                            src={Images.imageURL('social/tiktok.png')}
                            alt="TikTok"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                    <a target="_blank" href={instagramLink}>
                        <img
                            onClick={Mixpanel.trackByEvent}
                            data-event={mixpanelEvents.social_media_click}
                            data-social_media_clicked='instagram'
                            src={Images.imageURL('social/instagram.png')}
                            alt="Instagram"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                    <a target="_blank" href={twitterLink}>
                        <img
                            onClick={Mixpanel.trackByEvent}
                            data-event={mixpanelEvents.social_media_click}
                            data-social_media_clicked='twitter'
                            src={Images.imageURL('social/twitter.png')}
                            alt="Twitter"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                    <a target="_blank" href={telegramLink}>
                        <img
                            onClick={Mixpanel.trackByEvent}
                            data-event={mixpanelEvents.social_media_click}
                            data-social_media_clicked='telegram'
                            src={Images.imageURL('social/telegram.png')}
                            alt="Telegram"
                            width="24"
                            height="24"
                            className='footer-icon'
                        />
                    </a>
                </div>
                {
                    <div className="legal-links-div">
                        <RedirectWithLang
                            to={configuration.paths.terms_of_use}
                            props={{
                                className: "footer-link"
                            }}
                            text={Content.getValue('menu_title_terms_of_use')}>
                        </RedirectWithLang>
                        <RedirectWithLang
                            to={configuration.paths.privacy_policy}
                            props={{
                                className: "footer-link"
                            }}
                            text={Content.getValue('menu_title_privacy_policy')}>
                        </RedirectWithLang>
                        <RedirectWithLang
                            to={configuration.paths.cookie_policy}
                            props={{
                                className: "footer-link"
                            }}
                            text={Content.getValue('menu_title_cookie_policy')}>
                        </RedirectWithLang>
                    </div>
                }
                <div className="flex gap-10 justify-center items-center">
                    <div className="copyright-div margin-0">&copy; {Content.getValue("brand_name")}</div>
                    <div className="rights-reserved margin-0">{Content.getValue("all_rights_reserved")}</div>
                </div>
            </footer>
        );
    }

}

export default Footer;