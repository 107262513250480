import React from 'react';
import Images from '../../utils/Images';
import Content from '../../utils/Content';
import ActionButton from '../buttons/Action_Button';

function DeleteModal({ onClose, handleReviewDelete, deleteModalOpen, }) {
  return (
    <div className={`delete-review-modal ${deleteModalOpen ? 'open' : ''}`}>
      <img src={Images.imageURL('delete-icon.png')} alt="" />
      <p className='delete-review-modal-desc'>{Content.getValue('do_you_want_to_delete_review')}</p>
      <div className="delete-review-modal-buttons w-full">
        <ActionButton
          classNames='delete-review-modal-cancel-button'
          content={Content.getValue('cancel_button_text')}
          onClick={onClose} />
        <ActionButton
          onClick={handleReviewDelete}
          content={Content.getValue('reviews_delete')}
        />
      </div>
    </div>
  );
}

export default DeleteModal;