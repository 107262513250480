/* eslint-disable no-console */
import ApiFacade from 'ApiFacade.js';
import Backend from 'Backend.js';
import ActionButton from 'components/buttons/Action_Button';
import { SpinnerContext } from 'context/Global_Spinner';
import { useContext, useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Alerts from 'utils/Alerts.js';
import Content from 'utils/Content.js';
import Images from 'utils/Images.js';
import Platform from 'utils/Platform.js';
import UserData from 'User_Data.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const CustomSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className='terms-popup-green-limb' viewBox="0 0 375 439" fill="none">
    <g filter="url(#filter0_f_1685_12744)">
      <circle cx="187.877" cy="177.876" r="57.6182" transform="rotate(78.5921 187.877 177.876)" fill="url(#paint0_linear_1685_12744)" />
    </g>
    <defs>
      <filter id="filter0_f_1685_12744" x="-72.5529" y="-82.5539" width="520.859" height="520.86" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="101.4" result="effect1_foregroundBlur_1685_12744" />
      </filter>
      <linearGradient id="paint0_linear_1685_12744" x1="130.258" y1="177.876" x2="245.495" y2="177.876" gradientUnits="userSpaceOnUse">
        <stop stopColor="#289BFE" />
        <stop offset="0.5" stopColor="#55CC7D" />
        <stop offset="1" stopColor="#CF69FF" />
      </linearGradient>
    </defs>
  </svg>
);

function TermsPopup({ setOpenTermsPopup, openTermsPopup, setState }) {
  const [step, setStep] = useState(0);
  const [currentImage, setCurrentImage] = useState(step);
  const [fading, setFading] = useState(false);
  const [countdown, setCountdown] = useState(null);
  const [clicked, setClicked] = useState([]);
  const [checked, setChecked] = useState(false);
  const [opacity, setOpacity] = useState(false);

  const { updateIsVisibleSpinner } = useContext(SpinnerContext);

  

  const listText = Content.getValue('terms_and_conditions_second_part_list').split("{separator}");
  let platform = Platform.getPlatformName();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (countdown > 1) {
        setCountdown(prevCount => prevCount - 1);
      } else {
        setCountdown(null);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [countdown]);
  useEffect(() => {
    setFading(true);
    const timeout = setTimeout(() => {
      setFading(false);
      setCurrentImage(step);
    }, 500);

    return () => clearTimeout(timeout);
  }, [step]);

  const acceptTerms = async () => {
    try {
      updateIsVisibleSpinner({ isVisible: true, contentKey: 'loading' });
      const res = await ApiFacade.userAcceptTerms();
      if (res.ok) {
        const { token, websiteReferralLink, telegramReferralLink } = res.response;
        UserData.setItem(userDataKeys.website_referral_link, websiteReferralLink);
        UserData.setItem(userDataKeys.telegram_referral_link, telegramReferralLink);
        if (setState) {
          setState({
            webReferralLink: websiteReferralLink
          });
        }
        Backend.setUserDataFromJwt(token);
      } else if (res.unauthorized) {
        Alerts.showErrorAlert(res.unauthorized);
      }
    } catch (e) {
      Alerts.showErrorAlert(e.message);
    } finally {
      updateIsVisibleSpinner({ isVisible: false });
      setOpenTermsPopup(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setOpacity(openTermsPopup);
    }, 100);
  }, [openTermsPopup]);


  return (
    <div className={`terms-popup ${opacity ? 'opened' : ''}`} style={{ display: openTermsPopup ? 'flex' : 'none' }}>
      <div className="terms-popup-body padding-x-25" style={{ paddingBottom: platform === 'iOS' ? 90 : 25 }}>
        <button
          onClick={() => {
            setOpacity(false);
            setTimeout(() => {
              setOpenTermsPopup(false);
            }, 300);
          }}
          className="hidden-button close-button">
          <img src={Images.imageURL('close-account-suspended.png')} alt="" />
        </button>
        <div className="terms-popup-steps">
          {step < 1 ?
            <div className="terms-popup-head-wrapper">
              <div className="green-outline term-popup-bordered-outlined-heading">
                <h5 className="white-20-700-text padding-x-25">{Content.getValue('terms_popup_head_step_0')}</h5>
              </div>
            </div> :
            step < 4 ?
              <div className="terms-popup-head-wrapper">
                <div className="red-outline term-popup-bordered-outlined-heading ">
                  <h5 className="white-20-700-text letter-spacing-08 uppercase-text">
                    {Content.getValue((step < 3) ? 'terms_popup_head_step_do_not_say' : 'terms_popup_head_step_do_not_spam')}
                    <br />
                    <span className="white-16-400-text">
                      {Content.getValue(`terms_popup_head_step_${step}`)}
                    </span>
                  </h5>
                </div>
              </div> :
              <div className="terms-popup-head-wrapper margin-bottom-24">
                <h5 className="term-popup-step-terms-and-conditions-head">{Content.getValue(`terms_and_conditions`)}</h5>
              </div>
          }
          {(step < 4) ?
            <>
              <div className="terms-popup-images">
                <CustomSVG />
                <div className={`terms-popup-bg-image step-${step}`}
                  style={{
                    backgroundImage: `url(${Images.imageURL('referrals-page/popup/referrals-terms-popup-bg-image.png')}) `
                  }}
                >
                </div>
                <img
                  className={`terms-popup-bot ${fading ? 'fade-out' : ''}`}
                  src={Images.imageURL(`referrals-page/popup/referrals_terms_popup_bot_${currentImage}.png`)} />
              </div>
              <p className="terms-popup-desc">{Content.getValue(`terms_popup_desc_step_${step}`)}</p>
            </>
            :
            <div className='terms-popup-conditions-scrollable'>
              <SimpleBar
                style={{ maxHeight: 432, maxWidth: 582 }}
              >
                <p className='margin-top-0'>{Content.getValue('terms_and_conditions_first_part')}</p>
                <ol>
                  {listText.map(i => <li key={i}>{i}</li>)}
                </ol>
              </SimpleBar>
            </div>
          }

        </div>
        {(step < 4) ? <>
          <div className='flex gap-8 w-full justify-center '>
            <ActionButton
              classNames={'pink-outlined-button margin-x-0'}
              content={Content.getValue('previous')}
              disabled={step === 0}
              onClick={() => {
                setStep(prev => {
                  setCountdown(null);
                  if (1 < prev < 4) {
                    return prev - 1;
                  }
                });
              }}
            />
            <ActionButton
              disabled={(countdown <= 3 && countdown !== null)}
              classNames={'pink-contained-button margin-x-0'}
              content={(countdown <= 3 && countdown !== null) ? countdown : Content.getValue('next')}
              onClick={() => {
                setClicked(prev => [...prev, step]);
                if (!clicked.includes(step)) {
                  setCountdown(3);
                }
                if (step < 5) {
                  setStep(prev => prev + 1);
                }
              }}
            />
          </div>
        </>
          :
          <>
            <div className={`confirm-check-wrapper`}>
              <label className="confirm-check">
                <input
                  className='confirm-input margin-right-10'
                  type="checkbox"
                  disabled={false}
                  checked={checked}
                  onChange={(e) => {
                    e.stopPropagation();
                    setChecked(prev => !prev);
                  }}
                />
              </label>
              <p className="terms-popup-agreement">{Content.getValue('referrals_terms_agreement')}</p>
            </div>
            <ActionButton
              disabled={!checked}
              content={Content.getValue('continue_button_text')}
              onClick={acceptTerms}
            />
          </>
        }
      </div>
    </div>
  );
}

export default TermsPopup;