import React, { useContext } from 'react';
import ContentPageLogo from "components/graphics/Content_Page_Logo.js";
import LoginLogoutButton from 'components/buttons/Login_Logout_Button.js';
import LoggedInHeading from 'components/widgets/Logged_In_Heading.js';
import UserData from 'User_Data.js';
import ApiFacade from 'ApiFacade.js';
import { GlobalContext } from 'context/Global_Context';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const ContentPageHeader = props => {

    const { siteLayout } = useContext(GlobalContext);

    const showLoginButton = props.showLoginButton !== false;
    const layout = ApiFacade.isLoggedIn() && siteLayout ? siteLayout : null;

    return (
        <header className={`content-page-header ${UserData.getItem(userDataKeys.jwt_token) ? "new" : ""}`} >
            <ContentPageLogo layout={layout}/>
            {showLoginButton && (
                <div className='header-login-container'>
                    {!ApiFacade.isLoggedIn() 
                        ? <LoginLogoutButton isStyled fromMainMenu={false} />
                        : <LoggedInHeading layout={layout}/>
                    }
                </div>
            )}
        </header>
    )

}

export default ContentPageHeader;