import UserData from 'User_Data.js';
import jwt from 'jwt-decode';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;

class JWTUtils {

    static getJWTCountry() {
        let userJWTToken = UserData.getItem(userDataKeys.jwt_token);
        let guestJWTToken =  UserData.getItem(userDataKeys.guest_token);
        let token = userJWTToken ? userJWTToken: guestJWTToken;
        return jwt(token).country;
    }


}

export default JWTUtils;