import React from 'react';
import ApiFacade from 'ApiFacade.js';
import Content from 'utils/Content.js';

let thisComponent;
let removeFilterTimeout = null;

class ReferHomepageContainer extends React.Component {

    constructor(props) {
        super(props);
        thisComponent = this;
    }

    openDialog() {
        thisComponent.addFilter();
    }

    addFilter() {
        if (removeFilterTimeout !== null) clearTimeout(removeFilterTimeout);
        document.getElementById('refer-users-widget').className = 'refer-float-button hovered';
        removeFilterTimeout = setTimeout(function () {
            thisComponent.removeFilter();
        }, 3000);
    }

    removeFilter() {
        if (removeFilterTimeout !== null) clearTimeout(removeFilterTimeout);
        document.getElementById('refer-users-widget').className = 'refer-float-button';
    }

    render() {
        return (ApiFacade.isLoggedIn()) ? (<>
                <section className="refer-friends-container">
                    <h2 id="refer-friends-subtitle">{Content.getValue("refer_friends_cta_label")}</h2>
                </section>
            </>) : (null);
        }

}

export default ReferHomepageContainer;