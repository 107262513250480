import ActionButton from 'components/buttons/Action_Button.js';
import { useNavigate } from 'react-router-dom';
import Content from 'utils/Content.js';
import Images from 'utils/Images.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import Formatting from '../../utils/Formatting';

const configuration = require('configuration.json');

function CompletedLayout({ coins, treatCoinValue, isCompleted, widthValue, reviewData, isHomepage, isPercentage }) {
    const navigate = useNavigate();
    let styles = { display: !isCompleted && 'none' };
    if (widthValue) {
        styles['width'] = widthValue;
    }

    const CommonComponent = () => <>

        <p className='completed-layout-name white-i text-center-i'>
            {Content.getValue('report_status_heading_4')}
        </p>
        {isPercentage ?
            <>
                <p className="top-offer-homepage-tc-value ">{Formatting.format(Content.getValue('offer_popup_percentage_value'), ['(value)'], [coins])}</p>
            </>
            : <>
        <div className={`top-offer-homepage-payout `} >
            <div className="top-offer-homepage-tc-value">
                <div>+</div>
                <img src={Images.imageURL('offers/stats-header-money-icon-new.png')} alt="TreatCoin" className="top-offer-homepage-treat-coin" />
            </div>
            <p className="top-offer-homepage-tc-value">{coins.toLocaleString()}</p>
            <p className="top-offer-homepage-usd-value">=  ${(coins * treatCoinValue).toFixed(2)}</p>
                </div></>}
        <p className='text-success text-center-i'>
            {Content.getValue('received')}
        </p>
    </>;

    const renderContent = () => {
        switch (true) {
            case reviewData?.reviewSubmitted:
                return (
                    <>
                        <CommonComponent />
                    </>
                );

            case reviewData?.reviewRequired:
                return (
                    <>
                        <p className='completed-layout-name white-i text-center-i margin-bottom-5'>
                            {Content.getValue('report_status_heading_4')}
                        </p>
                        <ActionButton
                            onClick={() => {
                                redirectWithLangFunc(configuration.paths.my_reviews, navigate);
                            }}
                            classNames={'completed-layout-button'} content={Content.getValue('tell_us_your_experience_earn_tc')}>
                            <img src={Images.imageURL('stats-header-money-icon-new.png')} />
                        </ActionButton>
                    </>
                );

            case reviewData?.reviewAllowed:
                return (
                    <>
                        <CommonComponent />
                        <ActionButton
                            onClick={() => {
                                redirectWithLangFunc(configuration.paths.my_reviews, navigate);
                            }}
                            classNames={'completed-layout-button'} content={Content.getValue('tell_us_your_experience')} />
                    </>
                );

            default:
                return (
                    <>
                        <CommonComponent />
                    </>
                );
        }
    };

    return (
        <div style={styles} className={`completed-layout ${isHomepage ? 'homepage' : ''}`}>
            {renderContent()}
        </div>
    );
}

export default CompletedLayout;
