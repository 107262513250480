import React from 'react';
import LinkCreator from 'utils/Link_Creator.js';

import Content from 'utils/Content.js';

class ConnectToBotButton extends React.Component {

    render() {
                
        let discordLinkAddress = LinkCreator.createDiscordLink();

        return (
            <a href={discordLinkAddress} className="connect-to-telegram-button margin-top-20">
                {Content.getValue("connect_to_discord_now")}
            </a>
        );
    }

}

export default ConnectToBotButton;