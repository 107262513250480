import React from 'react';
import { Swiper, SwiperSlide, } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Content from 'utils/Content.js';
import ShowMore from 'components/wrappers/Show_More';
const configuration = require('configuration.json');
// const nftValuePerTreatCoin = configuration.business_logic.nft_value_per_treat_coin;
const usdValuePerTreatCoin = configuration.business_logic.usd_value_per_treatcoin;

class TreatCoinsEqualsMoneySection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            earnAmount: 0,
        }
        this.formatter = new Intl.NumberFormat('en').format;
    }

    handleSlideChange = (evt) => {
        const amount = Content.getValue("treat_coins_equals_money_step_1_text").find((each, idx) => idx===evt.realIndex);
        this.setState({
            earnAmount: amount,
            currentIndex: evt.realIndex
        });
    };

    render() {
            return <>
                <h2 className="how-does-it-work" id="how-does-it-work-title">{Content.getValue("treat_coins_equals_money_subtitle")}</h2>
                <ShowMore sectionName='tcEqualsMoney'>
                <div className="steps-div-container">
                    <div className="treat-coin-equals-div">
                        <span className="treat-coin-equals-text treat-coin-equals-money-text">{Content.getValue("treat_coins_equals_money_step_1_title")}</span>
                        <Swiper
                            loop
                            updateOnWindowResize
                            grabCursor
                            centeredSlides
                            centeredSlidesBounds
                            slidesPerView={2.5}
                            spaceBetween={30}
                            initialSlide={1}
                            slideToClickedSlide={false}
                            onSlideChange={this.handleSlideChange} >
                            {Content.getValue("treat_coins_equals_money_step_1_text").map((each, idx) => (
                                <SwiperSlide
                                    key={each}
                                    >
                                        <div className={`treat-coin-equals-amount ${this.state.currentIndex !== idx ? 'treat-coin-equals-amount-inactive' : 'margin-right'}`}>
                                            <div>
                                                <span>{this.formatter(each)}</span>
                                                <span className='treat-little-text'>{Content.getValue("treat_coin_abbreviation")}</span>
                                            </div>
                                        </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        </div>
                        <span className="treat-coin-equals-text treat-coin-equals-money-text margin-top-10 inline-block">{Content.getValue("treat_coins_equals_money_step_2_title")}</span>
                        <div className='treat-coin-equals-div-container'>

                        <div className="treat-coin-equals-div">
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <p className='treat-coin-equals-amount'>
                                    ${this.formatter(this.state.earnAmount * usdValuePerTreatCoin)}
                                </p>
                                <p className="treat-coin-equals-text treat-coin-equals-money-text green-color padding-x-25">{Content.getValue("treat_coins_equals_money_step_2_text")}</p>
                            </div>
                        </div>

                            {/* <div className="treat-coin-equals-div margin-bottom-0 self-center">
                            <span className="treat-coin-equals-text treat-coin-equals-money-text">{Content.getValue("treat_coins_equals_money_step_3_title")}</span>
                        </div> */}
                        
                            {/* <div className="treat-coin-equals-div">
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                                <p className='treat-coin-equals-amount'>
                                    ${this.formatter(this.state.earnAmount * nftValuePerTreatCoin)}
                                </p>
                                <p className="treat-coin-equals-text treat-coin-equals-money-text green-color padding-x-25">{Content.getValue("treat_coins_equals_money_step_3_text")}</p>
                            </div>
                        </div> */}

                        <div className="treat-coin-equals-div self-center">
                            <span className="treat-coin-equals-text treat-coin-equals-money-text">{Content.getValue("treat_coins_equals_money_step_4_title")}</span>
                        </div>

                        <div className="treat-coin-equals-div">
                            <p className="treat-coin-equals-text treat-coin-equals-money-text green-color padding-x-25 margin-top-20">{Content.getValue("treat_coins_equals_money_step_4_text")}</p>
                        </div>

                    </div>
                </div>
                </ShowMore>
            </>;
    }

}

export default TreatCoinsEqualsMoneySection;