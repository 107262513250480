import Content from 'utils/Content.js';
import 'design/reviews.css';
import CoinsConversionPanel from "components/panels/CoinsConversionPanel";
import Formatting from 'utils/Formatting.js';
import PopupModal from 'components/modals/PopupModal';
import { useState } from 'react';
import WriteOfferReview from './WriteOfferReview';

const ReviewsToDo = (props) => {

    const {reviews, afterReviewSubmit} = props;

    const [isEditOpen, setIsEditOpen] = useState(false);
    const [selectedReview, setSelectedReview] = useState(reviews[0]);

    return (
        <>
            {reviews.map((review, idx) => {
                return (
                    <div className="review-wrap flex-row" key={idx}>
                        <div className="review-img">
                            <img src={review.ImageUrl} />
                        </div>
                        <div className="reviews-todo-content flex-grow space-between flex-column">
                            <div className="review-app-name bold">{review.AppName}</div>
                            <div className="review-title">
                                {review.AttributeMeaning1 && review.AttributeMeaning2
                                    ? Formatting.format(Content.getValue("deposit_x_win_y"), ["(att1)", "(att2)"], [review.AttributeMeaning1, review.AttributeMeaning2])
                                    : review.MainTitle
                                }
                            </div>
                            <CoinsConversionPanel
                                coins={review.Coins}
                                treatCoinValue={review.TreatcoinValue}
                                fullWidth
                            />
                            <div className="review-btn">
                                <button className="round-btn" 
                                    onClick={()=>{
                                        setIsEditOpen(true);
                                        setSelectedReview(review);
                                    }}
                                >
                                    {review?.reviewRequired === 1 
                                        ? Formatting.format(Content.getValue("review_app_and_win_tcoins"), ["(appName)", "(tcValue)"], [review.AppName, review.Coins])
                                        : <span className="capitalize">{Content.getValue('review')} {review.AppName}</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                )
            })}
            <PopupModal 
                isOpened={isEditOpen} 
                closeModal={() => setIsEditOpen(false)}
                darkMode
                blurBackground
            >
                <WriteOfferReview 
                    review={selectedReview} 
                    afterReviewSubmit={(e)=>{
                        setIsEditOpen(false)
                        afterReviewSubmit(e)
                    }} 
                />
            </PopupModal>
        </>
    );
};

export default ReviewsToDo;