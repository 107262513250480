import ContentPageHeader from "components/sections/Content_Page_Header.js";
import AboutText from "components/text/About_Text.js";
import Footer from "components/sections/Footer.js";
import Content from 'utils/Content.js';
import { useEffect } from "react";
import { Mixpanel } from "utils/User_Events_Util.js";

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;


const AboutUs = () => {

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.about_us});
    }, []);

    return (
        <>
            <div id="main">

                <div id="content">

                    <ContentPageHeader />
                    <section className="content-page-section">
                        <div>
                            <div className="title-container">
                                <h1 className="page-title">{Content.getValue("meta_content").about_us.title}</h1>
                            </div>
                            <div className="page-container">
                                <AboutText />
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default AboutUs;