import React from 'react';
import Images from 'utils/Images.js';

function Slider({
    value, 
    setValueHandler, 
    minValue = 1, 
    maxValue = 10 
}) {
    const currentValue = value || 5;
    const items = new Array(maxValue - minValue + 1).fill(minValue).map((item, idx) => item + idx);
    const changeHandler = (evt) => setValueHandler(+evt.target.value);
    const incrementHandler = () => currentValue < maxValue && setValueHandler(currentValue + 1);
    const decrementHandler = () => currentValue > minValue && setValueHandler(currentValue - 1);

    return (
        <div className="slider-container">
            <input 
                type="range" 
                id="myRange" 
                className="slider" 
                value={currentValue} 
                min={minValue} 
                max={maxValue}
                onChange={changeHandler} 
            />
            <div className="slider-numbers">
                {
                    items.map((each) => (
                        <span key={each} className={`${each === currentValue ? 'active' : ''}`}>{ each }</span>
                    ))
                }
            </div>
            <div className="slider-buttons">
                <img
                    src={Images.imageURL('minus-icon.png')}
                    alt='Decrease'
                    width='24'
                    height='24'
                    disabled={currentValue === minValue}
                    onClick={decrementHandler}
                /> 
                <img
                    src={Images.imageURL('plus-icon.png')}
                    alt='Increase'
                    width='24'
                    height='24'
                    disabled={currentValue === maxValue}
                    onClick={incrementHandler}
                /> 
            </div>
        </div>
    )
}

export default Slider;