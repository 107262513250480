import React, { createContext, useState } from 'react';

const MenuContext = createContext();

const MenuContextProvider=({children})=>{
    const [showMenu, setShowMenu] = useState(false)

    const updateShowMenu=()=>{
        setShowMenu(prev=>!prev)
    }
    return (
        <MenuContext.Provider value={{ showMenu, updateShowMenu }}>
        {children}
    </MenuContext.Provider>
    )
}

export { MenuContext, MenuContextProvider };