import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Footer from "components/sections/Footer.js";
import Content from 'utils/Content.js';
import { useEffect, useState } from "react";
import { Mixpanel } from "utils/User_Events_Util.js";
import 'design/reviews.css';
import AllReviews from "components/reviews/AllReviews.js";
import UserReviews from "components/reviews/UserReviews.js";
import { Tab, TabList, TabPanel, Tabs } from "components/tabs/tabs.js";

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;


const MyReviews = (props) => {
 
    const [activeTab, setActiveTab] = useState(props.activeTab ?? 0);

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.about_us });
    }, []);


    return (
        <div id="main">
            <div id="content">
                <ContentPageHeader />
                <section className="content-page-section">
                    <div>
                        <div className="title-container">
                            <h1 className="page-title">{Content.getValue("menu_section_reviews")}</h1>
                            <div className="page-description desktop">{Content.getValue("my_reviews_sub_title")}</div>
                        </div>
                        <div className="page-container">
                            <Tabs selectedIndex={activeTab} onSelect={index => setActiveTab(index)}>
                                <TabList>
                                    <Tab>{Content.getValue("my_reviews_tab")}</Tab>
                                    <Tab>{Content.getValue("users_reviews_tab")}</Tab>
                                </TabList>
                                <TabPanel>
                                    <UserReviews />
                                </TabPanel>
                                <TabPanel>
                                    <AllReviews />
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </div>
    );
};

export default MyReviews;