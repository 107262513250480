import React, {useState, useEffect} from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import { Spinner } from 'react-spinners-css';
import Content from 'utils/Content.js';
import Formatting from 'utils/Formatting.js';
import ConfirmModal from './Confirm_Modal';


const configuration = require('configuration.json');

const withdrawalModes = configuration.withdrawal_modes;
const withdrawalHintModes = configuration.withdrawal_hint_modes;


const WithdrawalCalculator = props => {

    const { onSubmit } = props;

    const [confirmModal, setConfirmModal] = useState(false);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [currencyAmountLoaded, setCurrencyAmountLoaded] = useState(true);
    const [isRestricted, setIsRestricted] = useState(false);
    const [coins, setCoins] = useState(null);
    const [treatCoinValue, setTreatCoinValue] = useState(0.00);
    const [treatCoinAmount, setTreatCoinAmount] = useState(null);
    const [treatCoinAmountStr, setTreatCoinAmountStr] = useState('');
    const [currencyAmount, setCurrencyAmount] = useState(null);
    const [amountHintMessage, setAmountHintMessage] = useState(null);
    const [amountAcceptable, setAmountAcceptable] = useState(false);
    const [withdrawalMode, setWithdrawalMode] = useState(null);
    const [minTreatCoinWithdrawal, setMinTreatCoinWithdrawal] = useState(0);


    useEffect(() => {
        getClientCoins();
    }, []);

    useEffect(() => {
        updateCurrencyAmount();
    }, [treatCoinAmount]);

    const updateCurrencyAmount = () => {

        setCurrencyAmountLoaded(true)
        setCurrencyAmount(treatCoinAmount ? (treatCoinAmount * treatCoinValue).toFixed(2) : null)
    }

    const initiateWithdrawal = () => {
        const currencyAmount = treatCoinAmount * treatCoinValue;

        const mixpanelObject = {
            withdraw_treatcoin_value: treatCoinAmount,
            is_withdraw_all: withdrawalMode === withdrawalModes.maximum,
            is_withdraw_minimum: withdrawalMode === withdrawalModes.minimum
        }

        onSubmit({ 
            treatCoinAmount: treatCoinAmount, 
            currencyAmount: currencyAmount.toFixed(2), 
            mixpanelObject 
        });
        
    }

    const updateWithdrawalMode = newMode => {

        if (withdrawalMode === newMode) {

            setWithdrawalMode(null)
            setTreatCoinAmount(null)
            setTreatCoinAmountStr('')
            setAmountHintMessage(Formatting.format(Content.getValue("min_treat_coin_withdrawal_hint"), ["(minTreatCoinWithdrawal)"], [Formatting.formatTreatCoinAmount(minTreatCoinWithdrawal)]))
            setAmountAcceptable(false)
        }
        else if (newMode === withdrawalModes.minimum || newMode === withdrawalModes.maximum) {

            let newTreatCoinAmount = null;
            if (newMode === withdrawalModes.minimum) newTreatCoinAmount = Math.min(minTreatCoinWithdrawal, coins);
            else if (newMode === withdrawalModes.maximum) newTreatCoinAmount = coins;

            setWithdrawalMode(newMode)
            setTreatCoinAmount(newTreatCoinAmount)
            setTreatCoinAmountStr(newTreatCoinAmount.toFixed(2))
            setAmountAcceptable((newTreatCoinAmount >= minTreatCoinWithdrawal))
        }
    }

    const updateCoinsAmount = buttonPressed => {

        let newAmountStr = null;

        if (!isNaN(buttonPressed) && !(treatCoinAmountStr.includes(".") && treatCoinAmountStr.split(".")[1].length === 2)) {
            newAmountStr = treatCoinAmountStr + buttonPressed;
        }
        else if (buttonPressed === '.' && treatCoinAmountStr.length > 0 && !treatCoinAmountStr.includes(".")) {
            newAmountStr = treatCoinAmountStr + buttonPressed;
        }
        else if (buttonPressed === 'del' && treatCoinAmountStr.length > 0) {
            newAmountStr = treatCoinAmountStr.slice(0, -1);
        }

        if (newAmountStr !== null) {
            let newAmountAcceptable;
            let newHintMessage;
            let newAmount = (newAmountStr.length > 0) ? Number(newAmountStr) : null;

            if (newAmount === null || newAmount < minTreatCoinWithdrawal || newAmount > coins) {
                if (newAmount === null) {
                    newHintMessage = Formatting.format(Content.getValue("min_treat_coin_withdrawal_hint"), ["(minTreatCoinWithdrawal)"], [Formatting.formatTreatCoinAmount(minTreatCoinWithdrawal)]);
                }
                else if (newAmount < minTreatCoinWithdrawal) {
                    newHintMessage = Formatting.format(Content.getValue("min_treat_coin_withdrawal_hint"), ["(minTreatCoinWithdrawal)"], [Formatting.formatTreatCoinAmount(minTreatCoinWithdrawal)]);
                }
                else if (newAmount > coins) {
                    newHintMessage = Formatting.format(Content.getValue("max_treat_coin_withdrawal_hint"), ["(maxTreatCoinWithdrawal)"], [Formatting.formatTreatCoinAmount(coins)]);
                }
                newAmountAcceptable = false;
            }
            else {
                newAmountAcceptable = true;
            }

            setTreatCoinAmount(newAmount)
            setTreatCoinAmountStr(newAmountStr);
            setAmountAcceptable(newAmountAcceptable);
            setWithdrawalMode(null);
            setAmountHintMessage(newHintMessage);
        }
    }


    const getClientCoins = async () => {
        try{
            const res = await ApiFacade.myCoins();

            setIsLoaded(true);

            if (res?.ok){
                const {coins, minimumCoinsForWithdrawal, treatCoinValue} = res.response;
                setCoins(coins);
                setTreatCoinValue(treatCoinValue);
                setMinTreatCoinWithdrawal(minimumCoinsForWithdrawal);
                setAmountHintMessage(Formatting.format(Content.getValue("min_treat_coin_withdrawal_hint"), ["(minTreatCoinWithdrawal)"], [Formatting.formatTreatCoinAmount(minimumCoinsForWithdrawal)]))
            } else if (res?.unauthorized){
                setIsRestricted(true);
            } else {
                setError(res?.error);
            }

        }catch(error){
            setIsLoaded(true);
            setError(error);
        }

    }


    let buttonEnabled = Boolean(amountAcceptable);
    let displayedHintMessage = '';
    let hintMode = treatCoinAmount !== null ? withdrawalHintModes.error : withdrawalHintModes.hint

    if (amountAcceptable === false) {
        displayedHintMessage = amountHintMessage;
    }


    return (
        error
        ? (<div className="error-message red-error">{Backend.loadBackendMessages().errorMessage}</div>)
        : isRestricted
        ? null
        : !isLoaded
        ? (<div className="treat-coins-balance-indicator">{Content.getValue("treat_coins_balance_label")}</div>)
        : (<>
            <div className="treat-coins-balance-indicator desktop">
                {Formatting.format(Content.getValue("treat_coins_balance_indicator"), ["(coins)"], [Formatting.formatTreatCoinAmount(coins)])}
            </div>
            <div className="withdrawal-input-container desktop">
                <div className="withdrawal-treat-coins-amount desktop">
                    <span>{(treatCoinAmountStr === "") ? "---" : treatCoinAmountStr}
                        <span className="cursor-blink">|</span>
                    </span>
                    <span className="withdrawal-treat-coins-usd-amount">
                        {(currencyAmount !== null || !currencyAmountLoaded) ?  `= ${Formatting.currencySymbol()}${currencyAmount?.toLocaleString()}`: (null)}
                    </span>
                    {(!currencyAmountLoaded) ? (<Spinner color="#6BC6F5" className="withdrawal-calculator-spinner" />) : (null)}
                </div>
                <div className="withdrawal-treat-coins-label desktop">{Content.getValue("treat_coins")}</div>
                <div className={`min-treat-coin-withdrawal-hint desktop ${hintMode}`}>{displayedHintMessage}</div>
                <div className="withdrawal-amount-options-container desktop">
                    <button className={(withdrawalMode === withdrawalModes.minimum) ? "withdrawal-option-button selected" : "withdrawal-option-button"} onClick={() => updateWithdrawalMode(withdrawalModes.minimum)}>{Content.getValue("minimum_button_text")}</button>
                    <button className={(withdrawalMode === withdrawalModes.maximum) ? "withdrawal-option-button selected" : "withdrawal-option-button"} onClick={() => updateWithdrawalMode(withdrawalModes.maximum)}>{Content.getValue("all_button_text")}</button>
                </div>
                <div className="withdraw-digits-container desktop">
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('1')}>1</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('2')}>2</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('3')}>3</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('4')}>4</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('5')}>5</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('6')}>6</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('7')}>7</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('8')}>8</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('9')}>9</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('.')}>.</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('0')}>0</button>
                    <button className="withdraw-digit" onClick={() => updateCoinsAmount('del')}><>&#8592;</></button>
                </div>
                            <button disabled={!buttonEnabled} onClick={() => setConfirmModal(true)} className={(buttonEnabled) ? "complete-withdraw-button" : "complete-withdraw-button disabled"}>{Content.getValue("withdraw_button_text")}</button>
                            {confirmModal ? <ConfirmModal initiateWithdrawal={initiateWithdrawal} setConfirmModal={setConfirmModal} /> : <></>}
            </div>
            </>
        )
    )

}

export default WithdrawalCalculator;