import React from 'react';
import UserData from 'User_Data.js';
import ApiFacade from 'ApiFacade.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

const globalConf = require('global_conf.json');
const mixpanelToken = globalConf.mixpanel_connection.token;
const analyticsId = globalConf.google_analytics_connection.analytics_id;
const gtagStreamId = globalConf.google_analytics_connection.measurement_id;

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const urls = configuration.urls;

const googleTagManagerURL = urls.google_tag_manager_url;

class Consent extends React.Component {

    static addGoogleAnalyticsScripts() {
        const pregoogleAnalyitcsScript = document.createElement("script");
        pregoogleAnalyitcsScript.innerHTML =
                `window.dataLayer = window.dataLayer || [];
                var gclient_id = null;
                function gtag(){dataLayer.push(arguments);};
                gtag('get', '${gtagStreamId}', 'client_id', function(clientId) {
                    if (clientId){
                        gclient_id = clientId;
                        if (window.localStorage){
                            window.localStorage.setItem('gclient_id', clientId);
                            if(window.onAnalyticsInitialize){
                                var onAnalyticsInitialize = window.onAnalyticsInitialize;
                                window.onAnalyticsInitialize = null;
                                onAnalyticsInitialize();
                            }
                        }
                    } else {
                        // console.warn('No client id returned');
                    } }
                );
                function get_gclient_id(){
                    if (!gclient_id && window.localStorage){
                        gclient_id = window.localStorage.getItem('gclient_id');
                    }
                    return gclient_id;
                }`;
                    
        document.head.appendChild(pregoogleAnalyitcsScript);    

        const googleAnalyticsScript = document.createElement("script");
        googleAnalyticsScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
            {'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            '${googleTagManagerURL}'+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',
            '${analyticsId}');`;
        document.head.appendChild(googleAnalyticsScript);

        setTimeout(function() { 
            if(window.onAnalyticsInitialize) {
                var onAnalyticsInitialize = window.onAnalyticsInitialize;
                window.onAnalyticsInitialize = null;
                onAnalyticsInitialize();
            }
        }, 3000);

    }

    static addMixpanel() {
        Mixpanel.init(mixpanelToken);
    }

    static sendThirdPartyConsent() {
        const req = ApiFacade.recordThirdPartyConsent();
        req.then(function (res) {
                if (res.ok) {
                    let result = res.response;
                    UserData.setItem(userDataKeys.guest_token, result.token);
                }
            },
            () => { 
            }
        );
    }
    
    static approveThirdParty() {
        Consent.addGoogleAnalyticsScripts();
        Consent.addMixpanel();
    }

}

export default Consent;