import { Children } from 'react';

export function Tabs(props) {

    const { children, selectedIndex, onSelect } = props;

    let counter = 0;

    return (
        Children.map(children, (child, index) => {
            if (index == 0) {
                return (
                    <TabList {...child.props} selectedIndex={selectedIndex} onTabChange={(e) => onSelect(e)}>{child.props.children}</TabList>
                )
            }
            else {
                if (selectedIndex == counter) {
                    counter++;
                    return (
                        <TabPanel {...child.props}>{child.props.children}</TabPanel>
                    )
                }
                counter++;
            } 
        })
    )

}

export function TabList(props) {

    const { children, selectedIndex, onTabChange } = props;

    return (
        <div className="tabs desktop">
            {Children.map(children, (child, index) => {
                return (
                    <Tab {...child.props} selectedIndex={selectedIndex} index={index} onTabChange={onTabChange}>{child.props.children}</Tab>
                )
            })}
        </div>
    )

}


export function Tab(props) {

    const { children, index, onTabChange, selectedIndex } = props;

    return (
        <div key={index} className={`tab desktop ${selectedIndex == index ? "marked" : ""}`} onClick={() => onTabChange(index)}>{children}</div>
    );
}


export function TabPanel(props) {

    const { children } = props;

    return (
        <div className="tabs-container">{children}</div>
    );
}


