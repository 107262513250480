import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import React from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import Consent from 'utils/Consent.js';
import UserData from 'User_Data.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

let mixpanelAccepted = true;
let googleAnalyticsAccepted = true;

const PinkSwitch = styled(Switch)(() => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#fff',
        marginLeft: "-10px",
        '&:hover': {
            backgroundColor: '#9F7DDB',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#9F7DDB',
    },
    '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#9191BF',
        opacity: 0.4,
    },
    '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#9191BF',
        height: "30px",
        width: "74px",
        borderRadius: "15px",
        marginTop: '-8px',
        marginLeft: '-10px',
    },
}));

function CookieModal({refer }) {
    return (
        <div ref={refer} className='cookie-modal'>
            <button
                onClick={(e) => {
                    if (e.currentTarget) {
                        let thirdPartySettings = {};
                        thirdPartySettings[userDataKeys.third_party_cookies.mixpanel] = mixpanelAccepted;
                        thirdPartySettings[userDataKeys.third_party_cookies.google_analytics] = googleAnalyticsAccepted;
                        UserData.setItem(userDataKeys.third_party_settings, JSON.stringify(thirdPartySettings));
                        if (googleAnalyticsAccepted) {
                            Consent.addGoogleAnalyticsScripts();
                        }
                        if (mixpanelAccepted) {
                            Consent.addMixpanel();
                            Mixpanel.trackByArgs(mixpanelEvents.cookies_click_accept, {});
                            Mixpanel.trackByArgs(mixpanelEvents.page_view, { 
                                page_name: window.location.pathname.includes(configuration.paths.welcome) ? pages.welcome : pages.homepage 
                            });
                        }
                        refer.current.style.display = "none";
                        document.getElementsByClassName("cookie-popup-overlay")[0].style.display = 'none';

                    }
                }}
                className="hidden-button">
            <img className='back-button-cookies' src={Images.imageURL('back-button.png')} height={44} width={44} />
            </button>

            <ul className='cookies-list'>
                <li className='cookies-list-item'>
                    <p className='cookies-list-item-desc'>{Content.getValue('mixpanel_cookies')}</p>
                    <PinkSwitch
                        value={mixpanelAccepted}
                        defaultChecked={mixpanelAccepted}
                        onChange={() => {
                            mixpanelAccepted = !(mixpanelAccepted);
                        }} />
                </li>
                <li className='cookies-list-item'>
                    <p className='cookies-list-item-desc'>{Content.getValue('google_analytics_cookies')}</p>
                    <PinkSwitch
                        value={googleAnalyticsAccepted}
                        defaultChecked={googleAnalyticsAccepted}
                        onChange={() => {
                            googleAnalyticsAccepted = !(googleAnalyticsAccepted);
                        }}
                    />
                </li>
            </ul>
        </div>
    );
}

export default CookieModal;