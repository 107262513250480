import Content from 'utils/Content.js';
const configuration = require('configuration.json');
const businessLogic = configuration.business_logic;

const minimumPasswordLength = businessLogic.minimum_password_length;

class DataValidator {

    static isValidEmail(email) {
        const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let valid = emailRegex.test(email);
        return valid;
    }

    static isValidPassword(password) {
        let valid;
        let error;
        if (password.length < minimumPasswordLength) {
            valid = false;
            error = Content.getValue("password_no_minimum_requirements_message");
        }
        else if (password === password.toLowerCase()) {
            valid = false;
            error = Content.getValue("password_no_minimum_requirements_message");
        }
        else if(/\d/.test(password) === false) {
            valid = false;
            error = Content.getValue("password_no_minimum_requirements_message");
        }
        else if (/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password) === false) {
            valid = false;
            error = Content.getValue("password_no_minimum_requirements_message");
        }
        else {
            valid = true;
            error = null;
        }
        return { valid: valid, error: error };
    }

    static isValidUsername(username) {
            let usernameRegex = /^[A-Za-z0-9_]{6,20}$/;
            let valid = (username !== undefined && username !== null && usernameRegex.test(username));
            return valid;
    }

    static isValidFullName(fullName) {
        let userFullNameRegex = /^[a-zA-Z]{2,40} [a-zA-Z]{2,40}$/;
        let valid = (fullName !== undefined && fullName !== null && userFullNameRegex.test(fullName));
        return valid;
    } 

    static isAdultAge(birthDateString) {

        let today = new Date();
        let birthDate = new Date(birthDateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return (age >= 18 && age <= 99);
    }

    static isValidTetherWallet(walletAddress) {
        const tetherWalletRegex = /^T[A-Za-z1-9]{33}$/;
        let valid = tetherWalletRegex.test(walletAddress);
        return valid;
    }

    static isValidPhoneNumber_brazil(phone) {
        const phoneRegex = /^\+[1-9][0-9]\d{1,14}$/;
        let valid = phoneRegex.test(phone);
        return valid;
    }

    static isValidPaymentMethod_Pix_cpfCnpj(cpfCnpj) {
        const cpfCnpjRegex = /^(\d{3})\.?(\d{3})\.?(\d{3})-?(\d{2}$)$|^(\d{2})\.?(\d{3})\.?(\d{3})\/?([0-1]{4})-?(\d{2})$/;
        let valid = cpfCnpjRegex.test(cpfCnpj);
        return valid;
    }

    static isValidPaymentMethod_Pix_randomKey(randomKey) {
        const randomKeyRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;
        let valid = randomKeyRegex.test(randomKey);
        return valid;
    }

    static hasDuplicatesArrObj(arrayOfObjects) {
        const names = new Set();
    
        for (const obj of arrayOfObjects) {
            if (names.has(obj.file.name)) {
                return true; 
            }
            names.add(obj.file.name);
        }
    
        return false; 
    }

}

export default DataValidator;