import React, { useState, useMemo, useEffect } from 'react';
import LiveNews from 'components/live_news/Live_News.js';
import UserData from 'User_Data.js';
import { memo } from 'react';

const configuration = require('configuration.json');
const broadcastChannels = configuration.broadcast_channels;
const userDataKeys = configuration.user_data_keys;

function NewsTrack() {
    const liveNewsCheck = JSON.parse(UserData.getItem(userDataKeys.receive_live_news));
    const [isNewsVisible, setIsNewsVisible] = useState(Boolean(liveNewsCheck));
    const [news, setNews] = useState(null);

    let liveNewsContent = UserData.getItem(userDataKeys.live_news_content);
    if (liveNewsContent && news === null) {
        setIsNewsVisible(true);
        setNews(liveNewsContent.split(","));
    }

    const channel = useMemo(() => new BroadcastChannel(broadcastChannels.news_channel), []);
    const handleEvent = (event) => {
        try {
            const news = event.data;
            if (news && news.length > 0) {
                setIsNewsVisible(true);
                setNews(news);
            }
            else {
                setNews([]);
                setIsNewsVisible(false);
            }
        } catch {
            setNews([]);
            setIsNewsVisible(false);
        }
    };

    useEffect(() => {
        return () => {
            channel.removeEventListener('message', handleEvent);
        };
    }, []);

    if (!news) {
        channel.addEventListener('message', handleEvent);
    }

    return isNewsVisible || (news && news.length > 0) ? <LiveNews news={news} /> : null;
}

export default memo(NewsTrack);