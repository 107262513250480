import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import React, { Component } from 'react';
import ApiFacade from 'ApiFacade.js';
import GoogleLogin from 'react-google-login';
import Images from 'utils/Images.js';
import Platform from 'utils/Platform.js';

import Backend from 'Backend.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import Redirects from 'utils/Redirects.js';

const MySwal = withReactContent(Swal);

const globalConf = require('global_conf.json');
const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const loginTypes = configuration.login_types;

const googleConnection = globalConf.google_connection;

const googleClientID = googleConnection.client_id;
const googleRedirectUrl = googleConnection.redirect_url;


const robotIconURL = Images.imageURL('robot-icon.png');

class LoginGoogle extends Component {

   

    static handleGoogleLogin(tokenId, gclient_id, fbclid, fbp, navigate, updateIsVisibleSpinner) {

        updateIsVisibleSpinner({ isVisible: true, contentKey: 'logging_in_with_google' });
        let ref = UserData.getItem(userDataKeys.referrer_code);
        const platform = Platform.getPlatformName();
        const directToDiscordCommunity = UserData.getItem(userDataKeys.redirect_to_discord_community_after_auth) ? 1 : 0;
        const postBody = {
            googleToken: tokenId,
            ref: ref,
            platformName: platform,
            gclient_id: gclient_id, 
            fbclid: fbclid,
            fbp: fbp,
            directToDiscordCommunity
        }
        fetch(Backend.backendURLs.googleLoginURL, {
            credentials: "include",
            method: Backend.backendMethods.post,
            headers: Backend.generateHeader(),
            body: JSON.stringify(postBody)
        }).then(async function (res) {
            updateIsVisibleSpinner({ isVisible: false });
            if (res.status === Backend.backendResponseCodes.internalServerErrorCode || res.status === Backend.backendResponseCodes.serviceUnavailableCode) {
                MySwal.fire({
                    title: '<p>' + Content.getValue("google_login_failed") + '</p>',
                    html: '<p class="swal2-content">' + Backend.loadBackendMessages().errorMessage + '</p>',
                    iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                    background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                    showCancelButton: false,
                    confirmButtonText: Content.getValue("close_button_text"),
                    customClass: {
                        confirmButton: 'standard-confirm-button'
                    }
                });
            }
            else if (res.status === Backend.backendResponseCodes.badRequestCode) {
                const result = await res.json();
                if (result.accountSuspended === true) {
                    redirectWithLangFunc(configuration.paths.account_suspended, navigate);
                }
                else {
                    MySwal.fire({
                        title: '<p>' + Content.getValue("google_login_failed_title") + '</p>',
                        html: '<p class="swal2-content">' + Content.getValue(result.message) + '</p>',
                        iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + robotIconURL + '"/>',
                        background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
                        showCancelButton: false,
                        confirmButtonText: Content.getValue("close_button_text"),
                        customClass: {
                            confirmButton: 'standard-confirm-button'
                        }
                    });
                }
            }
            else {
                const result = await res.json();
                await Backend.setUserSessionData(result, loginTypes.google);
                Redirects.makeRedirectAfterLogin(result.existingAccount, navigate);
            }
        });

    }
    
    render() {
        if (!ApiFacade.isLoggedIn()) {
        return <GoogleLogin
            clientId={googleClientID}
            render={renderProps => (
                <button className="google-signup-button" id="google-signup" onClick={() => { renderProps.onClick(); }} disabled={renderProps.disabled}>
                    {Content.getValue("sign_up_with_google_button_text")}
                    </button>
                )
            }
            scope="profile"
            uxMode="redirect"
            redirectUri={googleRedirectUrl}
            buttonText={Content.getValue("sign_up_with_google_button_text")}
            cookiePolicy={'single_host_origin'} />;
    }
    return null;
    }

}

export default LoginGoogle;


