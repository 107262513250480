import React, { useState, useMemo } from 'react';
import UserData from 'User_Data.js';
import TopRightCloseIcon from 'components/widgets/Top_Right_Close_Icon.js';
import IntroSurveyQuizLayout from 'components/static/Intro_Survey_Quiz_Layout.js';
import StepProgress from 'components/survey/Step_Progress.js';
import ProgressBar from 'components/widgets/Progress_Bar.js';
import QuizOption from 'components/options/Quiz_Option.js';
import BackButton from 'components/buttons/Back_Button.js';
import NextButton from 'components/buttons/Next_Button.js';
import Slider from 'components/widgets/Slider.js';
import Select from 'components/options/Select.js';
import OpenScreen from 'components/survey/Open_Screen.js';
import IntroSurveyAnswersStorage from 'utils/Intro_Survey_Answers_Storage.js';
import { format } from "date-fns";

import Content from 'utils/Content.js';

const configuration = require('configuration.json');
const phonePrefixes = configuration.country_to_phone_prefix;
const userDataKeys = configuration.user_data_keys;

const introSurveyQuestionTypes = configuration.intro_survey_question_types;
const country = UserData.getItem(userDataKeys.country);

function Quiz({ 
    goToNext, 
    goToBack, 
    submitHandler,
    data, 
    index, 
    hint 
}) {
    const introSurveyTotalSteps = parseInt(Content.getValue("intro_survey_total_steps"));
    const introSurveyTotalQuestions = parseInt(Content.getValue("intro_survey_finishing_index"));
    const { 
        type, 
        step, 
        indexInStep, 
        totalInStep, 
        content, 
        answers, 
        footerText,
        extraHTML,
        minValue,
        maxValue,
        defaultValue,
        placeholder } = data;
    const [checkedId, setCheckedId] = useState(-1);
    const [checkedItems, setCheckedItems] = useState({});
    const [sliderValue, setSliderValue] = useState(null);
    const [dropDownValue, setDropDownValue] = useState();
    const [openScreen, setOpenScreen] = useState({});
    const progressBarWidth = 100 * (index / introSurveyTotalQuestions)+'%';
    const isBackButtonEnabled = step!==1 || indexInStep!==1;
    const isNextButtonEnabledForSingle = checkedId >= 0;
    const isNextButtonEnabledForMultiple = Object.values(checkedItems).some(each => each);
    const isNextButtonEnabledForDropdown = dropDownValue >= 0;
    const isNextButtonEnabledForOpenScreen = useMemo(() => {
        const { bdate, city, state, country, surname, gender } = openScreen;
        if (!bdate || !city || !state || !country || !surname || !gender) {
            return false;
        }
        return true;
    }, [openScreen]); 

    const isNextButtonEnabled = 
        type === introSurveyQuestionTypes.range_selection ? 
        sliderValue :
        type === introSurveyQuestionTypes.dropdown_selection ?
        isNextButtonEnabledForDropdown :
        type === introSurveyQuestionTypes.multiple_selection ? 
        isNextButtonEnabledForMultiple :
        type === introSurveyQuestionTypes.single_selection ? 
        isNextButtonEnabledForSingle :
        isNextButtonEnabledForOpenScreen;

    const backClickHandler = () => {
        if (!isBackButtonEnabled) return;
        let previousQuestionIndex = null;
        if (step === 1 && indexInStep === 2) {
            previousQuestionIndex = 1;
        }
        else if (step === 1 && indexInStep === 3) {
            if (IntroSurveyAnswersStorage.fetchAnswer(1) === 1) {
                previousQuestionIndex = 2;
            }
            else {
                previousQuestionIndex = 3;
            }
        }
        else {
            previousQuestionIndex = index - 1;
        }
        goToBack(previousQuestionIndex);
        setQuestionSelection(previousQuestionIndex);
    }

    const addAnswer = () => {
        if (type === introSurveyQuestionTypes.range_selection) {
            IntroSurveyAnswersStorage.addRangeSelectionAnswer(index, sliderValue || null);
        }
        else if (type === introSurveyQuestionTypes.dropdown_selection) {
            IntroSurveyAnswersStorage.addDropdownSelectionAnswer(index, dropDownValue + 1);
        }
        else if (type === introSurveyQuestionTypes.single_selection) {
            IntroSurveyAnswersStorage.addSingleSelectionAnswer(index, checkedId + 1);
        }
        else if (type === introSurveyQuestionTypes.multiple_selection) {
            const selected = [];
            for (const key in checkedItems) {
                if (checkedItems[key]) selected.push(+key+1);
            }
            IntroSurveyAnswersStorage.addMultipleSelectionAnswer(index, selected);
        }
        else {
            IntroSurveyAnswersStorage.addOpenQuestionAnswer(
                index,
                openScreen.surname,
                openScreen.country,
                openScreen.state,
                openScreen.city,
                format(openScreen.bdate, 'yyyy-MM-dd'),
                (openScreen.prefix || phonePrefixes[country])  + openScreen.phone,
                openScreen.gender,
            );
        }
    }

    const nextClickHandler = () => {
        addAnswer();
        if (index === introSurveyTotalQuestions) {
            goToNext();
            submitHandler();
            return;
        }
        
        const goOverOne = (step === 1) && ((indexInStep === 1 && checkedId === 1) || (indexInStep === 2 && index === 2));
        const nextQuestionIndex = goOverOne ? index + 2 : index + 1;
        goToNext(nextQuestionIndex);
        setQuestionSelection(nextQuestionIndex);
        setSliderValue(null)
    }

    const setQuestionSelection = (questionIndex) => {
        const introSurveyQuestions = Content.getValue("intro_survey_questions");
        const questionType = introSurveyQuestions[questionIndex].type;
        const answer = IntroSurveyAnswersStorage.fetchAnswer(questionIndex);
        if (questionType === introSurveyQuestionTypes.single_selection) {
            setCheckedId(answer - 1);
        }
        else if (questionType === introSurveyQuestionTypes.multiple_selection) {
            const checked = {};
            if (answer !== null) {
                let singleAnswer;
                for (let i = 0, len = answer.length; i < len; i++) {
                    singleAnswer = answer[i];
                    checked[singleAnswer - 1] = true;
                }
            }
            setCheckedItems(checked);
        }
        else if (questionType === introSurveyQuestionTypes.range_selection) {
            setSliderValue(answer || defaultValue);
        }
        else if (questionType === introSurveyQuestionTypes.dropdown_selection) {
            setDropDownValue(answer - 1);
        }
        else if (questionType === introSurveyQuestionTypes.open_question) {
            setOpenScreen({});
        }
    }

    return (
        <div className='intro-survey-starting-section-container desktop'>
            <div className='intro-survey-starting-section-absolute desktop' />
            <TopRightCloseIcon url={configuration.paths.playground} />
            <div className='intro-survey-starting-section quiz desktop'>
                <StepProgress 
                    stepNumber={step}
                    totalSteps={introSurveyTotalSteps}
                    questionNumber={indexInStep}
                    totalQuestions={totalInStep}
                />
                <ProgressBar width={progressBarWidth} />
                <IntroSurveyQuizLayout>
                    {
                        type === introSurveyQuestionTypes.range_selection ? 
                        (
                            <div className='intro-survey-quiz-range-container desktop'>
                                <div dangerouslySetInnerHTML={{ __html: extraHTML }} />
                            </div>
                        ) :
                        type === introSurveyQuestionTypes.open_question ?
                        (
                            <OpenScreen 
                                prefix={phonePrefixes[country]}
                                data={openScreen}
                                changeHandler={(type, value) => setOpenScreen(prev => ({ ...prev, [type]: value }))}
                            />
                        ) :
                        (
                            <>
                                <div className='intro-survey-quiz-font heading desktop'>{ content }</div> 
                                { type === introSurveyQuestionTypes.multiple_selection && hint ? <div className='intro-survey-quiz-font subheading desktop'>{ hint }</div> : null }                    
                                <div className='intro-survey-quiz-options desktop'>
                                    {
                                        type === introSurveyQuestionTypes.single_selection ?
                                        Object.values(answers).map((each, idx) => (
                                            <QuizOption
                                                key={each}
                                                data={each}
                                                type={type}
                                                checked={idx===checkedId}
                                                changeCheckboxHandler={() => setCheckedId(idx)}
                                            />
                                        )) :
                                        type === introSurveyQuestionTypes.multiple_selection ?
                                        Object.values(answers).map((each, idx) => (
                                            <QuizOption
                                                key={each}
                                                data={each}
                                                type={type}
                                                checked={checkedItems[idx]}
                                                changeCheckboxHandler={() => setCheckedItems(prev => ({...prev, [idx]: !prev[idx]}))}
                                            />
                                        )) :
                                        type === introSurveyQuestionTypes.dropdown_selection ?
                                        <Select
                                            placeholder={placeholder}
                                            value={dropDownValue}
                                            items={Object.values(answers)}
                                            optionChangeHandler={(value) => setDropDownValue(value)}
                                        /> :
                                        null
                                    }
                                </div>
                            </>
                        )
                    }
                    <div className='quiz-actions-container desktop'>
                        {
                            type === introSurveyQuestionTypes.range_selection ? 
                            <div className='intro-survey-quiz-range-slider desktop'>
                                <div className='intro-survey-quiz-font heading silder-heading desktop'>{ content }</div>
                                <Slider
                                    value={sliderValue}
                                    defaultValue={null}
                                    setValueHandler={(value) => setSliderValue(value)}
                                    minValue={minValue}
                                    maxValue={maxValue}
                                />
                            </div> :
                            null
                        }

                        { footerText ? <div className='intro-survey-quiz-font footertext desktop'>{ footerText }</div> : null }

                        <div className='quiz-actions desktop'>
                                <BackButton
                                    onClick={backClickHandler}
                                    className={'intro-survey-quiz-back-button'}
                                    enabled={isBackButtonEnabled} />
                            <span onClick={nextClickHandler}>
                                <NextButton enabled={isNextButtonEnabled} />
                            </span>
                        </div>
                    </div>
                </IntroSurveyQuizLayout>
            </div>
        </div>
    )
}

export default Quiz;