import React, { createContext, useState } from 'react';
import Content from 'utils/Content.js';

const SpinnerContext = createContext();

const SpinnerContextProvider = ({ children }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [spinnerText, setSpinnerText] = useState('')

    const updateIsVisibleSpinner = ({ isVisible = true, contentKey}) => {
        setIsVisible(isVisible);
        setSpinnerText(contentKey ? Content.getValue(contentKey) : '')
    };

    return (
        <SpinnerContext.Provider value={{ isVisible, updateIsVisibleSpinner, spinnerText }}>
            {children}
        </SpinnerContext.Provider>
    );
};

export { SpinnerContext, SpinnerContextProvider };