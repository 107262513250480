import React from 'react';
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import UserData from 'User_Data.js';
import ApiFacade from 'ApiFacade.js';

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const routerBackValue = configuration.router_back_value;
const queryVariables = configuration.query_variables;
const languageUrlCodes = configuration.languages.map(lang => lang.url_code);

const generateRouteWithLang = (to) => {
  if (to === routerBackValue) {
    return routerBackValue;
  }
  const langForUrl =
    UserData.getItem(userDataKeys.language)?.slice(0, 2) !== 'en'
      ? '/' + UserData.getItem(userDataKeys.language)?.slice(0, 2)
      : '';

  let normalizedPath = window.location.pathname.split('/').pop();
  if (languageUrlCodes.includes(normalizedPath)) {
    normalizedPath = '';
  }
  let goingToSamePage = (('/' + normalizedPath) === to);

  let queryParams = "";
  let searchParams;
  if (goingToSamePage) {
    searchParams = new URLSearchParams(window.location.search);
    searchParams.delete(queryVariables.token);
    searchParams.delete(queryVariables.source);
  }
  else {
    searchParams = new URLSearchParams();
  }

  const isAuthorized = ApiFacade.isLoggedIn();
  const userIdentifiedInMixpanel = UserData.getItem(userDataKeys.mixpanel_user_identified);

  if (!isAuthorized || isAuthorized && userIdentifiedInMixpanel !== "1" && to === configuration.paths.welcome) {
    const utmSource = UserData.getItem(userDataKeys.utm_source);
    const utmMedium = UserData.getItem(userDataKeys.utm_medium);
    const utmCampaign = UserData.getItem(userDataKeys.utm_campaign);
    const utmContent = UserData.getItem(userDataKeys.utm_content);
    const utmTerm = UserData.getItem(userDataKeys.utm_term);

    if (utmSource !== null && !searchParams.has(queryVariables.utm_source)) {
      searchParams.append(queryVariables.utm_source, utmSource);
    }
    if (utmMedium !== null && !searchParams.has(queryVariables.utm_medium)) {
      searchParams.append(queryVariables.utm_medium, utmMedium);
    }
    if (utmCampaign !== null && !searchParams.has(queryVariables.utm_campaign)) {
      searchParams.append(queryVariables.utm_campaign, utmCampaign);
    }
    if (utmContent !== null && !searchParams.has(queryVariables.utm_content)) {
      searchParams.append(queryVariables.utm_content, utmContent);
    }
    if (utmTerm !== null && !searchParams.has(queryVariables.utm_term)) {
      searchParams.append(queryVariables.utm_term, utmTerm);
    }

    if (!isAuthorized) {
      const fbclid = UserData.getItem(userDataKeys.fbclid);
      const emailSignup = UserData.getItem(userDataKeys.email_signup);

      if (fbclid !== null && !searchParams.has(queryVariables.fbclid)) {
        searchParams.append(queryVariables.fbclid, fbclid.split('.').pop());
      }
      if (emailSignup !== null && !searchParams.has(queryVariables.email)) {
        searchParams.append(queryVariables.email, emailSignup);
      }
    }
  }

  let paramsString = searchParams.toString();
  queryParams = ((paramsString.length > 0) ? '?' : '') + paramsString;

  const urlFragment = (goingToSamePage && window.location.href.split("#").length === 2) ? `#${window.location.href.split("#")[1]}` : "";

  const routeWithLang = `${langForUrl}${to}${queryParams}${urlFragment}`;

  return routeWithLang;
};

const RedirectWithLang = ({ onClick, props, className, to, text, isButton, children, disabled, allowRedirect = true }) => {
  const navigate = useNavigate();
  if (isButton) {
    return <button
      className={className}
      onClick={() => {
        if (onClick) {
          onClick();
        } else if (props?.onClickHandler) {
          props?.onClickHandler();
        }
        if (allowRedirect) {
          navigate(generateRouteWithLang(to));
        }
      }}
      disabled={disabled}
      {...props}
    >
      {text}
      {children}
    </button>;
  } else {
    return (
      <Link
        {...props}
        to={generateRouteWithLang(to)}
      >
        {text}
        {children}
      </Link>
    );
  }
};

const redirectWithLangFunc = async (to, navigate) => {
  const routeWithLang = generateRouteWithLang(to);
  if (navigate) {
    navigate(routeWithLang);
  } else {
    window.location.href = routeWithLang;
  }
};


export { RedirectWithLang, redirectWithLangFunc, generateRouteWithLang };