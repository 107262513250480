import GetPathName from "./Get_Pathname";
import Images from "./Images";

const imagePathGenerator = (imagePath, urlPath) => {
    const currentPath = GetPathName();
    if (currentPath === urlPath) {
        return Images.imageURL(imagePath.replace('2', 'white'));
    } else {
        return Images.imageURL(imagePath);
    }
};

export default imagePathGenerator;