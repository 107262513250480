import EmailSubscriptionSettings from 'components/forms/Email_Subscription_Settings.js';
import Footer from 'components/sections/Footer.js';
import ContentPageHeader from "components/sections/Content_Page_Header.js";

import Content from 'utils/Content.js';
import { useEffect } from "react";
import { Mixpanel } from "utils/User_Events_Util.js";

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const NotificationSettings = () => {

    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.notification_settings });
    }, []);

    return (
        <div>
            <div id="main">
                <div id="content">
                    <ContentPageHeader />
                    <section>
                        <div>
                            <div className="title-container">
                                <h1 className="settings-title">{Content.getValue('menu_title_notification_settings')}</h1>
                            </div>
                            <div className="page-container">
                                <EmailSubscriptionSettings />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default NotificationSettings;