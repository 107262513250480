import * as queryString from 'query-string';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Formatting from 'utils/Formatting.js';
import Images from 'utils/Images.js';
import LinkCreator from 'utils/Link_Creator.js';
import Content from 'utils/Content.js';
import { Mixpanel } from './User_Events_Util';
import { redirectWithLangFunc } from 'utils/RedirectWithLang.js';
import UserData from 'User_Data.js';

const globalConf = require('global_conf.json');
const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const urls = configuration.urls;
const userDataKeys = configuration.user_data_keys;
const emailSignupModes = configuration.email_signup_modes;

const MySwal = withReactContent(Swal);

// const facebookConnection = globalConf.facebook_connection;
const discordConnection = globalConf.discord_connection;

// const facebookAppID = facebookConnection.app_id;
// const facebookRedirectUrl = facebookConnection.redirect_url;

const discordClientID = discordConnection.client_id;
const discordRedirectUrl = discordConnection.redirect_url;

// const facebookAuthURL = urls.facebook_auth_url;
const discordAuthURL = urls.discord_auth_url;

class Joining {
    static showPopup(navigate) {

        const emailSignup = UserData.getItem(userDataKeys.email_signup);

        if (emailSignup === emailSignupModes.direct || 
            emailSignup === emailSignupModes.join_button) {
            redirectWithLangFunc(configuration.paths.email_signup, navigate);        
        }  
        else {

           /* 
           const stringifiedFacebookParams = queryString.stringify({
                client_id: facebookAppID,
                redirect_uri: facebookRedirectUrl,
                scope: ['email'].join(','),
                response_type: 'code',
                auth_type: 'rerequest',
                display: 'popup',
            });
            */

            const stringifiedDiscordParams = queryString.stringify({
                client_id: discordClientID,
                response_type: 'code',
                redirect_uri: discordRedirectUrl,
                scope: ['identify', 'email', 'guilds.join', 'guilds.members.read'].join(' '),
                prompt: 'none'
            });

            // const facebookLoginUrl = Formatting.format(facebookAuthURL, ["(stringifiedParams)"], [stringifiedFacebookParams]);
            const discordLoginUrl = Formatting.format(discordAuthURL, ["(stringifiedParams)"], [stringifiedDiscordParams]);

            window.SubmitEmailClickPanel = function () {
                Mixpanel.trackByArgs(mixpanelEvents.continue_with_click, {});
                redirectWithLangFunc(configuration.paths.email_signup, navigate);
                MySwal.close();
            };

            window.joinWithGooglePopup = function () {
                document.getElementById("google-signup").click();
                MySwal.close();
            };

            let botLinkAddress = LinkCreator.createBotLink();
            let alertTitle = Content.getValue("join_with_alert_title");

            let swalHTML = '<div class="join-options-container">';

            swalHTML += '<a href="' + discordLoginUrl + ' "><div class="join-option-div" id="discord-join-option"><img src="' + Images.imageURL('most-popular-mark.png') + '" alt="Most popular" width="79" class="most-popular-image"/> <span class="most-popular-join-text">' + Content.getValue("most_popular_label")
                + '</span><img src="'
                + Images.imageURL('discord-join4x.png')
                + '" alt="Join via Discord" width="26" class="join-option-image"/><div><span class="join-option-text">'
                + Content.getValue("discord_join_alert_label") + '</span><span class="join-option-indicator">'
                + Content.getValue("instantly_join_the_community_alert_label") + '</span></div>'
                + `<svg class="success-svg" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowCircleRightIcon">
                    <path d="M22 12c0-5.52-4.48-10-10-10S2 6.48 2 12s4.48 10 10 10 10-4.48 10-10zm-10 1H8v-2h4V8l4 4-4 4v-3z">
                </path></svg>`
                + '</div></a>';
            /*
            swalHTML += '<a href="' + facebookLoginUrl + '"><div class= "join-option-div"><img src="' + Images.imageURL('facebook-join4x.png') + '" alt="Join via Facebook" width="26" class="join-option-image"/><span class="join-option-text">' + Content.getValue("facebook_join_alert_label") + '</span>'
                + `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.25" cx="10.5" cy="10.5" r="10" stroke="#282B54"/>
                </svg>`
                + '</div></a>';
            */
            swalHTML += '<div onClick=\'window.SubmitEmailClickPanel();\'><div class="join-option-div"> <img src="'
                + Images.imageURL('email-join4x.png')
            + '" alt="Join via email" width="26" class="join-option-image" /><span class="join-option-text">'
            + Content.getValue("email_join_alert_label") + '</span>'
            + `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.25" cx="10.5" cy="10.5" r="10" stroke="#282B54"/>
            </svg>`
            + '</div></div>';

            swalHTML += '<a href="' + botLinkAddress + ' "><div class="join-option-div" id="telegram-join-option"><img src="'
                + Images.imageURL('telegram-join4x.png')
            + '" alt="Join via Telegram" width="26" class="join-option-image"/><div><span class="join-option-text">'
            + Content.getValue("telegram_join_alert_label") + '</span></div>'
            + `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.25" cx="10.5" cy="10.5" r="10" stroke="#282B54"/>
            </svg>`
            + '</div></a>';

            swalHTML += '<div class="join-option-div" onClick=\'window.joinWithGooglePopup();\'><img src="'
                + Images.imageURL('google-join4x.png')
                + '" alt="Join via Google" width="26" class="join-option-image"/><span class="join-option-text">'
                + Content.getValue("google_join_alert_label") + '</span>'
                + `<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle opacity="0.25" cx="10.5" cy="10.5" r="10" stroke="#282B54"/>
                </svg>`
                + '</div>';
            swalHTML += ' <p class="register-content margin-top-45">' + Content.getValue("already_have_an_account")+' ';
            swalHTML += `<a class="signup-link dark-hover" href='${configuration.paths.login}'>` + Content.getValue("sign_in_cta") + '</a>';
            swalHTML += '</p>';

            swalHTML += '<p class="join-disclaimer">' + Formatting.format(Content.getValue("alert_join_consent"), ['(termsOfUseOptionUrl)', '(privacyPolicyOptionUrl)', '(cookiePolicyOptionUrl)'], [configuration.paths.terms_of_use, configuration.paths.privacy_policy, configuration.paths.cookie_policy]) + '</p>';
            swalHTML += '</div>';

            MySwal.fire({
                title: '<p class="swal-join-title">' + alertTitle + '</p>',
                html: swalHTML,
                background: '#FFFFFF',
                showCloseButton: true,
                showConfirmButton: false,
                customClass: {
                    closeButton: 'swal2-join-close'
                },
                didClose: () => {
                    Mixpanel.trackByArgs(mixpanelEvents.closed_join_popup, {});
                }
            });
        }
    }
}

export default Joining;