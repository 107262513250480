import React from 'react';
import Content from 'utils/Content.js';

const BackButton = ({ enabled, className, contentKey, children, onClick }) => {
    return (
        <button
            onClick={() => {
                onClick();
            }}
            disabled={!enabled}
            className={`${className ? className : 'button-back-bordered hidden-button'}`}
        >
            {children ? children : Content.getValue(contentKey ? contentKey : 'back')}
        </button>
    );
};

export default BackButton;
