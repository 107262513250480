import { forwardRef } from 'react';
import '../../design/inputs.css';

const TelInput = forwardRef((props, ref) => {

    const {
        label,
        direction, // row || column
        align, // start || center || end
        width,
        height,
        placeHolder,
        onChange,
        error,
        defaultValue
    } = props;

    const styles = {
        wrapper: {
            display: 'flex',
            flexDirection: direction || 'column',
            alignItems: align || 'start',
            maxWidth: width || 300,
            BorderColor: '#9A7AD8',
            gap: 5
        },
        input: {
            height: height || 40
        }
    }


    const onChangeHandler = (e) => {
        onChange && onChange(e.target.value);
    }

    return (
        <div className='form-input' style={styles.wrapper}>
            {label && (<label>{label}</label>)}

            <div className={`input-wrap ${error ? 'validation-error' : ''}`}>
                <input
                    type='tel'
                    onChange={onChangeHandler}
                    style={styles.input}
                    ref={ref}
                    placeholder={placeHolder}
                    defaultValue={defaultValue}
                />
                {error && (<div className='input-error-message'>{error}</div>)}
            </div>

        </div>
    )

})

TelInput.displayName = 'TelInput';

export default TelInput;