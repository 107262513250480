import Images from 'utils/Images';
import Content from 'utils/Content.js';
import 'design/gold.css';


const TreatcoinGoldBenefitsPopup = () => {

    return (
        <div className='gold-popup-container'>
            <div className='gold-ligth-bg'></div>
            <div className='gold-popup-header gold-shadow'>
                <div dangerouslySetInnerHTML={{ __html: Content.getValue("gold_popup_header") }}></div>
                <img width={81} height={91} src={Images.imageURL('tc-gold-inteiro.png')} alt="" />
            </div>
            <div className='gold-benefits-title'>{Content.getValue('gold_popup_title')}</div>
            <div className='gold-benefits-wrap'>
                <div className='gold-benefit gold-border'>
                    <div className='gold-benefit-lable'>{Content.getValue('gold_popup_benefit_1_title')}</div>
                    <div className='gold-benefit-desc'>{Content.getValue('gold_popup_benefit_1_description')}</div>
                </div>
                <div className='gold-benefit gold-border'>
                    <div className='gold-benefit-lable'>{Content.getValue('gold_popup_benefit_2_title')}</div>
                    <div className='gold-benefit-desc'>{Content.getValue('gold_popup_benefit_2_description')}</div>
                </div>
                <div className='gold-benefit gold-border'>
                    <div className='gold-benefit-lable'>{Content.getValue('gold_popup_benefit_3_title')}</div>
                    <div className='gold-benefit-desc'>{Content.getValue('gold_popup_benefit_3_description')}</div>
                </div>
                <div className='gold-benefit gold-border'>
                    <div className='gold-benefit-lable'>{Content.getValue('gold_popup_benefit_4_title')}</div>
                    <div className='gold-benefit-desc'>{Content.getValue('gold_popup_benefit_4_description')}</div>
                </div>
                <div className='gold-benefit gold-border'>
                    <div className='gold-benefit-lable'>{Content.getValue('gold_popup_benefit_5_title')}</div>
                    <div className='gold-benefit-desc'>{Content.getValue('gold_popup_benefit_5_description')}</div>
                </div>
                <div className='gold-benefit gold-border'>
                    <div className='gold-benefit-lable'>{Content.getValue('gold_popup_benefit_6_title')}</div>
                    <div className='gold-benefit-desc'>{Content.getValue('gold_popup_benefit_6_description')}</div>
                </div>

            </div>
        </div>
    )

}


export default TreatcoinGoldBenefitsPopup;