import ContentPageHeader from "components/sections/Content_Page_Header.js";
import Highscore from 'components/prizes/Highscore.js';
import Footer from 'components/sections/Footer.js';

import NewsTrack from "components/live_news/News_Track.js";
import Content from 'utils/Content.js';
import { useEffect } from "react";
import { Mixpanel } from "utils/User_Events_Util.js";

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

const Leaderboards = () => {
    useEffect(() => {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.leaderboards });
    }, []);


    let preferredLanguage = Content.getPreferredLanguage();

    return (
        <>
            <div id="main">

                <div id="content">

                    <ContentPageHeader />
                    <NewsTrack />
                    <section className="content-page-section">
                        <div>
                            <div className="title-container">
                                <h1 id="leaderboards-title" className="page-title">{Content.getValue('menu_title_leaderboards')}</h1>
                            </div>
                            <div className="page-container leaderboards-container">
                                <div className="leaderboards-description">
                                    {Content.getValue("contest_leaderboards_description")}
                                </div>
                                <div className="leaderboards-wrapper">
                                    <Highscore
                                        language={preferredLanguage}
                                        isLeaderboards={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Leaderboards;