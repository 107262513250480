import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

class Modal {
    
    static modal = MySwal;

    static close(){
        this.modal.close()
    }

    static open(component, props) {

        const popup = this.modal;

        const {onClose, onConfirm, onDecline, title, width, confirmButtonText, confirmButtonColor, hideCloseBtn} = props ?? {};

        popup.fire({
            html: component,
            title: title,
            background: '#FFF',
            showCloseButton: !hideCloseBtn,
            showCancelButton: onConfirm && true,
            showConfirmButton: onConfirm && true,
            width: width ?? 600,
            customClass: {
                popup: 'website-swal-modal',
            },
            confirmButtonText,
            confirmButtonColor
        }).then((result) => {
            if (result.isConfirmed) {
                onConfirm && onConfirm()
            } else{
                onDecline && onDecline()
            }
            onClose && onClose()
        })
    }
}

export default Modal;