import React, { Component } from 'react';
import Content from 'utils/Content.js';

class ConvertText extends Component {

    render() {
        return (
            <div dangerouslySetInnerHTML={{__html: Content.getValue("convert_to_competitions_static")}} />
        )
    }

}

export default ConvertText;