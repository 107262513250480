import React from 'react';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';

const configuration = require('configuration.json');
const payoutStatutes = configuration.payout_statuses;

class CoinPayout extends React.Component {
    constructor(props) {
        super(props);
        this.isHomepage = props.isHomepage;
        this.isEarning = props.isEarning;
        this.isMyPayoutsPage = props.isMyPayoutsPage;
        this.bgColor = props.bgColor;
        this.isConversion = props.isConversion;
        this.state ={
            amount: props.amount,
            targetAmount: props.targetAmount,
            USDAmount: props.USDAmount,
            currency: props.currency,
            status: props.status,
            transactionID: props.transactionID,
            platform: props.platform,
            date: props.date,
            time: props.time,
            isConversion: props.isConversion,
        };
    }

    render() {
        const { 
            amount,
            targetAmount,
            USDAmount,
            currency,
            status,
            transactionID,
            platform,
            date,
            time,
            isConversion
        } = this.state;

        const imageUrl = isConversion ?
            Images.imageURL("robot-delete-account-icon.png") :
            Images.imageURL('payouts/' + platform.toLowerCase() + '.png');
        const actionAmount = isConversion ?
            targetAmount :
            USDAmount;

        const bgColor = this.bgColor;
        const isMyPayoutsPage = this.isMyPayoutsPage;

        return (
            <>
            {
                isMyPayoutsPage ?
                (
                            <div className={`treatcoin-payout-entry-wrapper padding-x-25 max-w-full justify-between gap-10 desktop payout-page ${this.isHomepage ? "homepage desktop w-full homepage desktop-min-height-320" : ""} ${this.isEarning ? "earning" : ""} gradient-${bgColor} `} key={`_conversion-${date}-${amount}`}>
                        <div className="treatcoin-payout-entry-wrapper-part first desktop flex-50 max-w-half flex gap-20">
                            <div className="treatcoin-payout-entry-left-section desktop w-fit">
                                <img src={imageUrl} alt="Reward payout entry logo" className='payout-entry-logo' />
                            </div>
                            <div className="treatcoin-payout-entry-right-section flex-grow margin-left-20">
                                {
                                    isConversion ?
                                    <div className="treatcoin-payout-description conversion">{Formatting.format(Content.getValue("coin_conversion_entry"), ["(usdAmount)", "(treatCoins)"], [amount.toLocaleString(), actionAmount.toLocaleString()])}</div> :
                                    <div className="treatcoin-payout-description">{Formatting.format(Content.getValue("coin_payout_entry"), ["(currency)", "(usdAmount)", "(treatCoins)", "(platform)"], [currency, actionAmount.toFixed(2), Formatting.formatTreatCoinAmount(amount), platform])}</div>
                                }
                                <div className="treatcoin-payout-transaction-id margin-top-0">{Formatting.format(Content.getValue("payout_transaction_id"), ["(transactionID)"], [transactionID])}</div>
                                
                            </div>
                        </div>

                        <div className={`treatcoin-payout-entry-wrapper-part second desktop flex-50 max-w-half text-right gradient-${bgColor}`}>
                            <div className="treatcoin-payout-date-container flex-column items-end">
                                {
                                    !isConversion ?
                                    <span className={`treatcoin-payout-status w-fit payout-status-${status}`}>{Content.getValue("payout_statuses")[status.toString()]}</span> :
                                    null
                                }
                                <span className="treatcoin-payout-date">{(status !== payoutStatutes.pending && !isConversion) ? Content.getValue("at_label") : (null)} {date}, {time}</span>
                            </div>
                            {
                                (status === payoutStatutes.rejected) ? 
                                (
                                    <div className="treatcoins-returned-label  margin-top-0">{Formatting.format(Content.getValue("treatcoins_returned_to_balance"), ["(treatCoins)"], [Formatting.formatTreatCoinAmount(amount)])}</div>
                                ) : 
                                (null)
                            }
                        </div>
                    </div>
                ) :
                null
            }

            <div className={`treatcoin-payout-entry-wrapper mobile max-w-full gap-10 ${this.isHomepage ? "homepage desktop w-full homepage min-height-288 desktop-min-height-320" : ""} ${this.isEarning ? "earning" : ""} gradient-${bgColor} `} key={`_conversion-${date}-${amount}`}>
                <div className="treatcoin-payout-entry-left-section">
                    {
                        isConversion ?
                        <img src={imageUrl} width="140" height="110" alt="Reward payout entry logo" className="treatcoin-payout-conversion" /> :
                        <img src={imageUrl} width="62" height="67" alt="Reward payout entry logo" className="treatcoin-payout-entry-icon" />
                    }
                </div>
                <div className="treatcoin-payout-entry-right-section margin-left-20">
                    {
                        isConversion ?
                        <div className="treatcoin-payout-description conversion">{Formatting.format(Content.getValue("coin_conversion_entry"), ["(usdAmount)", "(treatCoins)"], [amount.toLocaleString(), actionAmount.toLocaleString()])}</div> :
                        <div className="treatcoin-payout-description">{Formatting.format(Content.getValue("coin_payout_entry"), ["(currency)", "(usdAmount)", "(treatCoins)", "(platform)"], [currency, actionAmount.toFixed(2), Formatting.formatTreatCoinAmount(amount), platform])}</div>
                    }
                    <div className="treatcoin-payout-transaction-id">{Formatting.format(Content.getValue("payout_transaction_id"), ["(transactionID)"], [transactionID])}</div>
                    <div className="treatcoin-payout-date-container">
                        {
                            !isConversion ?
                            <span className={`treatcoin-payout-status payout-status-${status}`}>{Content.getValue("payout_statuses")[status.toString()]}</span> :
                            null
                        }
                        <span className="treatcoin-payout-date">{(status !== payoutStatutes.pending && !isConversion) ? Content.getValue("at_label") : (null)} {date}, {time}</span>
                    </div>
                    {
                        (status === payoutStatutes.rejected) ? 
                        (
                            <div className="treatcoins-returned-label">{Formatting.format(Content.getValue("treatcoins_returned_to_balance"), ["(treatCoins)"], [Formatting.formatTreatCoinAmount(amount)])}</div>
                        ) : 
                        (null)
                    }
                </div>
            </div>
        </>
        )
    }
}

export default CoinPayout;