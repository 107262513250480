import React, { useState, useEffect, useRef } from 'react';
import * as queryString from 'query-string';
import Images from 'utils/Images.js';
import JoinTreatCoinButton from 'components/buttons/Join_TreatCoin_Button.js';
import ReferHomepageContainer from 'components/referrals/Refer_Homepage_Container.js';
import Highscore from "components/prizes/Highscore.js";
import CurrentRewards from "components/prizes/Current_Rewards.js";
import HowDoesItWorkSection from 'components/static/How_Does_It_Work_Section.js';
import ScrollToTop from 'components/widgets/Scroll_To_Top.js';
import RecommendedOfferDetails from 'components/top_12_offers/Recommended_Offer_Details.js';
import Footer from 'components/sections/Footer.js';
import TreatCoinsEqualsMoneySection from 'components/static/Treat_Coins_Equals_Money_Section';
import HotOfferLoaderHomepage from 'components/offers/Hot_Offer_Loader_Homepage.js';
import UserStatsHomepage from 'components/one_two_treat_area/User_Stats_Homepage.js';
import NewsTrack from 'components/live_news/News_Track.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import UserData from 'User_Data.js';
import Consent from 'utils/Consent.js';
import CompetitionInterval from 'utils/Competition_Interval.js';
import TopOfferHomepageList from 'components/top_12_offers/Top_Offer_Homepage_List';
import ContentPageHeader from 'components/sections/Content_Page_Header';
import AdditionalOffersContainer from 'components/offers/additional_offers/Additional_Offers_Container';
import ShowMore from 'components/wrappers/Show_More';
import ApiFacade from 'ApiFacade.js';
import JWTUtils from 'utils/JWT_Utils.js';
import Platform from 'utils/Platform.js';
import LinkCreator from "utils/Link_Creator.js";
import ActionButton from 'components/buttons/Action_Button.js';
import CookieModal from 'components/modals/Cookie_Modal.js';
import Join from 'components/widgets/Join/Join.js';
import GainCoinsByFunActivities from 'components/static/Gain_Coins_By_Fun_Act.js';
import { useLocation } from 'react-router-dom';
import TermsPopup from 'components/modals/Terms_Popup.js';

const configuration = require('configuration.json');
const globalConf = require('global_conf.json');

const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;
const emailSignupModes = configuration.email_signup_modes;

const discordConnection = globalConf.discord_connection;
const discordCommunityURL = discordConnection.community_url;

const userDataKeys = configuration.user_data_keys;

const Homepage = () => {
    const location = useLocation();
    const startDiscordAuth = () => {
        window.location.href = LinkCreator.createDiscordLink();
    };

    const setToDiscordToDiscordCommunityAfterAuth = () => {
        UserData.setItem(userDataKeys.redirect_to_discord_community_after_auth, true);
    };

    const isSetToRedirectToDiscordCommunity = () => {
        return (UserData.getItem(userDataKeys.redirect_to_discord_community) !== null);
    };

    const redirectToDiscordCommunity = () => {

        UserData.removeItem(userDataKeys.redirect_to_discord_community);
        window.location.href = discordCommunityURL;
        
    };

    const [openTermsPopup, setOpenTermsPopup] = useState(false);

    const emailSignup = UserData.getItem(userDataKeys.email_signup);
    const emailReg = UserData.getItem(userDataKeys.email_register);
    let initialEmailReg = null;
    let initialShowSignup = false;
    if (emailSignup === emailSignupModes.direct && !ApiFacade.isLoggedIn()) {
        if (emailReg) {
            initialEmailReg = emailReg;
        } else {
            initialShowSignup = true;
        }
    }

    const [regEmail, setRegEmail] = useState(initialEmailReg);
    const showSignUp = initialShowSignup;
    const [additionalOffers, setAdditionalOffers] = useState(null)
    const cookieRef = useRef();
    const cookieModalRef = useRef();

    const closeCookieDialogHandler = () => {
        cookieRef.current.style.display = "none";
    }

    const openCookieDialogue = () => {
        closeCookieDialogHandler();
        cookieModalRef.current.style.display = "block";
    }

    const acceptCookies = () => {
        const thirdPartySettings = {};
        thirdPartySettings[userDataKeys.third_party_cookies.mixpanel] = true;
        thirdPartySettings[userDataKeys.third_party_cookies.google_analytics] = true;
        UserData.setItem(userDataKeys.third_party_settings, JSON.stringify(thirdPartySettings));
        Consent.approveThirdParty();
        Mixpanel.trackByArgs(mixpanelEvents.cookies_click_accept, {});    
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { 
            page_name: window.location.pathname.includes(configuration.paths.welcome) ? pages.welcome : pages.homepage 
        });
        document.getElementsByClassName("cookie-popup-container")[0].style.display = 'none';
        document.getElementsByClassName("cookie-popup-overlay")[0].style.display = 'none';
    }

    const receiveAdditionalOffersHandler = async () => {
        //await Backend.receiveAdditionalOffers();

        let platform = Platform.getPlatformName();
        let currentCountry = JWTUtils.getJWTCountry();
        let preferredLanguage = null;

        if (!ApiFacade.isLoggedIn()){
            preferredLanguage = Content.getPreferredLanguage();
        }

        const req = ApiFacade.additionalOffers(platform, currentCountry, preferredLanguage);
        const res = await req;
        if (res.ok) {
            UserData.setItem(userDataKeys.additional_offers, JSON.stringify(res.response));
            setAdditionalOffers(
                JSON.parse(UserData.getItem(userDataKeys.additional_offers))
            )
        }
    }

    useEffect(() => {

        const urlParams = queryString.parse(window.location.search);

        const discord = urlParams.discord;
        const community = urlParams.community;

        if (community === '1') {
            setToDiscordToDiscordCommunityAfterAuth();
        }
        if (location.key === 'default' && document.referrer.indexOf('discord.com') !== -1 || discord === '1') {
            startDiscordAuth();
        }

        if (isSetToRedirectToDiscordCommunity()) {
            redirectToDiscordCommunity();
        }

        Mixpanel.trackByArgs(mixpanelEvents.page_view, { 
            page_name: window.location.pathname.includes(configuration.paths.welcome) ? pages.welcome : pages.homepage 
        });
        receiveAdditionalOffersHandler();
        let emailRefer = UserData.getItem(userDataKeys.email_refer);
        if (emailRefer === "1" && ApiFacade.isLoggedIn()) {
            setTimeout(function() {
                document.getElementById('one-two-treat-button').click();
            }, 300);
            UserData.removeItem(userDataKeys.email_refer);
        }

    }, []);

    let preferredLanguage = Content.getPreferredLanguage();
    let thirdPartySettings = JSON.parse(UserData.getItem(userDataKeys.third_party_settings));

    return (
        <>                                    
            {
            (thirdPartySettings === null) ? (<div className="cookie-popup-overlay" onClick={() => {
                acceptCookies();
            }}>
            </div>) : (null)
            }
            <div id="main">
                <div id="content">
                    <ContentPageHeader />
                    <main>
                        <div className="relative">
                            <NewsTrack />
                            {
                                (!ApiFacade.isLoggedIn()) ?
                                    (
                                        <>
                                            <section className="light-container intro-container">
                                                <picture className="homepage-robot-image">
                                                    <source media="(max-width:1023px)" srcSet={Images.imageURL('robot-mobile-background.png')} />
                                                    <source media="(min-width:1024px)" srcSet={Images.imageURL('robot-desktop-background.png')} />
                                                    <img src={Images.imageURL('robot-mobile-background.png')} alt="Homepage robot" width="375" className="homepage-robot-image" />
                                                </picture>
                                                <img
                                                    src={Images.imageURL('robot-anon-homepage4x.png')}
                                                    alt="Homepage robot"
                                                    className="homepage-robot-avatar"
                                                />
                                                {(regEmail || showSignUp) ?
                                                    <Join setRegEmail={setRegEmail} regEmail={regEmail} />
                                                    : <>
                                                        <div className={`join-homepage-head-content ${Platform.getPlatformName() === 'iOS' ? 'bottom-100-iOS' : ''}`}>
                                                            <h4 className="join-content-heading margin-bottom-20">
                                                                <span className='text-purple'>Treat</span>
                                                                <span className='text-pink'>Coin</span>
                                                            </h4>
                                                            <div dangerouslySetInnerHTML={{ __html: Content.getValue("intro_content_text") }}></div>
                                                            <JoinTreatCoinButton addClass={'margin-top-20 join-position-relative'} showTriangle={true} />
                                                        </div>
                                                        {(UserData.getItem(userDataKeys.email_signup) === emailSignupModes.join_button) ?
                                                            (null)
                                                            : <div onClick={() => document.getElementById('current-rewards-title').scrollIntoView({ behavior: 'smooth' })}>
                                                                <div>
                                                                    <img
                                                                        src={Images.imageURL('earn-coins-arrow.png')}
                                                                        alt="Earn Treat Coins"
                                                                        className="earn-coins-arrow"
                                                                        width={30}
                                                                        height={30}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }

                                            </section>
                                            {
                                            (thirdPartySettings === null) ? ( <>
                                            <div ref={cookieRef} className="cookie-popup-container">
                                                <img src={Images.imageURL('cookie.png')} height={40} width={40} />
                                                <p className='cookie-text'>{Content.getValue('cookie_text')}</p>
                                                <div className='d-flex-center-gap w-full'>
                                                    <button onClick={openCookieDialogue} className="hidden-button underlined-bold">{Content.getValue('choose_cookies')}</button>
                                                            <ActionButton
                                                        onClick={() => {
                                                            acceptCookies();
                                                        }}
                                                        classNames={'purple-light-button '} content={Content.getValue('accept')} />
                                                </div>
                                            </div>
                                            <CookieModal refer={cookieModalRef} /> 
                                            </>) : (null)
                                            }
                                        </>
                                    ) : (null)
                            }
                            <div className="home-div">
                            <TermsPopup setOpenTermsPopup={setOpenTermsPopup} openTermsPopup={openTermsPopup} />
                                <div className="page-container new homepage-container">
                                    {
                                        (ApiFacade.isLoggedIn()) ?
                                            (
                                                <div className="home-body-padding desktop">
                                                    <div className='bg-absolute' />
                                                    <div className=" join-home-bodydesktop">
                                                            <div className="home-body-container relative desktop">
                                                                <div className='home-body-header'>
                                                                    <UserStatsHomepage />
                                                                </div>
                                                            </div>

                                                        <div className='stats-earning-section desktop relative'>
                                                            <TopOfferHomepageList />

                                                            <div className="relative flex-50 desktop max-w-50-desktop">
                                                                <div className='robot-absolute desktop' />
                                                                <div className="">
                                                                    <HotOfferLoaderHomepage setOpenTermsPopup={setOpenTermsPopup}/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                            (
                                            <>
                                            <RecommendedOfferDetails />
                                            <TopOfferHomepageList  />
                                            </>)
                                    }
                                    {additionalOffers?.length && <AdditionalOffersContainer offersObj={additionalOffers[0]} />}
                                    <section id='home-second-section' className='home-second-section'>
                                        <TreatCoinsEqualsMoneySection />
                                        {
                                            (regEmail || showSignUp) ?
                                                <div className="padding-x-30">
                                                    <Join setRegEmail={setRegEmail} showSignUp={showSignUp} regEmail={regEmail} variant={'short'} />

                                                </div>
                                                : <JoinTreatCoinButton showTriangle={false} />
                                        }
                                        {additionalOffers?.length > 1 && <AdditionalOffersContainer offersObj={additionalOffers[1]}/>}
                                    </section>
                                    <HowDoesItWorkSection />
                                    {additionalOffers?.length > 2 && <AdditionalOffersContainer offersObj={additionalOffers[2]}/>}
                                    {additionalOffers?.length > 3 && <AdditionalOffersContainer offersObj={additionalOffers[3]}/>}
                                    <CurrentRewards />
                                    <ReferHomepageContainer />
                                    <section className="leaderboards-wrapper padding-bottom-20">
                                        <h2 id="leaderboards-subtitle">
                                            {CompetitionInterval() + ' ' + Content.getValue("monthly_leaderboard")}
                                        </h2>
                                        <ShowMore sectionName='howDowsItWork'>
                                        <Highscore
                                            isHomepage={true}
                                            language={preferredLanguage}
                                            isLeaderboards={false}
                                        />
                                        </ShowMore>
                                    </section>
                                    <div className='highscores-display-end-description'>{Content.getValue("highscores_display_end_description")}</div>
                                    {additionalOffers?.length > 4 && <AdditionalOffersContainer offersObj={additionalOffers[4]}/>}
                                    <GainCoinsByFunActivities />
                                    {/* <section>
                                    <>
                                        <h2 className="providers-homepage-title margin-bottom-10">{Content.getValue("homepage_cashback_title")}</h2>
                                            <ShowMore sectionName='providersHomepage'>
                                                <h2 className="providers-homepage-card-description text-center margin-top-0 padding-x-25 font-weight-medium">{
                                                    Content.getValue("homepage_cashback_subtitle")
                                                }</h2>
                                            </ShowMore>
                                            {additionalOffers?.length > 5 && <AdditionalOffersContainer offersObj={additionalOffers[5]} />}
                                    </>
                                    </section> */}
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <ScrollToTop containerClass="scroll-to-top-container" />
                <Footer />
            </div>
        </>
    );
};

export default Homepage;