import React from 'react';
import ContentPageLogo from "components/graphics/Content_Page_Logo.js";

class ContentPageHeader extends React.Component {

    render() {
        return <header className="account-access-page-header">
            <ContentPageLogo />
        </header>;

    }

}

export default ContentPageHeader;