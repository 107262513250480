import OpenScreenSelect from 'components/options/Open_Screen_Select.js';
import { useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Content from 'utils/Content.js';

const configuration = require('configuration.json');

const countryToPhonePrefix = configuration.country_to_phone_prefix;
const introSurveyInputTypes = configuration.intro_survey_input_types;

function QuizOptionWithLabel({ 
    type, 
    label, 
    data, 
    placeholder, 
    optional, 
    prefix,
    changeTextHandler,
    changePhonePrefixHandler
}) {
    const inputRef = useRef(null);
    useEffect(() => {
        if (type === introSurveyInputTypes.surname) {
            inputRef.current.focus(); // Set focus conditionally
        }
    }, [type]);

    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);

    if (type === introSurveyInputTypes.phone) {
        return (
            <div>
                <label className="quiz-option-label desktop">{ label }</label>
                <div className="quiz-option-phone-container">
                    <OpenScreenSelect
                        items={countryToPhonePrefix}
                        value={prefix}
                        optionChangeHandler={(value) => changePhonePrefixHandler(value)}
                    />
                    <div className='quiz-option-container min'>
                        <input 
                            type="tel"
                            placeholder={placeholder} 
                            value={data}
                            onChange={(evt) => changeTextHandler(evt.target.value)}
                            className="quiz-option-data-text desktop" 
                        />
                    </div>
                </div>
                { optional ? <div className='intro-survey-quiz-font desktop footertext open-screen'>{ optional }</div> : null }
            </div>
        )
    }

    return (
        <div>
            <label className="quiz-option-label desktop">{ label }</label>
            <div className='quiz-option-container min'>
                { 
                    (type === introSurveyInputTypes.birth_date) ?
                        (
                            <DatePicker
                                maxDate={maxDate}
                                size='sm'
                                className='quiz-option-data-text desktop'
                                placeholderText={Content.getValue("birth_placeholder")}
                                dateFormat='yyyy-MM-dd'
                                selected={data}
                                onChange={date => changeTextHandler(date) }
                            />
                        ) :
                        (
                            <input 
                                type={type === "bdate" ? "date" : "text"}
                                ref={inputRef}
                                placeholder={placeholder} 
                                value={data}
                                onChange={evt => changeTextHandler(evt.target.value)}
                                className="quiz-option-data-text desktop" 
                            />
                        )
                }
            </div>
            { optional ? <div className='intro-survey-quiz-font desktop footertext open-screen'>{ optional }</div> : null }
        </div>
    )
}

export default QuizOptionWithLabel;