import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Content from 'utils/Content.js';

const MySwal = withReactContent(Swal);

class Payouts extends React.Component {

    static showMyPayoutsHint() {

        let swalHTML = "<p class='swal-treat-coins-validity-text'>" + Content.getValue("my_payouts_hint") + "</p>";

        MySwal.fire({
            title: '<h1 class="swal-treat-coins-validity-title">' + Content.getValue("my_payouts_title") + '</h1>',
            html: swalHTML,
            background: '#141629',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("got_it_button_text"),
            customClass: {
                confirmButton: 'payment-method-hint-confirm-button',
                closeButton: 'swal2-payment-method-hint-close'
            }
        });
    }

}



export default Payouts;