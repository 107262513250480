import React from 'react';
import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
import Images from 'utils/Images.js';
import Formatting from 'utils/Formatting.js';
import Earnings from 'utils/Earnings.js';
import Payouts from 'utils/Payouts.js';
import CoinPayout from 'components/payouts/Coin_Payout.js';
import Content from 'utils/Content.js';

let thisComponent;

class CoinPayoutsList extends React.Component {
    constructor(props) {
        super(props);
        this.minutesOffset = new Date().getTimezoneOffset() * (-1);
        this.state = {
            error: null,
            isLoaded: false,
            isRestricted: false,
            coinsPayouts: null,
            lifetimeUsableCoinsEarned: null,
            coinsUsed: null,
            availableToUse: null,
            displayedItems: 5,
            totalItems: 5,
        };
        this.handleScroll = function () {

            let isScrolling;
            if (isScrolling !== null) window.clearTimeout(isScrolling);

            isScrolling = setTimeout(function () {
                let element = document.getElementById('scroll-to-top-button');
                let docViewTop = window.scrollY;
                let docViewBottom = docViewTop + window.innerHeight;

                let elemTop = element.offsetTop;
                let elemBottom = elemTop + element.offsetHeight;

                let scrollingTriggered = ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));

                if (scrollingTriggered) {
                    thisComponent.increaseDisplayedItems();
                }

            }, 50);

        };
        thisComponent = this;
    }

    increaseDisplayedItems() {

        const { displayedItems, totalItems } = this.state;

        let extraElements = 20;

        thisComponent.setState({
            displayedItems: Math.min(displayedItems + extraElements, totalItems)
        });
    }

    componentDidMount() {
        this.fetchPayoutsHistory();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
    }

    fetchPayoutsHistory() {
        const req = ApiFacade.myPayoutHistory(this.minutesOffset);
        req.then(function (res) {
            if (res.ok){
                let result = res.response;
                thisComponent.setState({
                    isLoaded: true,
                    coinsPayouts: result.coinsPayouts,
                    lifetimeUsableCoinsEarned: result.lifetimeUsableCoinsEarned,
                    availableToUse: result.availableToUse,
                    coinsUsed: result.coinsUsed,
                    totalItems: result.coinsPayouts.length
                });
            } else if (res.unauthorized){
                thisComponent.setState({
                    isLoaded: true,
                    isRestricted: true
                });
            } else {
                thisComponent.setState({
                    isLoaded: true,
                    error: res.error
                });
            }
        },
            (error) => {
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
    }

    render() {
        const { error, isLoaded, isRestricted, coinsPayouts, lifetimeUsableCoinsEarned, availableToUse, coinsUsed, displayedItems } = this.state;

        if (error) {
            return <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>;
        } else if (isRestricted) {
            return <div className="error-message">{Backend.loadBackendMessages().restrictedMessage}</div>;
        }
        else if (!isLoaded) {
            return <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>;
        } else {
            window.addEventListener('scroll', this.handleScroll, false);

            return (
                <div className="coins-history-section">
                    <div className="coins-div desktop">
                        <div className="month-total-container payout mobile">
                            <button onClick={() => Earnings.showTreatCoinsValidityHint()} className="button-hidden">
                                <img className="treat-coins-validity-hint-icon" src={Images.imageURL('earnings/info-icon.png')} alt="Treat Coins validity hint" height="20" width="auto" />
                            </button>
                            <div className="lifetime-usable-treat-coins-label">{Content.getValue("lifetime_usable_treat_coins_earned_label")}</div>
                            <div className="lifetime-usable-treat-coins-container">
                                <div className="monthly-treat-coins-amount-number">{Formatting.formatTreatCoinAmount(lifetimeUsableCoinsEarned)}</div>
                                <div className="monthly-treat-coins-amount-tc">{Content.getValue("treat_coins")}</div>
                            </div>
                            <div className="monthly-treat-coins-breakdown-container dashed-top">
                                <div className="monthly-treat-coins-breakdown-item">
                                    <div className="treatcoins-breakdown-title">{Content.getValue("treat_coins_used")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item">
                                    <div className="treatcoins-breakdown-title">{Content.getValue("treat_coins_available_to_use")}</div>
                                </div>
                            </div>
                            <div className="monthly-treat-coins-breakdown-container">
                                <div className="monthly-treat-coins-breakdown-item">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(coinsUsed)}</div>
                                    <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-item">
                                    <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(availableToUse)}</div>
                                    <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                </div>
                            </div>
                        </div>

                        <div className="month-total-container payout desktop gap-30">
                            <div className="month-total-container-left card padding-top-30">
                                <button onClick={() => Earnings.showTreatCoinsValidityHint()} className="button-hidden">
                                    <img className="treat-coins-validity-hint-icon" src={Images.imageURL('earnings/info-icon.png')} alt="Treat Coins validity hint" height="20" width="auto" />
                                </button>
                                <div className="lifetime-usable-treat-coins-label">{Content.getValue("lifetime_usable_treat_coins_earned_label")}</div>
                                <div className="lifetime-usable-treat-coins-container">
                                    <div className="monthly-treat-coins-amount-number">{Formatting.formatTreatCoinAmount(lifetimeUsableCoinsEarned)}</div>
                                    <div className="monthly-treat-coins-amount-tc">{Content.getValue("treat_coins")}</div>
                                </div>
                            </div>

                            <div className="month-total-container-right card">
                                <div className="monthly-treat-coins-breakdown-container flex items-start desktop gap-40 w-full">
                                    <div className="lifetime-usable-treat-coins-label desktop flex-50 padding-0">
                                        <div className="lifetime-usable-treat-coins-label padding-0">{Content.getValue("treat_coins_used")}</div>
                                    </div>
                                    <div className="monthly-treat-coins-breakdown-item desktop flex-50 padding-0">
                                        <div className="lifetime-usable-treat-coins-label padding-0">{Content.getValue("treat_coins_available_to_use")}</div>
                                    </div>
                                </div>
                                <div className="monthly-treat-coins-breakdown-container flex desktop gap-40 w-full">
                                    <div className="monthly-treat-coins-breakdown-item desktop flex-50">
                                        <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(coinsUsed)}</div>
                                        <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                    </div>
                                    <div className="monthly-treat-coins-breakdown-item desktop flex-50">
                                        <div className="treatcoins-breakdown-amount">{Formatting.formatTreatCoinAmount(availableToUse)}</div>
                                        <div className="treatcoins-breakdown-label">{Content.getValue("treat_coins")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button className="my-payouts-label button-hidden margin-auto w-fit" onClick={() => Payouts.showMyPayoutsHint()}>{Content.getValue("my_payouts_label")} </button>
                    <div>
                        {coinsPayouts.slice(0, displayedItems).map((item) => (
                            <CoinPayout
                                key={`payout_${item.transactionID}`}
                                isMyPayoutsPage={true}
                                amount={item.amount}
                                targetAmount={item.targetAmount}
                                USDAmount={item.USDAmount}
                                currency={item.currency}
                                status={item.status}
                                transactionID={item.transactionID}
                                platform={item.platform}
                                date={item.date}
                                time={item.time}
                                isConversion={item.isConversion}
                            />
                        ))}
                    </div>
                </div>

            );
        }
    }

}

export default CoinPayoutsList;