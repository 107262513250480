import React from 'react';

import Backend from 'Backend.js';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';
import { Checkbox } from '@mui/material';
import UserData from 'User_Data.js';
import Redirects from 'utils/Redirects.js';

const globalConf = require('global_conf.json');
const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const cookies = configuration.cookies;
const queryVariables = configuration.query_variables;
const loginTypes = configuration.login_types;

const captchaConnection = globalConf.captcha_connection;

const captchaSiteKey = captchaConnection.site_key;

let thisComponent;

class StartYourJourneyForm extends React.Component {

    webviewClose() {
        window.close();
    }

   
    getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            btnDisabled: true,
            checkboxDisabled: false,
            checkboxChecked: false
        };
        thisComponent = this;
    }

    render() {
        const { navigate } = this.props;
        const { btnDisabled, checkboxDisabled, checkboxChecked } = this.state;
        return (
            <>
                <div className="mail-title-container start-now">
                    <img
                        className='start-now-robot'
                        src={Images.imageURL('robot-start-your-journey.png')} alt="robot" width={170} height={131} />
                    <h3 className='start-now-title'>
                        {Content.getValue('start_your_journey_title_1')}
                        <span className='text-purple'>
                            {Content.getValue('start_your_journey_title_2')}
                        </span>
                        <span className='text-pink'>
                            {Content.getValue('start_your_journey_title_3')}
                        </span>
                    </h3>
                    <div className="start-now-checkbox">
                        <Checkbox
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                            disabled={checkboxDisabled}
                            checked={checkboxChecked}
                            onClick={() => {
                                thisComponent.setState({ error: null, checkboxDisabled: true });
                                this.processRegistration();
                            }}
                        />
                        <p className='start-now-checkbox_desc'>{Content.getValue('start_your_checkbox')}</p>
                    </div>
                    
                    <button
                        disabled={btnDisabled}
                        id="submit-verification-button" className="register-button verification"
                        onClick={() => {
                            Redirects.makeRedirectAfterLogin(false, navigate);
                        }}
                    >
                        {Content.getValue('start_now_button')}
                    </button>
                    
                    <p className='start-now_desc '>{Content.getValue('above_18_yo')}</p>
                </div>
            </>
        );
    }

    removeCaptcha() {

        let captchaScript = document.getElementById('google-captcha');
        if (captchaScript) {
            captchaScript.remove();
        }

        const nodeBadge = document.querySelector('.grecaptcha-badge');
        if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
        }

    }

    componentWillUnmount() {
       this.removeCaptcha();
    }

    sendRequest(url, body) {
        const { navigate } = this.props;

        const verificationToken = this.getQueryVariable(queryVariables.verification_token);
        fetch(url, {
            credentials: "include",
            method: Backend.backendMethods.post,
            headers: Backend.generateVerificationHeader(verificationToken),
            body: JSON.stringify(body)
        })
            .then(async function (res) {
                if (res.status === Backend.backendResponseCodes.internalServerErrorCode || res.status === Backend.backendResponseCodes.serviceUnavailableCode) {
                    thisComponent.setState({
                        error: Backend.loadBackendMessages().errorMessage,
                    });
                    setTimeout(function () {
                        thisComponent.setState(prev => ({ ...prev, error: null }));
                    },
                        3000);
                    return;
                }
                let result = await res.json();
                if (res.ok) {
                    await Backend.setUserSessionData(result, loginTypes.telegram, true, navigate);
                    thisComponent.setState({ error: null, btnDisabled: false, checkboxChecked: true });
                } else {
                    thisComponent.setState({ error: null, btnDisabled: true });
                }


            });
    }

    processRegistration() {

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(captchaSiteKey, { action: 'submit' }).then(function (token) {
                const startRegistration = function() {
                    const gclient_id = UserData.getItem(userDataKeys.gclient_id);
                    const fbclid = UserData.getCookie(cookies.fbc) || UserData.getItem(userDataKeys.fbclid);
                    const fbp = UserData.getCookie(cookies.fbp);
                    const postBody = { 
                        captchaToken: token, 
                        gclient_id: gclient_id, 
                        fbclid: fbclid,
                        fbp: fbp
                    };
                    thisComponent.sendRequest(Backend.backendURLs.verifyBotURL, postBody);
                };
                Backend.startAfterAnalyticsInitialized(startRegistration);

            });
        });

    }
}

export default StartYourJourneyForm;