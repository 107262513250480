import React from 'react';
import { useState } from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';

function ConfirmModal(
    {
        initiateWithdrawal,
        setConfirmModal
    }
) {
    const [checked, setChecked] = useState(false);

    const handleContinueBtn = async (e) => {
        e.stopPropagation();
        await initiateWithdrawal();
    };

    return (
        <div className='fixed-popup-wrapper'
        >
            <button className="button-hidden close-button"
                onClick={(e) => {
                    e.stopPropagation();
                    setConfirmModal(false);
                }}
            >
                <img width={24} height={24} src={Images.imageURL('close-welcome.png')} alt="Close" />
            </button>
            <div className="withdrawal-confirm-body">
                <img src={Images.imageURL(`withdraw-popup-1.png`)} className='withdrawal-confirm-image' /> <>
                    <p className="withdrawal-confirm-desc">
                        {Content.getValue(`withdrawal_desc_${1}`)}
                    </p>
                    <div className="withdrawal-confirm-check-wrapper">
                        <label className="withdrawal-confirm-check">
                            <input
                                className='withdrawal-confirm-input'
                                type="checkbox"
                                checked={checked}
                                onChange={(e) => {
                                    e.stopPropagation();
                                    setChecked(prev => !prev);
                                }}
                            />
                        </label>
                        <p className="withdrawal-confirm-agreement">{Content.getValue('withdrawal_agreement')}</p>
                    </div>
                </>
                <button className={`purple-button ${checked ? "" : "disabled-btn"}`}
                    disabled={!checked}
                    onClick={handleContinueBtn}
                >
                    {Content.getValue('continue_button_text')}
                </button>
            </div>
        </div>
    );
}

export default ConfirmModal;