import React from 'react';

import Content from 'utils/Content.js';

class NextButton extends React.Component {
    
    render() {
        const enabled = this.props.enabled;
        return (
            <button 
                disabled={!enabled}
                className={`intro-survey-quiz-next-button purple ${enabled ? '' : 'opacity'}`}
            >
                { Content.getValue("next") }
            </button>
        );
    }

}

export default NextButton;