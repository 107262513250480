import { useLocation } from 'react-router-dom';
import Organization from "components/seo/Organization.js";
import Breadcrumb from "components/seo/Breadcrumb.js";
import MetaTags from 'components/seo/Meta_Tags.js';
import Content from 'utils/Content.js';


const configuration = require('configuration.json');

const MainLayout = props => {

    const { component, contentKey } = props;

    const location = useLocation();

    const content_meta_title = Content.getValue(`meta_title_${contentKey}`);
    const content_meta_description = Content.getValue(`meta_description_${contentKey}`);
    const content_meta_path = configuration.paths[contentKey] ?? location.pathname;

    return (
        <>
            <MetaTags
                title={content_meta_title}
                description={content_meta_description}
                url={content_meta_path}
            />
            <Organization />
            <Breadcrumb
                title={content_meta_title}
                path={content_meta_path}
            />
            {component}
        </>
    )
}

export default MainLayout;