import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Images from 'utils/Images.js';

import Content from 'utils/Content.js';
import { redirectWithLangFunc } from './RedirectWithLang';

const MySwal = withReactContent(Swal);

class Survey {

    static displaySurveyAlreadyCompleted(navigate) {
       
        MySwal.fire({
            title: '<p>' + Content.getValue("intro_survey_already_completed_title") + '</p>',
            html: "<p class='swal2-content'>" + Content.getValue("intro_survey_already_completed_message") + "</p>",
            iconHtml: '<img alt="TreatCoin robot" class="swal2-robot-icon" src="' + Images.imageURL('robot-icon.png') + '"/>',
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            confirmButtonText: Content.getValue("got_it_button_text"),
            customClass: {
                confirmButton: 'standard-confirm-button',
                cancelButton: 'standard-cancel-button',
            }
        });

        setTimeout(function () {
            redirectWithLangFunc(configuration.paths.playground, navigate);
        }, 3000);
    }

}

export default Survey;