import React from 'react';
import Images from 'utils/Images.js';
import ApiFacade from 'ApiFacade.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;

let thisComponent;

class OfferCompletionDisclaimer extends React.Component {

    constructor(props) {
        super(props);
        //this.markURL = markCoinsDisclaimerDisplayedURL;
        thisComponent = this;
    }

    render() {
        return <div className="offer-completion-disclaimer-container hidden" id="offer-completion-disclaimer-container">
            <img className="coins-disclaimer-alert" alt="Coins disclaimer alert" width="33" height="33" src={Images.imageURL('coins-disclaimer-alert.png')} />
            <img className="coins-disclaimer-close" alt="Coins disclaimer close" width="14" height="14" src={Images.imageURL('coins-disclaimer-close.png')} onClick={thisComponent.closeCoinsDisclaimer} />
            <div className="offer-completion-disclaimer-text">
                {Content.getValue("offer_completion_disclaimer_text")}
            </div>
        </div>;
    }

    closeCoinsDisclaimer() {
        thisComponent.markCoinsDisclaimerDisplayed();
        document.getElementById('offer-completion-disclaimer-container').parentNode.removeChild(document.getElementById('offer-completion-disclaimer-container'));
    }

    markCoinsDisclaimerDisplayed() {
        const req = ApiFacade.markCoinsDisclaimerDisplayed();
        req.then(function (res) {
            if (res.ok){
                UserData.setItem(userDataKeys.offer_completion_disclaimer_displayed, true);
            } else {
                ///????
            }
        });
    }


}

export default OfferCompletionDisclaimer;