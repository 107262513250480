import Images from 'utils/Images';
import Joining from 'utils/Joining';
import CompletedLayout from 'components/offers/Completed_Layout';
import { useEffect } from 'react';
import { OfferPopupContext } from 'context/Offer_Popup_Context.js';
import { useContext } from 'react';
import Content from 'utils/Content.js';
import { useNavigate } from 'react-router-dom';
import Formatting from 'utils/Formatting.js';

const configuration = require("configuration.json");

const offerMainType = configuration.offer_main_type;

function TopOfferHomepageCard(offer) {

  const navigate = useNavigate();
  const { handleOfferIsOpened } = useContext(OfferPopupContext);

  const getImageDimensions = (url) => {
    const img = new Image();
    img.src = url;


    /*if (img.width > 199) {
      setImageStyle({
        backgroundSize: 'cover'
      });
    } else if (img.width < 180 && img.width / img.height === 1) {
      setImageStyle({
        backgroundSize: 'auto',
      });
    } */
  };
  const {
    uniqueID,
    imageURL,
    coins,
    treatCoinValue,
    authorized,
    isCompleted,
    CategoryImageURL,
    CategoryHPID,
    MainTitle,
    reviewSubmitted,
    reviewAllowed,
    reviewRequired,
    isGold,
    isPercentage,
  } = offer.offer;

  useEffect(() => {
    getImageDimensions(imageURL);
  }, [imageURL])
  return (
    <li key={uniqueID} className={`top-offer-homepage-card ${isGold ? "gold-box-shadow" : ""}`}>
      {isGold && (
          <span className='gold-offer-badge-wrap'>
            <span className='gold-offer-badge'>{Content.getValue('gold_offer_badge')}</span>
          </span>
      )}
      <CompletedLayout
        isPercentage={isPercentage}
        isHomepage
        coins={coins} treatCoinValue={treatCoinValue} isCompleted={isCompleted}
        reviewData={{
          reviewSubmitted: Boolean(reviewSubmitted),
          reviewAllowed: Boolean(reviewAllowed),
          reviewRequired: Boolean(reviewRequired),
        }}
      />
      <button className='hidden-button flex-column justify-between' onClick={() => {
        if (!authorized) {
          return Joining.showPopup(navigate);
        } else if(!isCompleted){
          handleOfferIsOpened({offer: offer?.offer})
        }
      }}>
      <div className="top-offer-homepage-first-part">
          {CategoryImageURL && CategoryHPID && !isGold && (
              <div className="top-offer-homepage-category">
                  <img className='top-offer-homepage-category-img' src={Images.imageURL(CategoryImageURL)} alt="Category" />
                  <p className="top-offer-homepage-category-desc">{Content.getValue(`additional_offers_heading_${offerMainType[CategoryHPID]}`)}</p>
              </div>
          )}
          <div
            style={{
              backgroundImage: `url(${imageURL})`,
            }}
            className='top-offer-homepage-image '
            alt="Top-Offer" >
          </div>
          {/* <h4 className='top-offer-homepage-app-name'>{topHeading}</h4> */}
          <p className='top-offer-homepage-desc'>{MainTitle}</p>
      </div>
      {isGold
        ? (<button className='gold-btn'>{Content.getValue(`gold_offer_cta`)}</button>)
        : (<div>
              <div className="top-offer-homepage-payout">
                <img width={20} height={20} src={Images.imageURL('offers/stats-header-money-icon-new.png')} alt="TreatCoin" className="top-offer-homepage-treat-coin" />
              <p className="top-offer-homepage-tc-value">{coins.toLocaleString()}</p>
                <div className='top-offer-homepage-usd-value-container'>
                  <p className="top-offer-homepage-usd-value text-12-i">=  {Formatting.currencySymbol()}{(coins * treatCoinValue).toFixed(2)}</p>
              <p className="top-offer-homepage-usd-desc">{Formatting.currencyLabel()}</p>
                </div>
              </div>
              <p className="top-offer-homepage-tc-desc">Treat Coins</p>
          </div>)
      }
      
      </button>
    </li>
  );
}

export default TopOfferHomepageCard;