class UserData {

    static setItemWithValueCheck(key, value) {
        if (value != null && 
            value !== 'null' && 
            value !== 'undefined') {
                UserData.setItem(key, value);
        }
    }
    static setItem(key, value) {
        localStorage.setItem(key, value);
    }

    static getItem(key) {
        return localStorage.getItem(key);
    }

    static removeItem(key) {
        localStorage.removeItem(key);
    }

    static clear() {
        localStorage.clear();
    }

    static getCookie(name) {
        name = encodeURIComponent(name) + "=";
        const cookieArray = document.cookie.split(';');
        for(let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i];
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return decodeURIComponent(cookie.substring(name.length, cookie.length));
            }
        }
        return null;
    }

}

export default UserData;