import React from 'react';
import Faq from "react-faq-component";
import UserData from 'User_Data.js';
import Alerts from 'utils/Alerts.js';
import Content from 'utils/Content.js';

import Backend from 'Backend.js';
import ApiFacade from 'ApiFacade.js';
const configuration = require('configuration.json');

const loginTypes = configuration.login_types;
const userDataKeys = configuration.user_data_keys;

const FAQStyles = {
    rowTitleColor: '#FFFFFF',
    rowContentColor: '#FFFFFF',
    rowContentTextSize: '14px',
    arrowColor: "#5FC88F",
};

const FAQStylesParent = {
    rowTitleColor: '#FFFFFF',
    rowContentColor: '#FFFFFF',
    rowContentTextSize: '14px',
    arrowColor: "#5FC88F",
}

let thisComponent;

class FAQList extends React.Component {
    constructor(props) {
        super(props);
        this.preferredLanguage = Content.getPreferredLanguage();
        this.state = {
            error: null,
            isLoaded: false,
            items: []
        };
        thisComponent = this;
    }

    componentDidMount() {
        let req = ApiFacade.faq(this.preferredLanguage);
        req.then(
                function (res) {
                    if (res.ok){
                        let result = res.response;
                        thisComponent.setState({
                            isLoaded: true,
                            items: result.FAQ
                        });
                    } else{
                        thisComponent.setState({
                            isLoaded: true,
                            error: res.error
                        });
                    }
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div className="error-message">{Backend.loadBackendMessages().errorMessage}</div>;
        } else if (!isLoaded) {
            return <div className="loading-message">{Backend.loadBackendMessages().loadingMessage}</div>;
        } else {
            setTimeout(function () {
                let faqContactUsLink = document.getElementById('faq-contact-us-link');
                if (faqContactUsLink !== null && UserData.getItem(userDataKeys.login_type) === loginTypes.telegram) faqContactUsLink.onclick = function () { Alerts.showContactUsAlert(); };
            }, 1000);
           
            let FAQData = { rows: [] };
            for (let i = 0, len = items.length; i < len; i++) {
                let item = items[i];
                FAQData.rows.push({title: (<div className="faq-subject"><span className="green-text faq-order">{i+1}.</span><span>{item.title}</span></div>), 
                    content:  
                (<div className="faq-style-wrapper">
                    <Faq
                    data={{rows: item.rows }}
                    styles={FAQStyles}
                />
                </div>) });
            }
            return (
            <Faq
            data={FAQData}
            styles={FAQStylesParent}
            />
            );
        }
    }
}

export default FAQList;