import React from 'react';
import Images from 'utils/Images';

function LinkButton({ onClick, imageRoute, text, disabled }) {
    return (
        <button disabled={disabled} onClick={() => onClick()} className={`hidden-button link-button ${disabled ? 'disabled-btn' : ''}`}>
            <img src={Images.imageURL(imageRoute)} alt="Link Button" className='link-button-image' />
            <p className="link-button-text">{text}</p>
        </button>
    );
}

export default LinkButton;