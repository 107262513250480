import React from 'react';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';
import UserData from 'User_Data.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';


const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;

class CoinEarning extends React.Component {

    constructor(props) {
        super(props);
        this.isHomepage = props.isHomepage;
        this.isMyEarningsPage = props.isMyEarningsPage;
        this.coinsEarningAvailabilityID = props.coinsEarningAvailabilityID;
        this.providerName = props.providerName;
        this.logoURL = props.logoURL;
        this.description = props.description;
        this.referral = props.referral;
        this.referralType = props.referralType;
        this.amount = props.amount;
        this.USDAmount = props.USDAmount;
        this.date = props.date;
        this.time = props.time;
        this.transactionID = props.transactionID;
        this.referralEarningID = props.referralEarningID;
        this.coinsEarningValidity = props.coinsEarningValidity;
        this.moreOffersNumber = props.moreOffersNumber;
        this.appName = props.appName;
        this.position = props.position;
        this.bgColor = props.bgColor;
        this.isConversion = props.isConversion;
        this.isGold = props.isGold;
        this.AssetCollection = props.AssetCollection;
        this.AssetName = props.AssetName;
        this.AssetSerialNumber = props.AssetSerialNumber;
        this.AssetPrice = props.AssetPrice;
        this.InvoiceUniqueNumber = props.InvoiceUniqueNumber;
    }

    render() {
        const signObject = {
            "1": this.amount >= 0.00 ? "+" : "",
            "2": this.amount >= 0.00 ? "+" : "",
            "3": "-->",
            "4": this.amount >= 0.00 ? "+" : "",
            "5": "",
        }
        const coinSign = signObject[this.coinsEarningAvailabilityID];
        let coinsEarningDescription;
        if (this.referralEarningID !== null) {
            if (this.referral === null) {
                coinsEarningDescription = Formatting.format(Content.getValue("referral_completed_offer_inactive"), ["(offerDescription)"], [this.description]);
            }
            else {
                coinsEarningDescription = Formatting.format(Content.getValue("referral_completed_offer"), ["(referral)", "(offerDescription)"], [this.referral, this.description]);
            }
        }
        else coinsEarningDescription = this.description;

        const bgColor = this.bgColor;
        const isMyEarningsPage = this.isMyEarningsPage;

        return (
            <>
                {
                    isMyEarningsPage ?
                    (
                        <>
                                <div className={`earning-entry-wrapper  max-w-full desktop earning-page relative bg-white ${!this.isConversion ? `gradient-${bgColor}` : ''} `} key={Math.floor(Math.random() * 10000)}>
                                    <div className={`max-w-full gap-10 desktop flex justify-between padding-top-10`}>
                                        <div className="my-earnings earning-entry-wrapper-part first flex-50 items-center max-w-half">
                                            <div className="earning-entry-left-section desktop w-fit max-w-half">
                                                <img src={this.logoURL} alt="Provider logo" className='earning-entry-logo' width={70} height={70} />
                                            </div>
                                            <div className="earning-entry-left-section flex-50">
                                                {this.isGold ?
                                                    <>
                                                        <p className="coins-earning-description">
                                                            {Content.getValue('my_earnings_asset_name')}
                                                            {this.AssetName}
                                                        </p>
                                                        <p className="coins-earning-description">
                                                            {Content.getValue('my_earnings_unique_id')}
                                                            {this.transactionID}
                                                        </p>
                                                        <p className="coins-earning-description">
                                                            {Content.getValue('my_earnings_invoice_unique_number')}
                                                            {this.InvoiceUniqueNumber}
                                                        </p>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="coins-earning-unique-id desktop">{Formatting.format(Content.getValue("earning_unique_id"), ["(uniqueID)"], [this.transactionID])}</div>
                                                        {(this.referralEarningID !== null) ?
                                                            (<div className="coins-earning-unique-id">{Formatting.format(Content.getValue("referral_earning_unique_id"), ["(uniqueID)"], [this.referralEarningID])}</div>) : (null)

                                                        }
                                                        <div className="coins-earning-description desktop">{coinsEarningDescription} </div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    <div className="my-earnings earning-entry-wrapper-part second desktop flex-50 items-center max-w-half">
                                        <div className="earning-entry-right-section desktop flex-50 max-w-half">
                                            <div className="coins-earning-time text-right">{this.date}<br />{this.time}</div>
                                        </div>
                                            <div className="coins-earning-amount desktop flex-50 max-w-half">
                                                {coinSign}
                                                {" "}
                                                {Formatting.formatTreatCoinAmount(this.amount)}
                                                {" "}
                                                {Content.getValue("treat_coins")}
                                                {
                                                    (this.position !== null) ?
                                                        (<div className="coins-earning-worth">{Formatting.format(Content.getValue("worth_earning_amount"), ["(amount)"], [Formatting.formatUSDAmount(this.USDAmount, true)])}</div>) :
                                                        (null)
                                                }
                                                {this.isGold ?
                                                    <>
                                                        <div className="coins-earning-validity">{Formatting.format(Content.getValue("my_earnings_asset_price"), ['[price]'], [this.AssetPrice])}</div>
                                                        <div className="coins-earning-validity">{Formatting.format(Content.getValue("my_earnings_asset_collection"), ['[asset_collection]'], [this.AssetCollection])}</div>
                                                    </>
                                                    :
                                                    <div className="coins-earning-validity">({Content.getValue("earning_availabilities")[this.coinsEarningAvailabilityID.toString()]})</div>
                                                }
                                            </div>
                                    </div>
            
                                </div>
            
                                <div>
                                    {(this.moreOffersNumber > 0) ? (
                                            <RedirectWithLang
                                                props={{
                                                    className: "more-offers-link desktop",
                                                    onClick: () => UserData.setItem(userDataKeys.open_earning_list, true)
                                                }}
                                                to={`${configuration.paths.playground}#${this.appName.replaceAll("'", "").replaceAll("+", "").replaceAll(":", "_").replaceAll(" ", "_").replaceAll(".", "_").replace("__", "_").toLowerCase()}`}
                                                text={Formatting.format(Content.getValue("more_offers_earning"), ["(moreOffersNumber)"], [this.moreOffersNumber])}
                                            >
                                            </RedirectWithLang>
                                    ): (null)}
                                </div>
                            </div>
                        </>
                    ) : 
                    null
                }

                <div className={`earning-entry-wrapper mobile max-w-full gap-10 ${this.isHomepage ? "homepage desktop w-full desktop-min-height-320" : ""} relative bg-white ${!this.isConversion ? `gradient-${bgColor}` : ''}  `} key={Math.floor(Math.random() * 10000)}>
                    <div className="earning-entry-wrapper-part first desktop">
                        <div className="earning-entry-left-section desktop">
                            <img src={this.logoURL} alt="Provider logo" className='earning-entry-logo' />
                        </div>
                        <div className="coins-earning-amount desktop">
                            {coinSign}
                            {" "}
                            {Formatting.formatTreatCoinAmount(this.amount)} 
                            {" "}
                            {Content.getValue("treat_coins")}
                            {
                                (this.position !== null) ?
                                    (<div className="coins-earning-worth">{Formatting.format(Content.getValue("worth_earning_amount") ,["(amount)"], [Formatting.formatUSDAmount(this.USDAmount, true)])}</div>) :
                                    (null)
                            }
                            {this.isGold ?
                                <>
                                    <div className="coins-earning-validity">{Formatting.format(Content.getValue("my_earnings_asset_price"), ['[price]'], [this.AssetPrice])}</div>
                                    <div className="coins-earning-validity">{Formatting.format(Content.getValue("my_earnings_asset_collection"), ['[asset_collection]'], [this.AssetCollection])}</div>
                                </>
                                :
                                <div className="coins-earning-validity">({Content.getValue("earning_availabilities")[this.coinsEarningAvailabilityID.toString()]})</div>
                            }
                        </div>
                    </div>

                    <div className="earning-entry-wrapper-part second desktop">
                        <div className="earning-entry-left-section desktop">
                            {this.isGold ?
                                <>
                                    <p className="coins-earning-description">
                                        {Content.getValue('my_earnings_asset_name')}
                                        {this.AssetName}
                                    </p>
                                    <p className="coins-earning-description">
                                        {Content.getValue('my_earnings_unique_id')}
                                        {this.transactionID}
                                    </p>
                                    <p className="coins-earning-description">
                                        {Content.getValue('my_earnings_invoice_unique_number')}
                                        {this.InvoiceUniqueNumber}
                                    </p>
                                </>
                                : <>
                                    <div className="coins-earning-unique-id desktop">{Formatting.format(Content.getValue("earning_unique_id"), ["(uniqueID)"], [this.transactionID])}</div>
                                    {(this.referralEarningID !== null) ?
                                        (<div className="coins-earning-unique-id">{Formatting.format(Content.getValue("referral_earning_unique_id"), ["(uniqueID)"], [this.referralEarningID])}</div>) : (null)

                                    }
                                    <div className="coins-earning-description desktop">{coinsEarningDescription} </div>
                                </>}
                        </div>
                    <div className="earning-entry-right-section desktop">
                        <div className="coins-earning-time">{this.date}<br />{this.time}</div>
                    </div>
                    </div>

                    {(this.moreOffersNumber > 0) ? (
                        <RedirectWithLang
                            props={{
                                className: "more-offers-link desktop",
                                onClick: () => UserData.setItem(userDataKeys.open_earning_list, true)
                            }}
                            to={`${configuration.paths.playground}#${this.appName.replaceAll("'", "").replaceAll("+", "").replaceAll(":", "_").replaceAll(" ", "_").replaceAll(".", "_").replace("__", "_").toLowerCase()}`}
                            text={Formatting.format(Content.getValue("more_offers_earning"), ["(moreOffersNumber)"], [this.moreOffersNumber])}
                        >
                        </RedirectWithLang>
                    ): (null)}
                </div>
            </>
        )
    }

}

export default CoinEarning;