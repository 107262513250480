import { useState } from "react";


const StartRating = (props) => {

    const { score, maxScore, width, gap, onChange } = props;

    const [rating, setRating] = useState(score)
    const [selectedStar, setSelectedStar] = useState(null)

    const starsTotal = maxScore ?? 5;

    const styles = {
        size: {
            width: width ?? width,
            height: width ?? width
        },
        wrap: {
            gap: gap ?? 0
        }
    }

    const onRatingChange = (key) => {
        if(onChange){
            setRating(key)
            onChange(key)
            setSelectedStar(key)
            setTimeout(function() {
                setSelectedStar(null)      
            }, 800);
        }
    }

    

    return (
        <div style={styles.wrap} className={`star-rating-wrap ${onChange ? "editable" : ""}`}>
            {[...Array(starsTotal)].map((x, i) => {
                return (
                    <span onClick={()=>onRatingChange(i+1)} style={styles.size} key={i} className={`star-rating ${(rating > i ? "filled" : "")} ${(selectedStar == i+1 ? "pulse" : "")}`}></span>
                )
            })}
        </div>
    )
}

export default StartRating;