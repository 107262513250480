import UserData from "User_Data.js";
import mixpanel from "mixpanel-browser";

const globalConf = require('global_conf.json');
const mixpanelToken = globalConf.mixpanel_connection.token;

const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const blockedEvents = [
    "page_view", 
    "playground_filter_selected", 
    "drawer_menu_click",
    "cookies_click_accept",
    "continue_with_click",
    "1_2_treat_view",
    "closed_join_popup",
    "play_guide_video",
    "login_click"
];

const thirdPartySettings = JSON.parse(UserData.getItem(userDataKeys.third_party_settings))  || {};

if (thirdPartySettings[userDataKeys.third_party_cookies.mixpanel]) {
    mixpanel.init(mixpanelToken);
}

export const Mixpanel = {
    identify: (id) => {
        mixpanel.identify(id);
    },
    alias: (newID, oldID) => {
        mixpanel.alias(newID, oldID);
    },
    getDistinctId: () => {
        let distinctId;
        try {
            distinctId = mixpanel.get_distinct_id();
		} catch(e) {
            distinctId = null;
		}
        return distinctId;
    },
    init: () => {
        mixpanel.init(mixpanelToken);
    },
    trackByEvent: (currentEvent) => {
        const thirdPartySettings = JSON.parse(UserData.getItem(userDataKeys.third_party_settings)) || {};
        const mixpanelAccepted = thirdPartySettings[userDataKeys.third_party_cookies.mixpanel];

        if (mixpanelAccepted && currentEvent) {
            const props = Object.assign({}, currentEvent.target.dataset);
            const { event } = props;
            delete props.event;
            if (blockedEvents.indexOf(event) === -1) {
                mixpanel.track(event, props);
            }
        }
    },
    trackByArgs: (event, properties) => {
        const thirdPartySettings = JSON.parse(UserData.getItem(userDataKeys.third_party_settings)) || {};
        const mixpanelAccepted = thirdPartySettings[userDataKeys.third_party_cookies.mixpanel];

        if (!mixpanelAccepted) {
            return;
        }
        if (blockedEvents.indexOf(event) === -1) {
            mixpanel.track(event, properties);
        }
    },
    reset: () => {
        mixpanel.reset();
    }
};