import React, { useEffect, useRef, useState } from 'react';
import Content from 'utils/Content';
import Images from 'utils/Images';
import AdditionalOfferCard from 'components/offers/additional_offers/Additional_Offer_Card.js';

function AdditionalOffersContainer({
  offersObj
}) {
  const { categoryID, categoryHeading1, categoryHeading2, categoryImageURL, offers } = offersObj;
  const scrollRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [scrollOffset, setScrollOffset] = useState(null);

  const handleWindowResize = () => {
    setScrollOffset(window.innerWidth > 1440 ? (326) : (343 * 3 + 60));
    const summary = Math.ceil(offers.length / (window.innerWidth > 1440 ? 3 : 1));
    setTotalPages(summary > 1 ? summary : 0);
  };

  const handleScroll = (realIdx) => {
    if (scrollRef.current) {
      const element = scrollRef.current;
      element.scrollBy({
          left: scrollOffset * (realIdx - currentPage),
        behavior: 'smooth'
        });
      setCurrentPage(realIdx);
    }
  };

  const handleManualScroll = () => {
    if (scrollRef.current) {
      const element = scrollRef.current;
      const newPosition = element.scrollLeft;
      const scrollOffsetLocal = window.innerWidth < 1440 ? (326) : (343 * 3 + 60);
      setCurrentPage(Math.ceil(newPosition / scrollOffsetLocal)+1)
    }
  };

  useEffect(() => {
    setTotalPages(Math.ceil(offers.length / (window.innerWidth > 1440 ? 3 : 1)));
    setScrollOffset(window.innerWidth < 1440 ? (326) : (343 * 3 + 60));
    window.addEventListener('resize', handleWindowResize);
    const element = scrollRef.current;
    if (scrollRef.current) {
      element.addEventListener('scroll', handleManualScroll);
    }
    return () => {
      element.removeEventListener('scroll', handleManualScroll);
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [scrollRef]);

  return (
    <section className='additional-offers' >
      <div className={`additional-offers-container additional-offers-gradient-${categoryID}`}>
        <div
          className={`additional-offers-head-img`}
          style={{ backgroundImage: `url(${Images.imageURL(categoryImageURL)})` }}
          alt="Head image" />
        <div className="additional-offers-heading-container">
          <img
            className='additional-offers-heading-img'
            src={Images.imageURL(`additional-offers/sticker-${categoryID}.png`)}
            alt="Category"
          />
          <h3 className='additional-offers-heading'>
            {Content.getValue(categoryHeading1)}
          </h3>
        </div>
        <p className='additional-offers-desc'>{Content.getValue(categoryHeading2)}</p>
          <ul ref={scrollRef} className="additional-offers-list">
            {offers?.map(offer => <AdditionalOfferCard key={offer.uniqueID} offer={{ ...offer, CategoryImageURL: categoryImageURL }} categoryID={categoryID} />
            )}
          </ul>
        {totalPages ?
          <div className="additional-offers-paginator-desktop">
            <button onClick={() => { handleScroll(currentPage > 1 ? currentPage - 1 : currentPage) }}
              className="additional-offers-swipe-btn button-hidden">
              <img src={Images.imageURL('arrow_left_hp.png')} alt="ArrowLeft" />
            </button>
            <button onClick={() => { handleScroll( currentPage !== totalPages ? currentPage + 1 : currentPage); }}
              className="additional-offers-swipe-btn button-hidden">
              <img src={Images.imageURL('arrow_right_hp.png')} alt="ArrowRight" />
            </button>
          </div>
          : <></>}
        {totalPages  ?
        <div className="additional-offers-paginator">
            {Array(totalPages).fill().map((i, idx) => {
              const realIdx = idx + 1;
              const isCurrent = realIdx === currentPage;
              return (
                <button className="button-hidden"
                  key={idx}
                  disabled={isCurrent}
                  onClick={() => {
                    handleScroll(realIdx);
                  }}
                >
                  <img src={Images.imageURL(`additional-offers/pagination${isCurrent ? '-active' : ''}.png`)} alt="" />
                </button>
              );
            }
            )}
        </div> :
        <></>
        }
      </div>
    </section>
  );
}

export default AdditionalOffersContainer;
