import React from 'react';
import ApiFacade from 'ApiFacade.js';

import Joining from 'utils/Joining.js';
import Images from 'utils/Images.js';
import LinkCreator from 'utils/Link_Creator.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';

const configuration = require('configuration.json');

const userDataKeys = configuration.user_data_keys;
const uiSettings = configuration.ui_settings;
const mixpanelEvents = configuration.mixpanel_events;


const connectedToBotCheckPeriod = uiSettings.connected_to_bot_check_period;
const connectedToBotCheckDuration = uiSettings.connected_to_bot_check_duration;
const connectedToBotCheckCount = Math.floor(connectedToBotCheckDuration / connectedToBotCheckPeriod);

let connectionCheckInterval = null;
let connectedCheckCount = 0;
let thisComponent;

class MenuJoinButton extends React.Component {

    constructor(props) {

        super(props);

        thisComponent = this;


        if (UserData.getItem(userDataKeys.connected_to_bot) !== null) {
            this.state = {
                connectedToBot: (UserData.getItem(userDataKeys.connected_to_bot) === "true" || UserData.getItem(userDataKeys.connected_to_bot) === true),
                clickHandler: props.clickHandler
            };
        }
        else {
            this.state = {
                connectedToBot: null,
                clickHandler: props.clickHandler
            };
        }

    }

    checkBotConnection() {
        ApiFacade.connectedToBot().then(function (res) {
                if (res.ok) {
                    let result = res.response;
                    let connectedToBot = result.connectedToBot;
                    thisComponent.setState({
                        connectedToBot: connectedToBot
                    });
                    connectedCheckCount++;
                    UserData.setItem(userDataKeys.connected_to_bot, connectedToBot);
                    if (connectedToBot) {
                        if (connectionCheckInterval !== null) {
                            clearInterval(connectionCheckInterval);
                        }
                    }
                    if (connectedCheckCount >= connectedToBotCheckCount && connectionCheckInterval !== null) {
                        clearInterval(connectionCheckInterval);
                    }
                }
            },
            () => {

            }
        );
    }


    componentWillUnmount() {
        if (connectionCheckInterval !== null) clearInterval(connectionCheckInterval);
    }

    navigateTo = (link) => {
        window.open(link, '_blank');
    };

    render() {

        const { connectedToBot, clickHandler } = this.state;
        const { navigate } = this.props;

        if (!ApiFacade.isLoggedIn()) {
            let showTriangle = this.showTriangle || false;
            return (
                <span className="join-treatcoin-button-container">
                    <button className="join-treatcoin-button"
                        data-event={mixpanelEvents.join_now_click}
                        onClick={(event) => {
                            Mixpanel.trackByEvent(event);
                            clickHandler();
                            return Joining.showPopup(navigate);
                        }}>
                        <img src={Images.imageURL('join-triangle.png')} alt="Join TreatCoin" width="38" className={showTriangle ? 'join-triangle' : 'hidden-join-triangle'} />
                        {Content.getValue("join_treatcoin")}
                    </button>
                </span>
            );
        }
        else {
            if (UserData.getItem(userDataKeys.connected_to_bot) === null) {
                thisComponent.checkBotConnection();
            }
            else if (connectedToBot === null) {
                thisComponent.setState({
                    connectedToBot: (UserData.getItem(userDataKeys.connected_to_bot) === "true" || UserData.getItem(userDataKeys.connected_to_bot) === true),
                });
            }

            let botLinkAddress = LinkCreator.createBotLink();
            if (!connectedToBot) {
                return (
                    <button className="join-treatcoin-button" onClick={() => {
                        connectedCheckCount = 0;
                        connectionCheckInterval = setInterval(thisComponent.checkBotConnection, connectedToBotCheckPeriod * 1000);
                        this.navigateTo(botLinkAddress);
                    }}>
                        {Content.getValue("connect_to_telegram_bot")}
                    </button>
                );
            }
            else {
                return (
                    <button className="join-treatcoin-button"
                        onClick={() => { this.navigateTo(botLinkAddress); }}
                    >
                        {Content.getValue("go_to_telegram_bot")}
                    </button>
                );
            }
        }
    }

}

export default MenuJoinButton;