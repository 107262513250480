import React from 'react';
import Images from 'utils/Images.js';

import ContentPageHeader from "components/sections/Content_Page_Header.js";
import ConnectToDiscordUsername from "components/data/Connect_To_Discord_Username.js";
import ConnectToDiscordButton from "components/buttons/Connect_To_Discord_Button.js";
import Footer from "components/sections/Footer.js";

import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { RedirectWithLang } from 'utils/RedirectWithLang.js';
const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const pages = configuration.pages;

class ConnectToDiscord extends React.Component {

    componentDidMount() {
        Mixpanel.trackByArgs(mixpanelEvents.page_view, { page_name: pages.connect_to_discord });
    }

    render() {
       
        return (
            <>
                <div id="main">
                    <div id="content">
                        <ContentPageHeader />
                        <section className="content-page-section">
                            <div>
                                <RedirectWithLang to={configuration.paths.home}>
                                    <img src={Images.imageURL('close-welcome.png')} alt="Go to homepage" width="24" className="close-welcome" />
                                </RedirectWithLang>
                                <ConnectToDiscordUsername />
                                <div className="page-container">
                                    <img src={Images.imageURL('welcome-robot.png')} alt="Homepage robot" width="375" className="welcome-robot-image" />
                                    <div className="welcome-robot-text">
                                        {Content.getValue("connect_to_discord_description")}
                                    </div>
                                    <ConnectToDiscordButton/>
                                    <h2 className="connect-to-bot-bonus text-center margin-top-20 padding-x-25 font-weight-medium">
                                        {Content.getValue("connect_to_discord_bonus")}
                                    </h2>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
        
}

export default ConnectToDiscord;