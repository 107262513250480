import Logout from 'utils/Logout.js';
import UserData from 'User_Data.js';
import Content from 'utils/Content.js';
import { Mixpanel } from 'utils/User_Events_Util.js';
import { RedirectWithLang } from "utils/RedirectWithLang.js";
import Joining from "utils/Joining";
import { useNavigate } from 'react-router-dom';
import ApiFacade from 'ApiFacade.js';


const configuration = require('configuration.json');
const userDataKeys = configuration.user_data_keys;
const mixpanelEvents = configuration.mixpanel_events;
const emailSignupModes = configuration.email_signup_modes;

const LoginLogoutButton = ({ isStyled, fromMainMenu, clickHandler }) => {
    const navigate = useNavigate();


    if (ApiFacade.isLoggedIn()) {
        return <>
            <button className="login-logout-button"
                onClick={() => { clickHandler(); Logout.showLogoutPopup(navigate); }}
            >
                {Content.getValue("log_out_button_text")}
            </button>
        </>;
    }
    else {
        if (isStyled) {
            const emailSignup = UserData.getItem(userDataKeys.email_signup);
            if (emailSignup !== emailSignupModes.direct &&
                emailSignup !== emailSignupModes.join_button) {
                return (
                    <>
                        <button
                            className="login-button-styled"
                            data-event={mixpanelEvents.login_click}
                            data-from_main_menu='fromMainMenu'
                            onClick={(event) => {
                                Mixpanel.trackByEvent(event);
                                Joining.showPopup(navigate);
                            }}
                        >
                            <span>{Content.getValue("login_button_text_1")}</span> <br />
                            <span className='login-button-styled-or'>{Content.getValue("login_button_text_2")}</span> <br />
                            <span className='login-button-styled-sign-in'>{Content.getValue("login_button_text_3")}</span>
                        </button>
                    </>
                );
            }
            else {
                return (null);
            }
        }

        return <RedirectWithLang
            text={Content.getValue("log_in_button_text")}
            to={configuration.paths.login}
            props={{
                className: "login-logout-button",
                onClick: (event) => {
                    Mixpanel.trackByEvent(event);
                    clickHandler();
                },
                "data-event": mixpanelEvents.login_click,
                "data-from_main_menu": fromMainMenu,
            }}
        />;
    }
};

export default LoginLogoutButton;