import Menu from 'components/widgets/Menu_Drawer.js';
import { useEffect, useState, useContext } from 'react';
import Content from 'utils/Content';
import Images from 'utils/Images';
import { RedirectWithLang, redirectWithLangFunc } from 'utils/RedirectWithLang';
import { Mixpanel } from 'utils/User_Events_Util.js';
import Joining from 'utils/Joining.js';
import GetPathName from 'utils/Get_Pathname';
import imagePathGenerator from 'utils/Image_Path_Generator';
import { MenuContext } from 'context/Menu_Context.js';
import { useLocation, useNavigate } from 'react-router-dom';
import ApiFacade from 'ApiFacade.js';

const configuration = require('configuration.json');

const mixpanelEvents = configuration.mixpanel_events;

function BottomMenu() {
  const navigate = useNavigate();
  const [currentPath, setCurrentPath] = useState('');
  const location = useLocation();

  const { showMenu } = useContext(MenuContext);


  useEffect(() => {
    setCurrentPath(GetPathName());
  }, [location.pathname]);


  const hideMenu = window.location.pathname.includes(configuration.paths.start_your_journey)

  return (
    <div className={`bottom-menu ${hideMenu ? 'd-none' : ''}`}>
      <button className={`bottom-menu-item hidden-button padding-bottom-20 ${showMenu ? 'chosen-bottom-menu' : ''}`}
        onClick={() => {
          const menu = Menu.getMenu();
          if (menu) {
            menu.toggleMenu();
          }
        }}
      >
        <img src={Images.imageURL(`bottom-menu/burger-${showMenu ? 'green' : '2'}.png`)} alt="Burger" className='bottom-menu-item-icon padding-bottom-20 h-full-i' />
      </button>
      <RedirectWithLang
        to={configuration.paths.home}
        isButton
        props={{
          onClickHandler: (event) => {
            Mixpanel.trackByEvent(event);
          },
          "data-event": mixpanelEvents.drawer_menu_click,
          "data-click_from_main_menu": "homeOption",
          id: configuration.paths.home,
          className: `bottom-menu-item hidden-button ${currentPath === configuration.paths.home ? 'chosen-bottom-menu' : ''}`,
        }}
      >
        <img src={imagePathGenerator('bottom-menu/home-2.png', configuration.paths.home)} alt="Home" className='bottom-menu-item-icon' />
        <p className="bottom-menu-item-desc">{Content.getValue('my_zone')}</p>
      </RedirectWithLang>
      <RedirectWithLang
        to={configuration.paths.top_12_offers}
        isButton
        props={{
          id: configuration.paths.top_12_offers,
          onClickHandler: (event) => {
            Mixpanel.trackByEvent(event);
          },
          "data-event": mixpanelEvents.drawer_menu_click,
          "data-click_from_main_menu": "topXOffersOption",
          className: `bottom-menu-item hidden-button ${currentPath === configuration.paths.top_12_offers ? 'chosen-bottom-menu' : ''}`,
        }}
      >
        <img src={Images.imageURL('bottom-menu/treat-coin-2.png')} alt="TreatCoin" className='bottom-menu-item-icon' />
        <p className="bottom-menu-item-desc">{Content.getValue('top_12_offers')}</p>
      </RedirectWithLang>
      <RedirectWithLang
        to={configuration.paths.playground}
        isButton
        props={{
          onClickHandler: (event) => {
            Mixpanel.trackByEvent(event);
          },
          id: configuration.paths.playground,
          "data-event": mixpanelEvents.drawer_menu_click,
          "data-click_from_main_menu": "playgroundOption",
          className: `bottom-menu-item hidden-button ${currentPath === configuration.paths.playground ? 'chosen-bottom-menu' : ''}`,
        }}
      >
        <img src={imagePathGenerator('bottom-menu/all-offers-2.png', configuration.paths.playground)} alt="AllOffers" className='bottom-menu-item-icon' />
        <p className="bottom-menu-item-desc">{Content.getValue('all_offers')}</p>
      </RedirectWithLang>
      <RedirectWithLang
        to={configuration.paths.cashout}
        isButton
        allowRedirect={false}
        props={{
          id: configuration.paths.cashout,
          onClickHandler: (event) => {
            Mixpanel.trackByEvent(event);
            if (ApiFacade.isLoggedIn()) {
              redirectWithLangFunc(configuration.paths.cashout, navigate);
            } else {
              return Joining.showPopup(navigate);
            }
          },
          "data-event": mixpanelEvents.drawer_menu_click,
          "data-click_from_main_menu": "cashoutOption",
          className: `bottom-menu-item hidden-button ${currentPath === configuration.paths.cashout ? 'chosen-bottom-menu' : ''}`,
        }}
      >
        <img src={imagePathGenerator('bottom-menu/cashout-2.png', configuration.paths.cashout)} alt="Cashout" className='bottom-menu-item-icon' />
        <p className="bottom-menu-item-desc">{Content.getValue('cashout')}</p>
      </RedirectWithLang>
    </div>
  );
}

export default BottomMenu;