const configuration = require('configuration.json');

const platformTypes = configuration.platform_types;

const desktopPlatformType = platformTypes.desktop;
const androidPlatformType = platformTypes.android;
const iOSPlatformType = platformTypes.ios;

class Platform {

    static getPlatformName() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/windows phone/i.test(userAgent)) {
            return desktopPlatformType;
        }

        if (/android/i.test(userAgent)) {
            return androidPlatformType;
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return iOSPlatformType;
        }

        return desktopPlatformType;
    }


}

export default Platform;