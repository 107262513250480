import React from 'react';
import Images from 'utils/Images.js';

class LoadingPage extends React.Component {


    render() {

        return (
            <div id="main">
            <div id="loading-content">
            <section className="content-page-section">
                <div>
                    <div className="page-container">
                            <img src={Images.imageURL('welcome-robot.png')} alt="Homepage robot" width="375" className="welcome-robot-image" />
                            <h1 className="page-title welcome-title">Loading TreatCoin ...</h1>
                    </div>
                </div>
            </section>
            </div>
            </div>
        );
    }

}

export default LoadingPage;