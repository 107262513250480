import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Images from 'utils/Images.js';
import Sharing from 'utils/Sharing.js';
import Formatting from 'utils/Formatting.js';
import Content from 'utils/Content.js';
import TreatcoinGoldBenefitsPopup from 'popups/TreatcoinGoldBenefitsPopup';

const globalConf = require('global_conf.json');
const frontendHost = globalConf.website_connection.frontend_host;

const MySwal = withReactContent(Swal);

class Alerts {

    static showNftsAlert() {

        let swalHTML = '<img class="swal-nfts-image" alt="Nfts image" src="' + Images.imageURL('nfts-alert-image.png') + '"/>';
        swalHTML += "<p class='swal-nfts-text'>" + Content.getValue("nfts_alert_text") + "</p>";

        MySwal.fire({
            title: '<h1 class="swal-nfts-title">' + Content.getValue("nfts_alert_title") + '</h1>',
            html: swalHTML,
            background: '#141629',
            showCloseButton: true,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("got_it_button_text"),
            customClass: {
                confirmButton: 'nfts-confirm-button',
                closeButton: 'swal2-nfts-close'
            }
        });
    }

    static introSurveyLegitimacyWarning() {

        let swalHTML = '<img class="swal-legitimacy-image" alt="Legitimacy image" src="' + Images.imageURL('robot-legitimacy-warning.png') + '"/>';
        swalHTML += "<p class='swal-legitimacy-text'>" + Content.getValue("intro_survey_legitimacy_alert_text") + "</p>";

        MySwal.fire({
            title: '<h1 class="swal-legitimacy-title">' + Content.getValue("intro_survey_legitimacy_alert_title") + '</h1>',
            html: swalHTML,
            background: '#141629',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("got_it_button_text"),
            allowOutsideClick: false,
            customClass: {
                confirmButton: 'legitimacy-confirm-button',
                closeButton: 'swal2-legitimacy-close'
            }
        }).then(res => res.isConfirmed || res.isDenied || res.isDismissed ? window.location.replace(configuration.paths.playground) : null);

    }

    static displayDailyEarningCompletedPopup(date) {
        const imageFileName = 'daily-earning-plan-finished.jpg';

        const description = Formatting.format(Content.getValue("daily_earning_plan_finished_text"), ["(date)"], [date]);
        const sharingDescription = Formatting.format(Content.getValue("daily_earning_plan_finished_share_text"), ["(date)"], [date]);

        let swalHTML = '<div class="daily-earning-popup-image"></div>';
        swalHTML += '<div class="daily-earning-popup-container">';
        swalHTML += '<h1 class="daily-earning-popup-title">' + Content.getValue("daily_earning_plan") + '</h1>';
        swalHTML += '<h4 class="daily-earning-popup-description">' + description + '</h4>';
        swalHTML += '</div>';

        MySwal.fire({
            html: swalHTML,
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            showCancelButton: true,
            confirmButtonText: Content.getValue("share_with_friends"),
            cancelButtonText: Content.getValue("close_button_text"),
            customClass: {
                confirmButton: 'daily-earning-confirm-button',
                cancelButton: 'daily-earning-cancel-button',
            }
        })
            .then(async res => {
                if (res.isConfirmed) {
                    await Sharing.shareLink(
                        {
                            title: Content.getValue("daily_earning_plan_finished"),
                            text: sharingDescription,
                            url: frontendHost,
                            fallbackTitle: Content.getValue("share_daily_plan_fallback_title"),
                            fallbackMessage: sharingDescription,
                            detailsImageURL: Images.imageURL(imageFileName)
                        }
                    );
                }
            });
    }

    static displayWithdrawalApprovedPopup(amount) {
        const imageFileName = 'daily-earning-plan-finished.jpg';

        const description = Formatting.format(Content.getValue("withdrawal_approved_popup"), ["(amount)"], [Formatting.formatUSDAmount(amount, true)]);

        let swalHTML = '<div class="withdrawal-approved-popup-image"></div>'; // use custom classes
        swalHTML += '<div class="withdrawal-approved-popup-container">';
        swalHTML += '<h4 class="withdrawal-approved-popup-description">' + description + '</h4>'; // use custom classes
        swalHTML += '</div>';

        MySwal.fire({
            html: swalHTML,
            background: 'linear-gradient(46.77deg, #DEF5FE 14.02%, #F9E7FF 48.07%, #FEEBE3 69.02%, #FFF1CC 91.84%), #C4C4C4',
            showCancelButton: true,
            confirmButtonText: Content.getValue("share_with_friends"),
            cancelButtonText: Content.getValue("close_button_text"),
            customClass: {
                confirmButton: 'withdrawal-approved-confirm-button',
                cancelButton: 'withdrawal-approved-cancel-button',
            }
        }).then(async res => {
            if (res.isConfirmed) {
                await Sharing.shareLink(
                    {
                        title: Content.getValue("withdrawal_approved_title"),
                        text: description,
                        url: frontendHost,
                        fallbackTitle: Content.getValue("withdrawal_approved_fallback_title"),
                        fallbackMessage: description,
                        detailsImageURL: Images.imageURL(imageFileName)
                    }
                );
            }
        });
    }

    static showSuccessAlert(message) {
        MySwal.fire({
            html: '<p class="successful-alert">' + message + '</p>',
            background: '#5FC88F',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000,
            customClass: {
                closeButton: 'successful-alert'
            }
        });
    }

    static showErrorAlert(message) {
        MySwal.fire({
            html: '<p class="error-alert">' + message + '</p>',
            background: '#EC1717',
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            timer: 3000,
            customClass: {
                closeButton: 'error-alert'
            }
        });
    }


    static showTreatcoinGoldBenefitsPopup() {

        MySwal.fire({
            html: <TreatcoinGoldBenefitsPopup />,
            background: '#131522',
            showCloseButton: true,
            allowOutsideClick: true,
            showConfirmButton: true,
            confirmButtonText: Content.getValue("gold_popup_btn"),
            customClass: {
                confirmButton: 'gold-popup-btn gold-background',
                popup: 'gold-popup',
                container: 'gold-popup-mask',
            }
        });
    }

}

export default Alerts;