import Content from 'utils/Content.js';
import 'design/reviews.css';
import CoinsConversionPanel from "components/panels/CoinsConversionPanel";
import Formatting from 'utils/Formatting.js';
import { useEffect, useState } from 'react';
import StartRating from 'components/widgets/StartRating';
import ApiFacade from 'ApiFacade';
import { Mixpanel } from "utils/User_Events_Util.js";
import UserData from 'User_Data.js';
import Alerts from '../../utils/Alerts';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;
const userDataKeys = configuration.user_data_keys;


const WriteOfferReview = ({ review, afterReviewSubmit}) => {

    const [rating, setRating] = useState(review?.Rating || null)
    const [reviewTite, setReviewTite] = useState(review?.Title || "")
    const [reviewText, setReviewText] = useState(review?.ReviewText || "")
    const [submitInProgress, setSubmitInProgress] = useState(false);

    const isEdit = review?.Rating && review?.Title && review?.ReviewText;

    useEffect(()=>{
        Mixpanel.trackByArgs(mixpanelEvents.write_your_review_clicked, { 
            User_id: UserData.getItem(userDataKeys.uniqueUserID),
            UniqueOffer_Id: review.UniqueOfferID
        });
    }, [])


    const onPublish = async () => {
        try {
            setSubmitInProgress(true);
            const res = await ApiFacade.setUserReview({
                reviewID: isEdit ? review.ID : null,
                uniqueOfferID: review.UniqueOfferID,
                title: reviewTite,
                reviewText: reviewText,
                rating: rating,
            });

            Mixpanel.trackByArgs(mixpanelEvents.review_published, {
                User_id: UserData.getItem(userDataKeys.uniqueUserID),
                UniqueOffer_Id: review.UniqueOfferID,
                Rating: rating
            });
            if (res.ok) {
                Alerts.showSuccessAlert(Content.getValue(isEdit ? 'success_message_review_edit': 'success_message_review' ));
            } else if (res.badRequest) {
                Alerts.showErrorAlert(Content.getValue('error_message_review'));
            }
            setSubmitInProgress(false);
            afterReviewSubmit(res)   
        } catch (error) {
            Alerts.showErrorAlert(Content.getValue('error_message_review'));
        }
    }

    const isValid = Boolean(
        rating 
        && reviewTite.length > 4
        && reviewTite.length < 26
        && reviewText.length > 9
        && reviewText.length < 141
    )


    return (
        <div className='edit-review-outter-wrap'>
            <div className='edit-review-title'>{Content.getValue('write_your_review')}</div>
            <div className='edit-review-sub-title'>{review?.reviewRequired === 1 ?
                Formatting.format(Content.getValue("review_popup_sub_title"), ["(treatCoins)"], [review.Coins]) :
                Content.getValue("review_popup_sub_title_2")
            }</div>
            <div className="edit-review-wrap review-wrap flex-row">
                <div className="review-img">
                    <img src={review.ImageUrl} />
                </div>
                <div className="flex-grow space-between flex-column">
                    <div className="review-app-name bold">{review.AppName}</div>
                    <div className="review-title">
                        {review.AttributeMeaning1 && review.AttributeMeaning2
                            ? Formatting.format(Content.getValue("deposit_x_win_y"), ["(att1)", "(att2)"], [review.AttributeMeaning1, review.AttributeMeaning2])
                            : review.MainTitle
                        }
                    </div>
                    <CoinsConversionPanel
                        coins={review.Coins}
                        treatCoinValue={review.TreatcoinValue}
                        fullWidth
                    />
                </div>
            </div>
            <div className='flex-row space-between edit-review-rating items-center'>
                <div>{Content.getValue("review_rate")}:</div>
                <div className="margin-right-10">
                    <StartRating
                        gap={30}
                        onChange={e => setRating(e)}
                        score={rating}
                    />
                </div>
            </div>
            <div className='form-area'>
                <label htmlFor="edit-title-input">{Content.getValue("write_review_input_title")}</label>
                <input type='text' maxLength="25" id="edit-title-input" onChange={e=>setReviewTite(e.target.value)} value={reviewTite}/>
            </div>
            <div className='form-area'>
                <label htmlFor="edit-review-input">{Content.getValue("write_review_input_description")}</label>
                <textarea rows="3" maxLength="140" id="edit-review-input" onChange={e=>setReviewText(e.target.value)} value={reviewText}></textarea>
            </div>
            <button 
                className="round-btn"
                onClick={() => onPublish()}
                disabled={!isValid || submitInProgress}
            >
                {Content.getValue('review_submit_text')}
            </button>
        </div>
    );
};

export default WriteOfferReview;