import React from 'react';

function ChooseButton({ onClick, text, chosen, disabled }) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`button-hidden choose-button ${chosen ? 'chosen' : ''} ${disabled ? 'disabled' : ''}`}>
            {text}
        </button>
    );
}

export default ChooseButton;