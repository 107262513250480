import React from 'react';
import Images from 'utils/Images.js';
import Content from 'utils/Content.js';

const globalConf = require("global_conf.json");
const configuration = require("configuration.json");

const websiteConnection = globalConf.website_connection;
const social = configuration.social;

const host = websiteConnection.frontend_host;

const facebook = social.facebook;
const twitter = social.twitter;
const instagram = social.instagram;
const linkedin = social.linkedin;

class Organization extends React.Component {

    render() {

        let logoURL = Images.imageURL('treat-coin-logo-new.png');

        return (
            <script type="application/ld+json">
                {`{
                "@context": "https://schema.org",
                "@type": "Organization",
                "name": "${Content.getValue("organization_name")}",
                "description": "${Content.getValue("organization_description")}",
                "url": "${host}",
                "logo": "${logoURL}",
                "sameAs" : [
                    "${facebook}",
                    "${twitter}",
                    "${instagram}",
                    "${linkedin}"
                 ]
   }`}
           </script>
        )
    }
}

export default Organization;