import React from 'react';
import Images from 'utils/Images.js';
import GainTreatCoinsByFunActButton from 'components/buttons/Gain_Treat_Coins_By_Fun_Act_Button.js';
import Fade from 'react-reveal/Fade';
import Content from 'utils/Content.js';

const configuration = require('configuration.json');
const mixpanelEvents = configuration.mixpanel_events;

class GainCoinsByFunActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            earnAmount: 0,
        };
        this.formatter = new Intl.NumberFormat('en').format;
    }

    handleSlideChange = (evt) => {
        const amount = Content.getValue("treat_coins_equals_money_step_1_text").find((each, idx) => idx === evt.realIndex);
        this.setState({
            earnAmount: amount,
            currentIndex: evt.realIndex
        });
    };

    render() {

        return (
            <div className="providers-homepage">
                <h2 className="providers-homepage-title desktop">{Content.getValue("providers_homepage_title1")}</h2>
                <div className="providers-homepage-card-container desktop" >
                    <Fade right>
                        <div className='providers-homepage-card'>
                            <div className='providers-homepage-card-item'>
                                <div className='providers-homepage-card-text'>
                                    <h2 className="providers-homepage-card-title">{Content.getValue("providers_homepage_card2_title")}</h2>
                                    <h2 className="providers-homepage-card-description desktop">{Content.getValue("providers_homepage_card2_description")}</h2>
                                </div>
                                <div className='providers-homepage-card-image'>
                                    <img
                                        src={Images.imageURL('providers-homepage-card2-image-new.png')}
                                        alt="Broken line"
                                        width="120"
                                        height="136" 
                                    />
                                </div>
                            </div>
                            <GainTreatCoinsByFunActButton
                                dataEvent={mixpanelEvents.to_the_playground_click}
                            />
                        </div>
                    </Fade>
                    <Fade left>
                        <div className='providers-homepage-card'>
                            <div className='providers-homepage-card-item'>
                                <div className='providers-homepage-card-text'>
                                    <h2 className="providers-homepage-card-title">{Content.getValue("providers_homepage_card3_title")}</h2>
                                    <h2 className="providers-homepage-card-description desktop">{Content.getValue("providers_homepage_card3_description")}</h2>
                                </div>
                                <div className='providers-homepage-card-image'>
                                    <img
                                        src={Images.imageURL('providers-homepage-card3-image-new.png')}
                                        alt="Broken line"
                                        width="162"
                                        height="141" 
                                    />
                                </div>
                            </div>
                            <GainTreatCoinsByFunActButton
                                dataEvent={mixpanelEvents.to_the_playground_click}
                            />
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }

}

export default GainCoinsByFunActivities;