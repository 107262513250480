import Content from 'utils/Content.js';
import { getMonth, getYear  } from 'date-fns';


const CompetitionInterval = () => {
    const currentMonth = getMonth(new Date()) + 1;
    const currentYear= getYear(new Date())
    const customPeriodIndex = Math.ceil(currentMonth / 3) * 3 - 2;
    return [customPeriodIndex, customPeriodIndex + 1, customPeriodIndex + 2]
        .map((item) => new Date(currentYear, item - 1, 1).toLocaleString(Content.getValue("date_localization"), { month: 'short' }))
        .join('-')
        .replaceAll('.', '');
};

export default CompetitionInterval;
